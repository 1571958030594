import * as React from "react";

export default function DetailCard({
    title, rows
}) {

    return (
        <>
            <div style={styles.card}>
                <div className="card-header text-center">
                    {title}
                </div>
                <ul className="list-group list-group-flush">
                    {
                        rows.map((item) => {
                            return (
                                <li className="list-group-item d-flex p-1">
                                    <div className="col-4">{item.label}</div>
                                    <div className="col-8">{item?.value ? item.value : ''}</div>
                                </li>
                            )
                        })
                    }

                </ul>
            </div>
        </>
    )
}

const styles = {
    card: {
        backgroundColor: 'white',
        border: '1px solid rgb(167, 167, 167)',
        borderRadius: '8px',
        overflow: 'auto'
    },
    header: {

    },
    body: {
        lineHeight: '32px'

    },
    profileCont: {
        display: 'flex',
        gap: '15px'
    },
    imgCount: {
        width: '100px',
        height: '100px'
    },
    profile: {
        width: 'calc(100% - 100px)',
    },
    img: {
        width: '100%'
    },
    line: {
        display: 'flex',
        borderBottom: '1px solid #dbdbdb'
    },
    line1: {
        width: '175px'
    },
    line2: {
        width: 'calc(100% - 175px)',
        textTransform: 'capitalize'
    }
};

import React, { Component } from 'react';
import Layout from './components/Layout/Layout';
import { Route,Switch } from 'react-router-dom';
import mainbuilder from './containers/mainbuilder/mainbuilder';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AUX from './hoc/Aux_';
import Header from './components/Layout/Header';
import Sidebar from './components/Layout/Sidebar';
import footer from './components/Layout/Footer';
import "react-toggle/style.css"
import '../src/App.css'

class App extends Component {

  render() {
    let layout = null;

      layout = (
        <Layout header={Header}  sidebar={Sidebar} footer = {footer}  >
          <Switch>  
            <Route path="/" component={mainbuilder} />
          </Switch>
        </Layout>);
    return (
      <AUX>
        {layout}
      </AUX>
    );
  }
}
const mapStatetoProps = state =>{
  return {
     header: state.Zeed.header,
     sidebar: state.Zeed.sidebar,
     footer: state.Zeed.footer,
    //  loginpage:state.Zeed.loginpage
  };
}

export default withRouter(connect(mapStatetoProps)(App));

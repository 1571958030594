import React, { Component } from 'react'
import { bounce,flash,pulse,jello,wobble,headShake,rubberBand,shake,swing,tada,bounceIn,bounceInDown,bounceInLeft,bounceInRight,bounceInUp,bounceOut,bounceOutDown,bounceOutLeft,bounceOutRight,bounceOutUp,fadeIn,fadeInDown,fadeInDownBig,fadeInLeft,fadeInLeftBig,fadeInRight,fadeInRightBig,fadeInUp,fadeInUpBig,fadeOut,fadeOutDown,fadeOutDownBig,fadeOutLeft,fadeOutLeftBig,fadeOutRight,fadeOutRightBig,fadeOutUp,fadeOutUpBig,flip,flipInX,flipInY,flipOutX,flipOutY,lightSpeedIn,lightSpeedOut,rotateIn,rotateInDownLeft,rotateInDownRight,rotateInUpLeft,rotateInUpRight,rotateOut,rotateOutDownLeft,rotateOutDownRight,rotateOutUpLeft,rotateOutUpRight,slideInDown,slideInLeft,slideInRight,slideInUp,slideOutDown,slideOutLeft,slideOutRight,slideOutUp,hinge,rollIn,rollOut,zoomIn,zoomInDown,zoomInLeft,zoomInRight,zoomInUp,zoomOut,zoomOutDown,zoomOutLeft,zoomOutRight,zoomOutUp } from 'react-animations';
import Radium, {StyleRoot} from 'radium';

class HeaderAnim extends Component {
  render() {

    if(this.props.now_control === "shake")
    {
        return(<StyleRoot><div  style={{ animation: 'x 3s', animationName: Radium.keyframes(shake, 'shake') , width:"11vw"}}>
            <span style={{color:"#9BABB8", letterSpacing:"1px" ,  fontSize:"1.5vw" , fontWeight:"600"}}>Sales Overiew</span></div></StyleRoot>);
    }
    else
    {  
        return(<StyleRoot>
            Nothing             
        </StyleRoot>);
    }
    
   
    }
}

export default HeaderAnim
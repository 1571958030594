import React, { useEffect, useState } from 'react'
import AUX from '../../../hoc/Aux_';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeSlot ,editTimeSlot } from '../../../redux/MainAction'


const TiemSlotAdd = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const timeList = useSelector(state=>state.Zeed.timeslot)

    useEffect(()=>{
        getRolList()
    },[])

    useEffect(()=>{
        if(timeList&&params.t_id){
            var spr = timeList.filter((itm)=>itm.id===params.t_id)
            // console.log("spu", spr);
            setSlot({
                slot: spr.length>0? spr[0].slot :'' ,
                isActive: spr.length>0 ?spr[0].isActive=== true ? 'true':false:''
            })
        }
    },[timeList])
    
    const getRolList = ()=>{
        var DATA={
            type:"all"
        }
        dispatch(getTimeSlot(DATA))
    }

    const [slot , setSlot]=useState({
        slot:"",
        isActive:"true"
    })

    const handleChange = (e) =>{
        const { value , name } = e.target
        setSlot({...slot,[name]: value})
    }

    const onSubmit = (e)=>{
        e.preventDefault()
        if(params.t_id){
            var data = {
                slotId: params.t_id,
                slot: slot.slot,
                isActive: slot.isActive === "true"? true : false
            }
            dispatch(editTimeSlot(data))
            // console.log("data",data);
        }
    }
  return (
    <AUX>
         <div className="page-content-wrapper">
                 <div className="container-fluid">

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card m-b-20">
                            <div className="card-body">
                                    <form onSubmit={onSubmit}>
                                        <div className='row'>
                                            <div className="form-group col-md-6">
                                                <label>Time Slot</label>
                                                <input type="text" className="form-control" placeholder="Time Slot" name='slot' value={slot.slot} onChange={handleChange}/>
                                                {/* <span id="err">{this.state.name_err}</span> */}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Status</label>
                                                <div className="col-md-12">
                                                    <select className="form-control" name='isActive' value={slot.isActive} onChange={handleChange}>
                                                        <option value="true">Active</option>
                                                        <option value="false">In-Active</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className='d-flex justify-content-end '>
                                                <button  onClick={"this.handleSubmit1"} type="submit" className="btn btn-primary waves-effect waves-light mr-2">
                                                    Submit
                                                </button>
                                                <Link to='/time-slot' className="btn btn-secondary waves-effect m-l-5">
                                                    Cancel
                                                </Link>
                                            </div>
                                        </div>
                                </form>
                            </div>
                        </div>
                    </div> 

                </div> 
            </div> 
        </div>
    </AUX>
  )
}

export default TiemSlotAdd
import {
  GET_ALL_SELLER,
  GET_ALL_USER,
  GET_ROLES,
  GET_WALLET_LIST,
  USER_LOGIN_DETAILS,
  GET_BANK_LIST,
  GET_CATEGORY,
  GET_TIMESLOT,
  GET_ORDER,
  GET_ADMIN_KPI,
  GET_ORDER_STATUS,
  GET_PACKAGE,
  GET_COUPON,
  GET_REPORTED_POSTS,
  GET_COUNTRY,
  GET_STATE,
  GET_PRODUCTS,
  GET_WINNERS_LIST,
  GET_ATTRIBUTE,
  GET_NOTIFICATIONS,
  GET_ROLES_MODULE_LIST,
  GET_CITY,
  GET_SELLER_DROPDOWN,
  GET_DASHBOARD,
  GET_USERS_DROPDOWN,
  GET_SUB_CATEGORY_DROPDOWN,
  GET_DRIVERS_DROPDOWN,
  GET_WAREHOUSE,
} from "./type";

// Login
const initialState = {
  LoginData: {},
  userList: [],
  sellerList: [],
  roles: [],
  wallet: [],
  banks: [],
  category: [],
  timeslot: [],
  orders: [],
  adminKpi: [],
  orderStatus: [],
  package: [],
  coupon: [],
  reportPost: [],
  country: [],
  state: [],
  productList: [],
  winner: [],
  attribute: [],
  notification: [],
  modulesList: [],
  city: [],
  dashboard: [],
  subCategory: [],
  driveList: [],
  warehouse: [],
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_DETAILS:
      return {
        ...state,
        LoginData: action.payload,
      };
    case GET_ALL_USER:
      return {
        ...state,
        userList: action.payload,
      };
    case GET_ALL_SELLER:
      return {
        ...state,
        sellerList: action.payload,
      };
    case GET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case GET_WALLET_LIST:
      return {
        ...state,
        wallet: action.payload,
      };
      case GET_DRIVERS_DROPDOWN:
        return {
          ...state,
          driveList: action.payload,
        };
    case GET_BANK_LIST:
      return {
        ...state,
        banks: action.payload,
      };
    case GET_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case GET_TIMESLOT:
      return {
        ...state,
        timeslot: action.payload,
      };
    case GET_ORDER:
      return {
        ...state,
        orders: action.payload,
      };
    case GET_ADMIN_KPI:
      return {
        ...state,
        adminKpi: action.payload,
      };
    case GET_ORDER_STATUS:
      return {
        ...state,
        orderStatus: action.payload,
      };
    case GET_PACKAGE:
      return {
        ...state,
        package: action.payload,
      };
    case GET_COUPON:
      return {
        ...state,
        coupon: action.payload,
      };
    case GET_REPORTED_POSTS:
      return {
        ...state,
        reportPost: action.payload,
      };
    case GET_COUNTRY:
      return {
        ...state,
        country: action.payload,
      };
    case GET_STATE:
      return {
        ...state,
        state: action.payload,
      };
    case GET_CITY:
      return {
        ...state,
        city: action.payload,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        productList: action.payload,
      };
    case GET_WINNERS_LIST:
      return {
        ...state,
        winner: action.payload,
      };
    case GET_ATTRIBUTE:
      return {
        ...state,
        attribute: action.payload,
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notification: action.payload,
      };
    case GET_ROLES_MODULE_LIST:
      return {
        ...state,
        modulesList: action.payload,
      };
    case GET_SELLER_DROPDOWN:
      return {
        ...state,
        sellersList: action.payload,
      };

    case GET_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
      };
    case GET_USERS_DROPDOWN:
      return {
        ...state,
        userList: action.payload,
      };
    case GET_SUB_CATEGORY_DROPDOWN:
      return {
        ...state,
        subCategory: action.payload,
      };
    case GET_WAREHOUSE:
      return {
        ...state,
        warehouse: action.payload,
      };
    default:
      return state;
  }
};

export default mainReducer;

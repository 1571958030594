import React, { useEffect, useState } from 'react'
import AUX from '../../../../hoc/Aux_';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {getState, addState, updateState, getCountry} from '../../../../redux/MainAction'
import axios from "axios";
import Constant from '../../../../Constant';

var config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("loged_In_auth"),
    },
};

const options = [
    {label: "Active", name:"active", value: true},
    {label: "Inactive", name:"inactive", value: false},
]

const StateAdd = () => {
    const dispatch = useDispatch()
    const stateList = useSelector(state=>state.Zeed.state)
    const countryList = useSelector(state=>state.Zeed.country)
    
    const params = useParams()

    const [state, setState]=useState({
        name:"",
        countryId:"",
        status:"",
    })
    const handleChange = (e) =>{
        const { value , name } = e.target
        setState({...state,[name]: value})
    }
    useEffect(()=>{
        getRolList();
        getCountryList();
    },[])
    
    const getRolList = ()=>{
        var data={
        }
        axios
        .post(Constant.getAPI() + "/state/list",  data, config)
        .then((res) => {
        const states = res.data?.data    
        const spr = states?.filter(itm => itm.id === params.count_id)
        setState({
            name: spr[0]?.name,
            countryId: spr[0]?.CountryId,
            status: spr[0]?.status,
        })
    })
    .catch((err) => console.log(err));

    }

    const getCountryList = ()=>{
        var DATA={
        }
        dispatch(getCountry(DATA))
    }

    
    useEffect(()=>{
        if(params.count_id){
            var spr = stateList?.filter(itm=>itm.id===params.count_id)
            // console.log("spr",spr);
            setState({
                status:spr[0]?.status,
                name:spr[0]?.name,
                countryId:spr[0]?.country
            })
        }
    },[params.count_id])

    const onSubmit = (e)=>{
        e.preventDefault()
       if(params.count_id){
            var data ={
                StateId:params.count_id,
                status:state?.status,
                name:state?.name,
                countryId:state.countryId
           }
           dispatch(updateState(data))
       }else{
        var data ={
            status:state?.status,
            name:state?.name,
            countryId:state.countryId
           }
           dispatch(addState(data))
        }
    }

    return (   
         <AUX>
        <div className="page-content-wrapper">
                <div className="container-fluid">
    
               <div className="row">
                   <div className="col-lg-12">
                       <div className="card m-b-20">
                           <div className="card-body">
                                   <form onSubmit={onSubmit}>
                                       <div className='row'>
                                           <div className="form-group col-md-6">
                                               <label>State Name</label>
                                               <input type="text" className="form-control" placeholder="State Name" name='name' onChange={handleChange} value={state?.name ? state?.name : ""}/>
                                               {/* <span id="err">{this.state.name_err}</span> */}
                                           </div>
                                           <div className="form-group col-md-6">
                                               <label>Country</label>
                                               <select className="form-control" name="countryId" value={state?.countryId ? state?.countryId : ""} onChange={handleChange}>
                                                    <option> Select country</option>
                                                    {countryList !== undefined && countryList !== null && countryList.length > 0 ? countryList.map((country) => {
                                                       return <option value={country.id} key={country.id}> {country.name}</option>
                                                    }) : null}
                                               </select>
                                               {/* <input type="text" className="form-control" placeholder="Country" name='country' onChange={handleChange}/> */}
                                               {/* <span id="err">{this.state.name_err}</span> */}
                                           </div>
                                           <div className="form-group col-md-6">
                                               <label>Status</label>
                                               <div className="col-md-13">
                                                   <select className="form-control" name='status' value={state?.status} onChange={handleChange} placeholder='Status'>
                                                       <option>Select status</option>
                                                        {options.map(itm => 
                                                         <option value={itm.value} key={itm.label} name={itm.name}>{itm.label}</option>
                                                            )}
                                                   </select>
                                               </div>
                                           </div>
                                       </div>
                                       <div className="form-group">
                                           <div className='d-flex justify-content-end '>
                                               <button type="submit" className="btn btn-primary waves-effect waves-light mr-2">
                                                   Submit
                                               </button>
                                               <Link to='/state' className="btn btn-secondary waves-effect m-l-5">
                                                   Cancel
                                               </Link>
                                           </div>
                                       </div>
                               </form>
                           </div>
                       </div>
                   </div> 
    
               </div> 
           </div> 
       </div>
        </AUX>)
}

export default StateAdd;
import React, { useEffect, useState } from "react";
import AUX from "../../../hoc/Aux_";
import { MDBDataTable } from "mdbreact";
import { deleteRoles, setLoading, updateAdminStatus } from "../../../redux/MainAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import Toggle from "react-toggle";
import { Button } from "reactstrap";
import Axios from "axios";
import { GET_ROLES } from "../../../redux/type";
import Constant from "../../../Constant";
var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

const RolesList = () => {
  const dispatch = useDispatch();
  const roleList = useSelector((state) => state.Zeed.roles);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);

  useEffect(() => {
    fetchPageData(1);
  }, []);

  const fetchPageData = (pageNum) => {
    const DATA = {
      startRange: (pageNum - 1) * perPage,
      count: perPage,
      page: pageNum,
    };
    Axios.post(Constant.getAPI() + "/role/list", DATA, config)
      .then((res) => {
        dispatch({
          type: GET_ROLES,
          payload: res.data.data,
          totalRows: res.data.totalRows,
        });
        setTotalRows(res.data.totalRows);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
    setCurrentPage(pageNum);
  };

  const handlePageChange = (pageNum) => {
    dispatch(setLoading(true));
    fetchPageData(pageNum);
  };

  const handleDelete = (id) => {
    var data = {
      id: id,
    };
    dispatch(deleteRoles(data));
  };

  const handleToggleChange = (id, active) => {
    var data = {
      id: id,
      active: active,
    };
    dispatch(updateAdminStatus(data));
  };

  const data = {
    columns: [
      {
        label: "Role Type",
        field: "name",
      },
      {
        label: "User Name",
        field: "userName",
      },
      {
        label: "User Email",
        field: "email",
      },
      {
        label: "Registered Date",
        field: "createdAt",
      },
      {
        label: "Status",
        field: "isActive",
      },
      {
        label: "Action",
        field: "id",
      },
    ],
    rows: roleList?.map((Item) => {
      return {
        name: Item?.name,
        userName: Item?.Admin?.userName,
        email: Item?.Admin?.email,
        createdAt: moment(Item?.createdAt * 1000).format("L"),
        isActive: <Toggle defaultChecked={Item?.isActive} value={Item?.isActive} onChange={(e) => handleToggleChange(Item.id, e.target.checked)}/>,
        id: (
          <>
            <Link
              to={"/roles/edit/" + Item?.id}
              title="Edit"
              className="mdi mdi-grease-pencil"
            ></Link>{" "}
            <i
              onClick={() => handleDelete(Item.id)}
              title="Delete"
              className="mdi mdi-delete D_BTN"
            ></i>{" "}
          </>
        ),
      };
    }),
  };

  const totalPages = Math.ceil((totalRows ? totalRows : 0) / perPage);

  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <div className="d-flex justify-content-end">
                    <Link to="/roles/add">
                      <Button size="sm" color="secondary" onClick>
                        Add Roles
                      </Button>
                    </Link>
                  </div>
                  <MDBDataTable
                    bordered
                    theadColor="#2632384"
                    paging={false}
                    hover
                    entries={20}
                    data={data}
                  />
                  <div className="pagination-controls">
                    <button
                      className="pagination-btn first-btn"
                      onClick={() => handlePageChange(1)}
                      disabled={currentPage === 1}
                    >
                      First
                    </button>
                    <button
                      className="pagination-btn prev-btn"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <span>
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      className="pagination-btn next-btn"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                    <button
                      className="pagination-btn last-btn"
                      onClick={() => handlePageChange(totalPages)}
                      disabled={currentPage === totalPages}
                    >
                      Last
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{`
        .pagination-controls {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 20px;
        }
        select{
          width: 27%;
        }
        .pagination-btn {
          background-color: #263238;
          color: white;
          border: none;
          padding: 10px 20px;
          margin: 0 5px;
          cursor: pointer;
          border-radius: 5px;
          font-size: 14px;
        }
        
        .pagination-btn:disabled {
          background-color: #cccccc;
          cursor: not-allowed;
        }
        
        .pagination-btn:not(:disabled):hover {
          background-color: #263233;
        }
      `}</style>
    </AUX>
  );
};

export default RolesList;

import React, { useEffect, useState } from 'react';
import AUX from '../../../hoc/Aux_';
import { useParams, Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getUserDropdown, getSubCategoryDropdown, getProduct,productUpdateTimestamp, updateProduct, getCategoryDropdown, removeVariantProduct, removeImageProduct } from '../../../redux/MainAction';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Axios from "axios";
import Constant from "../../../Constant";
import Swal from 'sweetalert2';


const ProductEdit = () => {
    const params = useParams();
    const role = localStorage.getItem("role");
    const dispatch = useDispatch();
    const fixedList = useSelector(state => state.Zeed.productList);
    // const SubList = useSelector(state => state.Zeed.subCategory);
    const categoryList = useSelector((state) => state.Zeed.category);
    const UserList = useSelector(state => state.Zeed.userList);
    // const [usValue, setUserValue] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    // const CateList = useSelector(state => state.Zeed.category);
    // const [catValue, setCategoryValue] = useState(null);
    // const [categoryOptions, setCategoryOptions] = useState([]);
    // const [subCatValue, setSubCategoryValue] = useState([]);
    // const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    // const [variants, setVariants] = useState([]);
    const [prductImageId, setProductImageId] = useState("");
    const [productImages, setProductImages] = useState([]);

    const onImageChange = async (e) => {
      const files = Array.from(e.target.files);
      const uploadedImages = await Promise.all(
        files.map(async (file) => {
          try {
            const img = await UploadMedia(file);
            return img;
          } catch (error) {
            console.error("Error uploading media:", error);
            return null;
          }
        })
      );

      setProductImages((prevImages) => [
        ...prevImages,
        ...uploadedImages.filter((img) => img !== null),
      ]);
    };

    const addTimestamp = (id) => {
        const timestamp = new Date().toISOString();
        const data = { postId: params.id, updatedAt: timestamp, mediumId: id };
        dispatch(productUpdateTimestamp(data));
      };

    const UploadMedia = async (imageData) => {
      const formData = new FormData();
      formData.append("media", imageData);
      // formData.append("user", userId);
      try {
        const response = await Axios.post(
          `${Constant.getAPI()}/media/add`,
          formData,
          {
            headers: {
              Authorization: localStorage.getItem("Token"),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.status) {
          return {
            id: response.data.data[0]?.id,
            url: response.data.data[0]?.url,
          };
        } else {
          return null;
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    };

      const removeImage = (imageId) => {
        setProductImages((prevImages) =>
          prevImages.filter((img) => img.id !== imageId)
        );
        const data = { mediumId: imageId, postId: params.id }
        dispatch(removeImageProduct(data));
      };
    
    useEffect(() => {
        getRolList();
    }, []);

    const path = window.location.pathname;
    const tri = path.slice(0, 14);

    const getRolList = () => {
        var DATA = {
            startRange: '0',
            count: "1000"
        };
        dispatch(getCategoryDropdown(DATA));
        // dispatch(getCategory(DATA));
        dispatch(getUserDropdown(DATA));
        dispatch(getSubCategoryDropdown(DATA));
        if (tri === "/product-Fixed") {
            var DATA = {
                startRange: '0',
                count: "10000",
                type: "Fixed",
                all: "1"
            };
            dispatch(getProduct(DATA));
        } else {
            var DATA = {
                startRange: '0',
                count: "10000",
                type: "Auction",
                all: "1"
            };
            dispatch(getProduct(DATA));
        }
    };

    // const onCategoryChange = (selectedOption) => {
    //     if (selectedOption) {
    //         setCategoryValue(selectedOption.value);
    //         dispatch(getSubCategoryDropdown(selectedOption.value));
    //     }
    //     setCoup(prevState => ({
    //         ...prevState,
    //         categoryId: selectedOption.value
    //     }));
    // };
    
    // const onUserChange = (selectedOption) => {
    //     if (selectedOption) {
    //         setUserValue(selectedOption.value);
    //     }
    //     setCoup(prevState => ({
    //         ...prevState,
    //         userId: selectedOption.value
    //     }));
    // };

    // useEffect(() => {
    //     if (CateList?.length > 0) {
    //         var data = CateList?.map(opt => {
    //             return {
    //                 label: opt.name,
    //                 value: opt.id
    //             };
    //         });
    //         setCategoryOptions(data);
    //     }
    // }, [CateList]);

    // useEffect(() => {
    //     if (UserList?.length > 0) {
    //         const data = UserList.map(opt => {
    //             return {
    //                 label: opt.userName,
    //                 value: opt.id
    //             };
    //         });
    //         setUserOptions(data);
    //     }
    // }, [UserList]);

    // useEffect(() => {
    //     if (SubList?.length > 0) {
    //         const data = SubList.map(opt => {
    //             return {
    //                 label: opt.nameEn,
    //                 value: opt.id
    //             };
    //         });
    //         setSubCategoryOptions(data);
    //     }
    // }, [SubList]);

    const [coup, setCoup] = useState({
        categoryId: '',
        subCategoryId: '',
        userId: '',
        title: "",
        title_ar: "",
        price: "",
        quantity: "",
        about: "",
        about_ar: "",
        variants:[],
        type: tri === "/product-Fixed" ? "Fixed" : "Auction",
    });

    useEffect(() => {
        if (coup.categoryId || coup.userId) {
            const fetchSubCategories = async () => {
                const response = await Axios.get(`${Constant.getAPI()}/admin/get-sub-category-dropdown?categoryId=${coup.categoryId}&userId=${coup.userId}`, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('ucs-token')
                    }
                });
                setSubCategoryList(response.data.data);
            };
            fetchSubCategories();
        }
    }, [coup.categoryId, coup.userId]);

    const handleChange = (e) => {
        const { value, name } = e.target;
        if (name === "categoryId" || name === "userId") {
            setCoup((prevCoup) => ({
                ...prevCoup,
                [name]: value,
                subCategoryId: "",
            }));
        } else {
            setCoup({ ...coup, [name]: value });
        }
    };

    const [variants, setVariants] = useState([]);

    useEffect(() => {
        const totalQuantity = variants.reduce((total, variant) => total + (parseInt(variant.quantity) || 0), 0);
        if (totalQuantity > 0) {
            setCoup(prevState => ({ ...prevState, quantity: totalQuantity }));
          }
    }, [variants]);

    const onAddVariant = () => {
        const newVariant = {
            nameEn: '',
            nameAr: '',
            var2En: '',
            var2Ar: '',
            price: '',
            quantity: '',
            VariantAttributes: [
                { Attribute: { name: '', name_ar: '' } },
                { Attribute: { name: '', name_ar: '' } }
            ]
        };
        setVariants(prevVariants => [...prevVariants, newVariant]);
    };
    

    const removeVariant = (index) => {
        const filteredVariants = [...variants];
        filteredVariants.splice(index, 1);
        setVariants(filteredVariants);
        const data = { variantId: index, postId: params.id }
        dispatch(removeVariantProduct(data));
    };

    const variantHandleChange = (e, index) => {
        const { name, value } = e.target;
    
        setVariants(prevVariants => 
            prevVariants.map((variant, i) => {
                if (i === index) {
                    const updatedVariant = { ...variant };
                    if (name === "nameEn") {
                        updatedVariant.VariantAttributes[0].Attribute.name = value;
                    } else if (name === "nameAr") {
                        updatedVariant.VariantAttributes[0].Attribute.name_ar = value;
                    } else if (name === "var2En") {
                        updatedVariant.VariantAttributes[1].Attribute.name = value;
                    } else if (name === "var2Ar") {
                        updatedVariant.VariantAttributes[1].Attribute.name_ar = value;
                    } else {
                        updatedVariant[name] = value;
                    }
                    return updatedVariant;
                }
                return variant;
            })
        );
    };
    

    const [tabID, setTabID] = useState("productDetail");

    const handleTabChange = (tab) => {
      setTabID(tab);
    };

    useEffect(() => {
        if (params.id && fixedList) {
            var spr = fixedList?.filter(itm => itm.id === params.id);
            // const CateList = spr[0]?.category || [];
            // if (CateList?.length) {
            //     const cateArr = [];
            //     for (const ct of CateList) {
            //         const checkCat = CateList?.find(ele => ele.id === ct);
            //         if (checkCat) {
            //             cateArr.push({
            //                 label: checkCat.name,
            //                 value: checkCat.id
            //             });
            //             onCategoryChange(cateArr);
            //         }
            //     }
            // }
            // const UserList = spr[0]?.user || [];
            // if (UserList?.length) {
            //     const userArr = [];
            //     for (const us of UserList) {
            //         const checkUs = UserList?.find(ele => ele.id === us);
            //         if (checkUs) {
            //             userArr.push({
            //                 label: checkUs.userName,
            //                 value: checkUs.id
            //             });
            //             onUserChange(userArr);
            //         }
            //     }
            // }
            setCoup({
                title: spr[0]?.title,
                title_ar: spr[0]?.title_ar,
                price: spr[0]?.price,
                quantity: spr[0]?.quantity,
                about: spr[0]?.about,
                about_ar: spr[0]?.about_ar,
                type: spr[0]?.type,
                categoryId: spr[0]?.Category?.id,
                subCategoryId: spr[0]?.SubCategoryId,
                userId: spr[0]?.UserId,
                // variants: spr?.Variants || []
            });
            setVariants(spr[0]?.Variants || []);
            console.log(spr[0]?.Media,'---spr[0]?.Media----');
            const mediaUrl = spr[0]?.Media?.[0]?.url;
            if (mediaUrl) {
                // setProductImageId(spr[0]?.Media?.[0]?.id)`;
                setProductImages(spr[0]?.Media);
            }
        }
    }, [params.id, fixedList]);

    const onSubmit = (e) => {
        e.preventDefault();
        if (!coup?.title || !coup?.title_ar) {
          showWarning("Please Add Title");
          return false;
        }
        if (!coup?.categoryId) {
          showWarning("Please Add Category");
          return false;
        }
        if (!coup?.subCategoryId) {
          showWarning("Please Add SubCategory");
          return false;
        }
        if (!coup?.userId) {
          showWarning("Please Add User");
          return false;
        }
        if (!coup?.price) {
          showWarning("Please Add Price");
          return false;
        }
        if (!coup?.quantity) {
            showWarning("Please Add Quantity");
            return false;
          }
        if (coup.quantity < 0) {
            showWarning("Quantity cannot be negative");
            return false;
        }
        const categoryId = coup.categoryId;
        const userId = coup.userId;
        const subCategoryId = coup.subCategoryId;
        const formattedVariants = variants.map(variant => ({
            price: variant.price,
            quantity: variant.quantity,
            attributes: variant.VariantAttributes.map(attr => ({
                nameEn: attr.Attribute.name,
                nameAr: attr.Attribute.name_ar
            }))
            // attributes: [
            //     { nameEn: variant.name, nameAr: variant.name_ar },
            //     { nameEn: variant.name, nameAr: variant.name_ar }
            // ]
        })
    );
        if (params.id) {
            var data = {
                postId: params?.id,
                title: coup.title,
                title_ar: coup.title_ar,
                price: coup.price,
                quantity: coup.quantity,
                about: coup.about,
                about_ar: coup.about_ar,
                type: coup.type,
                categoryId: categoryId,
                userId: userId,
                variants: formattedVariants,
                subCategoryId: subCategoryId,
                productImageUrl: productImages,
            };
            // return
            dispatch(updateProduct(data))
        } 
    };

    function showWarning(message) {
      Swal.fire({
        title: "Warning",
        icon: "warning",
        text: message,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    }
    
    return (
        <AUX>
            <div className="page-content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className={`nav-link ${tabID === "productDetail" ? "active" : ""}`}
                                                data-toggle="tab" href="#productDetail" role="tab" onClick={() => handleTabChange("productDetail")}>
                                                <span className="d-none d-md-block"> Product Detail</span>
                                                <span className="d-block d-md-none"> <i className="mdi mdi-home-variant h5"></i></span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${tabID === "productImage" ? "active" : ""}`}
                                                data-toggle="tab" href="#productImage" role="tab" onClick={() => handleTabChange("productImage")}>
                                                <span className="d-none d-md-block">Product Image</span>
                                                <span className="d-block d-md-none"> <i className="mdi mdi-account h5"></i></span>
                                            </a>
                                        </li>
                                    </ul>
                                       

                                    <div className="tab-content mt-3">
                                        <div className={`tab-pane ${tabID === "productDetail" ? "active" : ""} p-3`} role="tabpanel">      
                                            
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <label>Title in English <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" placeholder="Title in English" name='title' value={coup.title} onChange={handleChange}/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Title in Arabic <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" placeholder="Title in Arabic" name='title_ar' value={coup.title_ar} onChange={handleChange}/>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                {/* <div className="col-md-6">
                                                    <label>Category</label>
                                                    <Select
                                                    onChange={selectedOption => setCategoryValue(selectedOption.value)}
                                                    options={categoryOptions}
                                                    value={categoryOptions.find(option => option.value === catValue)}
                                                    />
                                                    <Select onChange={onCategoryChange} options={categoryOptions}
                                                        value={categoryOptions.find(option => option.value === catValue)} />
                                                </div> */}
                                                    {role !== 'vendor' ? (   
                                                <div className="form-group col-md-6">
                                                   <label>Seller <span style={{ color: 'red' }}>*</span></label>
                                                   <div className="col-md-13">
                                                      <select
                                                      className="form-control"
                                                      name="userId"
                                                      value={coup.userId ? coup.userId : ""}
                                                      onChange={handleChange}
                                                      >
                                                      <option value="" disabled>
                                                        Select a user
                                                      </option>
                                                      {UserList && UserList.length > 0
                                                      ? UserList.map((itm) => (
                                                      <option value={itm.id} key={itm.id}>
                                                         {itm.userName}
                                                      </option>
                                                      ))
                                                      : null}
                                                      </select>
                                                   </div>
                                                 </div>
                                                  ) : null}
                                                <div className="form-group col-md-6">
                                                   <label>Category <span style={{ color: 'red' }}>*</span></label>
                                                   <div className="col-md-13">
                                                      <select
                                                      className="form-control"
                                                      name="categoryId"
                                                      value={coup.categoryId ? coup.categoryId : ""}
                                                      onChange={handleChange}
                                                      >
                                                      <option value="" disabled>
                                                        Select a category
                                                      </option>
                                                      {categoryList && categoryList.length > 0
                                                      ? categoryList.map((itm) => (
                                                      <option value={itm.id} key={itm.id}>
                                                         {itm.name}
                                                      </option>
                                                      ))
                                                      : null}
                                                      </select>
                                                   </div>
                                                 </div>
                                                {/* <div className="col-md-6">
                                                    <label>Sub Category</label>
                                                    <Select onChange={setSubCategoryValue} options={subCategoryOptions}
                                                        value={subCategoryOptions.find(option => option.value === subCatValue)} />
                                                </div> */}
                                                <div className="form-group col-md-6">
                                                   <label>Sub Category <span style={{ color: 'red' }}>*</span></label>
                                                   <div className="col-md-13">
                                                      <select
                                                      className="form-control"
                                                      name="subCategoryId"
                                                      value={coup.subCategoryId ? coup.subCategoryId : ""}
                                                      onChange={handleChange}
                                                      >
                                                      <option value="" disabled>
                                                        Select a sub category
                                                      </option>
                                                      {subCategoryList && subCategoryList.length > 0
                                                      ? subCategoryList.map((itm) => (
                                                      <option value={itm.id} key={itm.id}>
                                                         {itm.nameEn}
                                                      </option>
                                                      ))
                                                      : null}
                                                      </select>
                                                   </div>
                                                 </div>
                                            </div>

                                            <div className="form-group row">
                                                {/* <div className="col-md-6">
                                                    <label>User</label>
                                                    <Select onChange={onUserChange} options={userOptions}
                                                        value={userOptions.find(option => option.value === usValue)} />
                                                </div> */}
                                                {/* {role !== 'vendor' ? (   
                                                <div className="form-group col-md-6">
                                                   <label>Seller <span style={{ color: 'red' }}>*</span></label>
                                                   <div className="col-md-13">
                                                      <select
                                                      className="form-control"
                                                      name="userId"
                                                      value={coup.userId ? coup.userId : ""}
                                                      onChange={handleChange}
                                                      >
                                                      <option value="" disabled>
                                                        Select a user
                                                      </option>
                                                      {UserList && UserList.length > 0
                                                      ? UserList.map((itm) => (
                                                      <option value={itm.id} key={itm.id}>
                                                         {itm.userName}
                                                      </option>
                                                      ))
                                                      : null}
                                                      </select>
                                                   </div>
                                                 </div>
                                                  ) : null} */}
                                                <div className="col-md-6">
                                                    <label>Sell Type <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="col-md-13">
                                                        <select className="form-control" name='status' value={coup.type} onChange={handleChange}>
                                                            <option value="Fixed">Fixed</option>
                                                            <option value="Auction">Auction</option>
                                                        </select>
                                                    </div>
                                                </div>  
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <label>Description in English</label>
                                                    <textarea  className="form-control" placeholder="Description in English" name='about' 
                                                        value={coup.about} onChange={handleChange} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Description in Arabic</label>
                                                    <textarea  className="form-control" placeholder="Description in Arabic" name='about_ar' 
                                                        value={coup.about_ar} onChange={handleChange} />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <label>Price <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" placeholder="Price" name='price' value={coup.price} onChange={handleChange}/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Quantity</label>
                                                    <input type="number" className="form-control"  placeholder='Quantity' name='quantity' value={coup.quantity} onChange={handleChange}/>
                                                </div>     
                                            </div>
   
                                            <div className="form-group col-md-12">
                                                <button type='button' className="btn btn-info waves-effect m-l-5" onClick={onAddVariant}>+ Add Variant</button>
                                            </div>
                                            <div className='col-md-12 row'>
                                                {variants?.map((data, index) => {
                                                    return (
                                                        <div className='row col-md-12 attribt' key={index}>
                                                            <div className='col-md-6'>
                                                                <div className='form-group row'>
                                                                    <div className='col-sm-10'>
                                                                        <label>Variant 1 in English</label>
                                                                        <input type='text' className="form-control"  name="nameEn" placeholder="Variant 1 in English"
                                                                            required value={(data.VariantAttributes && data.VariantAttributes.length > 0 && data.VariantAttributes[0]?.Attribute?.name) ? data.VariantAttributes[0]?.Attribute?.name : ''} onChange={(e) => variantHandleChange(e, index)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='form-group row'>
                                                                    <div className='col-sm-10'>
                                                                        <label>Variant 1 in Arabic</label>
                                                                        <input type='text' className="form-control" name="nameAr" placeholder="Variant 1 in Arabic"
                                                                            required value={(data.VariantAttributes && data.VariantAttributes.length > 0 && data.VariantAttributes[0]?.Attribute?.name_ar) ? data.VariantAttributes[0]?.Attribute?.name_ar : ''} onChange={(e) => variantHandleChange(e, index)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='form-group row'>
                                                                    <div className='col-sm-10'>
                                                                        <label>Variant 2 in English</label>
                                                                        <input type='text' className="form-control"  name="var2En" placeholder="Variant 2 in English"
                                                                            required value={(data.VariantAttributes && data.VariantAttributes.length > 1 && data.VariantAttributes[1]?.Attribute?.name) ? data.VariantAttributes[1]?.Attribute?.name : ''} onChange={(e) => variantHandleChange(e, index)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='form-group row'>
                                                                    <div className='col-sm-10'>
                                                                        <label>Variant 2 in Arabic</label>
                                                                        <input type='text' className="form-control" name="var2Ar"  placeholder="Variant 2 in Arabic"
                                                                            required value={(data.VariantAttributes && data.VariantAttributes.length > 1 && data.VariantAttributes[1]?.Attribute?.name_ar) ? data.VariantAttributes[1]?.Attribute?.name_ar : ''} onChange={(e) => variantHandleChange(e, index)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='form-group row'>
                                                                    <div className='col-sm-10'>
                                                                        <label>Variant Price</label>
                                                                        <input type='text' className="form-control" name="price" placeholder="Variant Price"
                                                                            required value={data.price} onChange={(e) => variantHandleChange(e, index)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='form-group row'>
                                                                    <div className='col-sm-10'>
                                                                        <label>Variant Quantity</label>
                                                                        <input  type='number' className="form-control"  name="quantity" placeholder="Variant Quantity"
                                                                            required value={data.quantity} onChange={(e) => variantHandleChange(e, index)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 mt-3">
                                                                <button type='button' className="btn btn-secondary waves-effect m-l-5" onClick={() => removeVariant(data.id)}>Remove</button>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                        </div>

                                        <div className={`tab-pane ${tabID === "productImage" ? "active" : ""} p-3`} role="tabpanel">
                                            <div className="form-group col-md-6">
                                                <label> Images</label>
                                                <input type="file" accept="image/png, image/gif, image/jpeg, application/pdf , .doc, .docx"
                                                    className="form-control"  onChange={onImageChange} multiple />
                                            </div>
                                            {/* {productImg && (
                                                <div className="form-group col-md-6">
                                                    <img src={productImg} alt="" className="img-fluid" />
                                                </div>
                                            )} */}
                                            
                                            {/* {productImg !== "" && (
                                                <div className="col-md-9">
                                                    <div className="imgDiv">
                                                    <a href={productImg} target="_blank" > {productImg}</a>
                                        
                                                    <button onClick={() => { setProductImageId(""); setProductImg(""); }}>REMOVE</button>
                                                    </div>
                                                </div>
                                            )} */}
                                                  {Array.isArray(productImages) && productImages.length > 0 && productImages.map((img, index) => (
                                                    <div key={index} className="col-md-9">
                                                        <div className="imgDiv">
                                                        <a href={img.url} target="_blank" rel="noopener noreferrer">
                                                            <img src={img.url} alt={`Product ${index}`} className="product-image" />
                                                        </a>
                                                        </div>
                                                        <div className="button-group">
                                                            <button onClick={() => removeImage(img.id)} className="remove-btn">REMOVE</button>
                                                            <button onClick={() => addTimestamp(img.id)} className="timestamp-btn">SET THUMBNAIL</button>
                                                        </div>
                                                    </div>
                                                    ))}
                                           
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className='d-flex justify-content-end '>
                                            <button type="submit" onClick={onSubmit} className="btn btn-primary waves-effect waves-light mr-2">
                                                Submit
                                            </button>
                                            <Link to={`${tri === "/product-Fixed" ? "/product-Fixed" : "/product-Auction"}`} className="btn btn-secondary waves-effect m-l-5">
                                                Cancel
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AUX>
    );

};

export default ProductEdit;
import React, { useEffect, useState } from 'react';
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getReportedPOST } from '../../../redux/MainAction';
import moment from 'moment';
import axios from 'axios';
import { setLoading } from '../../../redux/MainAction';
import { GET_REPORTED_POSTS } from '../../../redux/type';
import Constant from '../../../Constant';

const config = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loged_In_auth'),
    },
};

const ReportedPosts = () => {
    const dispatch = useDispatch();
    const reportList = useSelector((state) => state.Zeed.reportPost);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20);

    useEffect(() => {
        dispatch(setLoading(true));
        fetchPageData(1);
    }, []);

    const fetchPageData = (pageNum) => {
        const DATA = {
            startRange: (pageNum - 1) * perPage,
            count: perPage,
            page: pageNum,
        };
        axios
            .post(Constant.getAPI() + '/post/backend-getAll-reportedPost', DATA, config)
            .then((res) => {
                dispatch({
                    type: GET_REPORTED_POSTS,
                    payload: res.data.data,
                    totalRows: res.data.totalRows,
                });
                setTotalRows(res.data.totalRows);
                dispatch(setLoading(false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(setLoading(false));
            });
        setCurrentPage(pageNum);
    };

    const handlePageChange = (pageNum) => {
        dispatch(setLoading(true));
        fetchPageData(pageNum);
    };

    const columns = [
        {
            label: 'Image',
            field: 'Post',
        },
        {
            label: 'Post Name',
            field: 'postName',
        },
        {
            label: 'User Name',
            field: 'User',
        },
        {
            label: 'User Email',
            field: 'email',
        },
        {
            label: 'Date',
            field: 'updatedAt',
        },
        {
            label: 'Action',
            field: 'id',
        },
    ];

    const rows = reportList.map((report) => ({
        Post: <img src={report?.Post?.Media?.[0]?.url} height={50} alt="Post" />,
        postName: report?.Post?.title,
        User: report?.User?.userName,
        email: report?.User?.email,
        updatedAt: moment(report?.updatedAt).format('L'),
        id: (
            <Link to={`/reported-Posts/view/${report.id}`} title="View" className="mdi mdi-eye"></Link>
        ),
    }));

    const data = {
        columns,
        rows,
    };

    const totalPages = Math.ceil((totalRows ? totalRows : 0) / perPage);

    return (
        <AUX>
            <div className="page-content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <MDBDataTable
                                        bordered
                                        theadColor="#2632384"
                                        paging={false}
                                        hover
                                        entries={20}
                                        data={data}
                                    />
                                    <div className="pagination-controls">
                                        <button
                                            className="pagination-btn first-btn"
                                            onClick={() => handlePageChange(1)}
                                            disabled={currentPage === 1}
                                        >
                                            First
                                        </button>
                                        <button
                                            className="pagination-btn prev-btn"
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1}
                                        >
                                            Previous
                                        </button>
                                        <span>
                                            Page {currentPage} of {totalPages}
                                        </span>
                                        <button
                                            className="pagination-btn next-btn"
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            disabled={currentPage === totalPages}
                                        >
                                            Next
                                        </button>
                                        <button
                                            className="pagination-btn last-btn"
                                            onClick={() => handlePageChange(totalPages)}
                                            disabled={currentPage === totalPages}
                                        >
                                            Last
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style>{`
                .pagination-controls {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-top: 20px;
                }
                select {
                    width: 27%;
                }
                .pagination-btn {
                    background-color: #263238;
                    color: white;
                    border: none;
                    padding: 10px 20px;
                    margin: 0 5px;
                    cursor: pointer;
                    border-radius: 5px;
                    font-size: 14px;
                }
                .pagination-btn:disabled {
                    background-color: #cccccc;
                    cursor: not-allowed;
                }
                .pagination-btn:not(:disabled):hover {
                    background-color: #263233;
                }
            `}</style>
        </AUX>
    );
};

export default ReportedPosts;

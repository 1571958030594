import React, { useEffect, useState } from 'react'
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import { getNotifications } from '../../../redux/MainAction'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Toggle from 'react-toggle'
import { Button } from 'reactstrap';
import axios from "axios";
import { GET_NOTIFICATIONS } from "../../../redux/type";
import Constant from "../../../Constant";
import { setLoading } from "../../../redux/MainAction";

var config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("loged_In_auth"),
    },
};

const PushNoti = () => {
    const dispatch = useDispatch()
    const NotiList = useSelector(state=>state.Zeed.notification);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const role = localStorage.getItem("role");

    useEffect(()=>{
        dispatch(setLoading(true));
        fetchPageData(1);
        // getRolList()
    },[])
    
    const fetchPageData = (pageNum) => {
        const DATA = {
            startRange: (pageNum - 1) * perPage,
            count: perPage,
            page: pageNum,
        };
        axios
            .post(Constant.getAPI() + "/notification/backend-getAll", DATA, config)
            .then((res) => {
                // window.setLoading(true)
                dispatch({
                    type: GET_NOTIFICATIONS,
                    payload: res.data.data,
                    totalRows: res.data.totalRows
                });
                setTotalRows(res.data.totalRows)
                dispatch(setLoading(false));
            })
            .catch((err) => {
                console.log(err)
                dispatch(setLoading(false))
            }
            );
        setCurrentPage(pageNum);
    };

    const handlePageChange = (pageNum) => {
        dispatch(setLoading(true));
        fetchPageData(pageNum);
    };

    const handleEditClick = async (notificationId) => {
        dispatch(setLoading(true));
        try {
            const response = await axios.post(`${Constant.getAPI()}/notification/get-admin-seen`, { id: notificationId }, config);
            // console.log(response.data);
            dispatch(setLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setLoading(false));
        }
    };

    const handlePerPageChange = (event) => {
        setPerPage(Number(event.target.value));
        fetchPageData(1);
    };

    // const getRolList = ()=>{
    //     dispatch(getNotifications())
    // }
    // console.log("NotiList",NotiList);
    const columns = [
            {
                label: "Type",
                field: "type",  
            },
            {
                label: "Text",
                field: "text",  
            },
            {
                label: "Date",
                field: "createdAt",  
            },
            {
                label: "Action",
                field: "id",
              },
        ];

    const rows = NotiList.map((Item) => {
        return {
            type: Item.type,
            text: Item.text,
            createdAt: moment(Item.createdAt * 1000).format('L'),
            id: (
                <>
                    <Link to={`/orders/${role == "admin" ? Item.orderMasterId : Item.orderSellerId}`} onClick={() => handleEditClick(Item.id)} title="View" className="mdi mdi-eye"></Link>
                </>
            ),
        }
    });

    const data = {
        columns,
        rows,
    };
    const totalPages = Math.ceil((totalRows ? totalRows : 0) / perPage);
  return (
    <AUX>
    <div className="page-content-wrapper">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card m-b-20">
                        <div className="card-body">
                           <div className='d-flex justify-content-end'>
                           <Link to='/push-notification/add'>
                            <Button size="sm" color="secondary" onClick >Send Notification</Button>
                           </Link>
                           </div>
                            <MDBDataTable
                                bordered
                                theadColor="#2632384"
                                paging={false}
                                hover
                                entries={20}
                                data={data}
                            />
                            <div className="pagination-controls">
                                      <button className="pagination-btn first-btn" onClick={() => handlePageChange(1)} disabled={currentPage === 1}>First</button>
                                      <button className="pagination-btn prev-btn" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                      <span>Page {currentPage} of {totalPages}</span>
                                      <button className="pagination-btn next-btn" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                                      <button className="pagination-btn last-btn" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>Last</button>
                                  </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div> 
    </div>
</AUX>
  )
}

export default PushNoti
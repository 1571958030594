import React, { useEffect, useState } from 'react'
import AUX from '../../../hoc/Aux_';
import { Link ,useParams } from 'react-router-dom';
import {addRole , editRole , getRole , getRoleModules} from "../../../redux/MainAction"
import { useDispatch, useSelector } from 'react-redux';
import 'react-responsive-modal/styles.css';
// import { Modal } from 'react-responsive-modal';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { param } from 'jquery';

const RolesAdd = () => {
    const params = useParams();
    const dispatch = useDispatch()
    const roleList = useSelector(state=>state.Zeed.roles)
    const roleModule = useSelector(state=>state.Zeed.modulesList)

    const [ modulOptions , setModulOptions ] = useState([])
    const [ modulValue , setModulValue ] = useState([])
    // const [open, setOpen] = useState(false);
    // const onOpenModal = () => setOpen(true);
    // const onCloseModal = () => setOpen(false);

    const [roles , setRoles]=useState({
        name:"",
        moduleIds:[],
        email:'',
        password:'',
        userName:'',
        isActive:"true"
    })
    useEffect(()=>{
        getRolList()
      },[])
    
      const getRolList = ()=>{
        var DATA={
          startRange:'0',
          count:"1000"
        }
        dispatch(getRole(DATA))
        dispatch(getRoleModules())
    }
    // console.log("params",params.id);
    useEffect(()=>{
        if(roleList&&params.id){
            var spr = roleList.filter(data=>data.id==params.id)
            // console.log("spr",spr);
            setRoles({
                name: spr.length>0?spr[0].name :'',
                isActive: spr.length>0?spr[0].isActive=== true ? 'true':false:'',
                email:spr[0]?.Admin?.email,
                userName:spr[0]?.Admin?.userName,
            })
            setModulValue(spr[0]?.modules?.map((mod)=> {
                return {
                    label:mod?.name,
                    value:mod.id
                }
            }))
        }
    },[roleList])

    const onModuleChange = (value) => {
        if(value){
            setModulValue(value)
        }
    }

    useEffect(()=>{
        if(roleModule?.length>0){
            var data = roleModule?.map(opt=>{
                return {
                    label:opt.name,
                    value:opt.id
                }
            })
            setModulOptions(data)

        }
    },[roleModule])

    const handleChange = (e) =>{
        const { value , name } = e.target
        setRoles({...roles,[name]: value})
    }
    const onSubmit = (e)=>{
        e.preventDefault()

        if (roles?.name === '') {
            Swal.fire({
                title: "Warning",
                icon: "warning",
                text: "Please Add Roles Type",
                type: "warning",
                confirmButtonColor: "#3ab1f7",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
            });
            return false
        }
        else if (modulValue.length < 1) {
            Swal.fire({
                title: "Warning",
                icon: "warning",
                text: "Please Select Module",
                type: "warning",
                confirmButtonColor: "#3ab1f7",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
            });
            return false
        }
        else if (roles?.email === '') {
            Swal.fire({
                title: "Warning",
                icon: "warning",
                text: "Please Add Email",
                type: "warning",
                confirmButtonColor: "#3ab1f7",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
            });
            return false
        }
        else if (roles?.password === '') {
            Swal.fire({
                title: "Warning",
                icon: "warning",
                text: "Please Add Password",
                type: "warning",
                confirmButtonColor: "#3ab1f7",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
            });
            return false
        }
        else if (roles?.userName === '') {
            Swal.fire({
                title: "Warning",
                icon: "warning",
                text: "Please Add User Name",
                type: "warning",
                confirmButtonColor: "#3ab1f7",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
            });
            return false
        }
        
        var moduleData = modulValue?.map(opt=>opt.value)
        if(params.id){
            if(roles.password !==''){
                var data = {
                    id: params?.id,
                    name: roles?.name,
                    moduleIds: moduleData,
                    isActive: roles?.isActive === "true"? true : false,
                    admin: {
                        email: roles?.email,
                        password: roles?.password,
                        userName: roles?.userName,
                    }
                   
                }
                 dispatch(editRole(data))
                //  console.log("data",data)
            }else{
                var data = {
                    id: params?.id,
                    name: roles?.name,
                    moduleIds: moduleData,
                    isActive: roles?.isActive === "true"? true : false,
                    admin: {
                        email: roles?.email,
                        // password: roles?.password,
                        userName: roles?.userName,
                    }
                }
                dispatch(editRole(data))
                // console.log("data",data);
            }
        }else{
            var data = {
                name:roles?.name,
                moduleIds:moduleData,
                admin: {
                    email: roles?.email,
                    password: roles?.password,
                    userName: roles?.userName,
                }
            }
            dispatch(addRole(data))
            // console.log("data",data);
        } 
    }
    // console.log("modulValue",modulValue);
  return (
    <AUX>
         <div className="page-content-wrapper">
                 <div className="container-fluid">

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card m-b-20">
                            <div className="card-body">
                                    <form onSubmit={onSubmit}>
                                        <div className='row'>
                                            <div className="form-group col-md-6">
                                                <label>Roles Type</label>
                                                <input type="text" className="form-control" placeholder="Roles Type" name='name' value={roles.name} onChange={handleChange}/>
                                                {/* <span id="err">{this.state.name_err}</span> */}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <div className="form-group mb-0">
                                                    <label className="control-label">Select Modules</label>
                                                    <Select
                                                        onChange={onModuleChange}
                                                        options={modulOptions}
                                                        value={modulValue}
                                                        isMulti ={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                    <label>User Name</label>
                                                    <input type="text" className="form-control" placeholder="User Name" name='userName' value={roles.userName} onChange={handleChange}/>
                                            </div>
                                            <div className="form-group col-md-6">
                                                    <label>Email</label>
                                                    <input type="email" className="form-control" placeholder="Email" name='email' value={roles.email} onChange={handleChange}/>
                                            </div>
                                            <div className="form-group col-md-6">
                                                    <label>Password</label>
                                                    <input type="password" className="form-control" placeholder="Password" name='password' value={roles.password} onChange={handleChange}/>
                                            </div>
                                            {param?.id? <div className="form-group col-md-6">
                                                <label>Status</label>
                                                <div className="col-md-13">
                                                    <select className="form-control" name='isActive' value={roles.isActive} onChange={handleChange}>
                                                        <option value="true">Active</option>
                                                        <option value="false">In-Active</option>
                                                    </select>
                                                </div>
                                            </div>:''}
                                        </div>
                                        {/* <Modal open={open} onClose={onCloseModal} classNames='modal' center>
                                            <div className="AdminModal">
                                                <h4>Pleases verify your Creds!</h4>
                                               

                                                
                                               
                                                
                                                <div className='form-group'>
                                                    <div className='d-flex justify-content-end '>
                                                        <button  onClick={onSubmit} type="submit" className="btn btn-primary waves-effect waves-light mr-2">
                                                            Save
                                                        </button>
                                                        <button onClick={onCloseModal} className="btn btn-secondary waves-effect m-l-5">
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal> */}
                                        <div className="form-group">
                                            <div className='d-flex justify-content-end '>
                                                <button  onClick={onSubmit} type="submit" className="btn btn-primary waves-effect waves-light mr-2">
                                                    Submit
                                                </button>
                                                <Link to='/roles' className="btn btn-secondary waves-effect m-l-5">
                                                    Cancel
                                                </Link>
                                            </div>
                                        </div>
                                </form>
                            </div>
                        </div>
                    </div> 

                </div> 
            </div> 
        </div>
    </AUX>
  )
}

export default RolesAdd
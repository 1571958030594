import React , {Component } from 'react';
import AUX from '../../../hoc/Aux_';
import C3Chart from 'react-c3js';
import 'c3/c3.css';

const colors = {
  pattern : ['#86A789','#CE5A67','#A8DF8E',"#232D3F","#FF3FA4" ] 
};

const data = {
        type: 'donut',
        columns: [
          ['IPhone 14', 4],
          ['Brown Jacket', 11],
          ['White Hoodie', 5],
          ['white t-shirt', 9],
          ['Shirts', 7],
      ], 
      
       onclick: function (d, i) { console.log("onclick", d, i); },
       onmouseover: function (d, i) { console.log("onmouseover", d, i); },
       onmouseout: function (d, i) { console.log("onmouseout", d, i); }
};

class MostViewPurchase extends Component{

    render(){
        return(
                <AUX>
                      <C3Chart data={data} color={colors} />
                </AUX>
            );
        }
    }
    
    export default MostViewPurchase;   
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import AUX from "../../../hoc/Aux_";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getSellerBackend } from "../../../redux/MainAction";
import Constant from "../../../Constant";

const SellerView = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const SellerList = useSelector((state) => state.Zeed.sellerList);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);

  useEffect(() => {
    getSellerList();
  }, []);

  const getSellerList = (pageNum) => {
    const DATA = {
      startRange: (pageNum - 1) * perPage,
      count: perPage,
      page: pageNum,
    };
    dispatch(getSellerBackend(DATA));
  };

  // const getSellerList = () => {
  //   var DATA = {
  //     startRange: "0",
  //     count: "1000",
  //   };
  //   dispatch(getSeller(DATA));
  // };

  const [coup, setCoup] = useState({
    shopName: "",
    userName: "",
    email: "",
    mobile: "",
    myfatoorahStatus: "",
    type: "",
    address: "",
    aboutBusiness: "",
    bankName: "",
    IBAN: "",
    isStoreVerified: "",
    myFatoorahSellerCode: "",
    mapUrl: "",
    lat: "",
    long: "",
    commissionPercentage: "",
    fixedCommissionAmount: "",
    bankAccountHolderName: "",
    bankAccount: "",
    firstName: "",
    lastName: "",
    gender: "",
    registrationType: "",
    walletBalance: "",
    instagram: "",
  });

  useEffect(() => {
    // console.log("params.id:", params.id);
    // console.log("SellerList:", SellerList);
    if (params.id && SellerList) {
      var spr = SellerList?.filter((itm) => itm.id === params.id);
      // console.log("Seller data:", spr);
      var SELLER = spr[0];
      setCoup({
        userName: SELLER?.userName,
        shopName: SELLER?.storeDetails?.shopName,
        email: SELLER?.storeDetails?.sellerEmail,
        mobile: SELLER?.mobileNumber,
        myfatoorahStatus: SELLER?.storeDetails?.myfatoorahStatus
          ? "YES"
          : "NO",
        type: SELLER?.storeDetails?.type,
        address: SELLER?.storeDetails?.address,
        aboutBusiness: SELLER?.storeDetails?.aboutBusiness,
        bankName: SELLER?.storeDetails?.bankName,
        bankAccountHolderName: SELLER?.storeDetails?.bankAccountHolderName,
        bankAccount: SELLER?.storeDetails?.bankAccount,
        IBAN: SELLER?.storeDetails?.IBAN,
        isStoreVerified:
          SELLER?.storeDetails?.isStoreVerified === 1 ? "YES" : "NO",
        myFatoorahSellerCode: SELLER?.storeDetails?.myFatoorahSellerCode,
        mapUrl: SELLER?.storeDetails?.mapUrl,
        lat: SELLER?.storeDetails?.lat,
        long: SELLER?.storeDetails?.long,
        instagram: SELLER?.storeDetails?.instagram,
        civilFront: SELLER?.storeDetails?.civilFront,
        civilBack: SELLER?.storeDetails?.civilBack,
        civilOfOwner: SELLER?.storeDetails?.civilOfOwner,
        commercialLicense: SELLER?.storeDetails?.commercialLicense,
        walletBalance: SELLER?.walletBalance,
        registrationType: SELLER?.registrationType,
        signatureAuthorization: SELLER?.storeDetails?.signatureAuthorization,
        articlesOfAssociation: SELLER?.storeDetails?.articlesOfAssociation,
        agreement: SELLER?.storeDetails?.agreement,
        gender: SELLER?.gender,
        commissionPercentage: SELLER?.storeDetails?.commissionPercentage,
        fixedCommissionAmount: SELLER?.storeDetails?.fixedCommissionAmount,
      });
    }
  }, [params.id, SellerList]);

  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <table className="table table-bordered table-custom">
                    <thead>
                      <tr style={{ backgroundColor: '#f0f0f0' }}>
                        <th colSpan="2">
                          <h5 style={{ margin: 0 }}>View Seller Details</h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="label">User Name</td>
                        <td className="value">{coup.userName}</td>
                      </tr>
                      <tr>
                        <td className="label">Registration Type</td>
                        <td className="value">{coup.registrationType}</td>
                      </tr>
                      {/* <tr>
                        <td className="label">Wallet Balance</td>
                        <td className="value">{coup.walletBalance}</td>
                      </tr> */}
                      <tr>
                        <td className="label">Business Name</td>
                        <td className="value">{coup.shopName}</td>
                      </tr>
                      <tr>
                        <td className="label">Business Type</td>
                        <td className="value">{coup.type}</td>
                      </tr>
                      <tr>
                        <td className="label">Address</td>
                        <td className="value">{coup.address ? Constant.formatAddress(coup.address) : ''}</td>
                      </tr>
                      <tr>
                        <td className="label">Bank Name</td>
                        <td className="value">{coup.bankName}</td>
                      </tr>
                      <tr>
                        <td className="label">Bank Account Holder Name</td>
                        <td className="value">{coup.bankAccountHolderName}</td>
                      </tr>
                      <tr>
                        <td className="label">Account Number</td>
                        <td className="value">{coup.bankAccount}</td>
                      </tr>
                      <tr>
                        <td className="label">IBAN</td>
                        <td className="value">{coup.IBAN}</td>
                      </tr>
                      <tr>
                        <td className="label">My Fatoorah Seller Code</td>
                        <td className="value">{coup.myFatoorahSellerCode}</td>
                      </tr>
                      <tr>
                        <td className="label">Mobile</td>
                        <td className="value">{coup.mobile}</td>
                      </tr>
                      <tr>
                        <td className="label">About Business</td>
                        <td className="value">{coup.aboutBusiness}</td>
                      </tr>
                      <tr>
                        <td className="label">Email</td>
                        <td className="value">{coup.email}</td>
                      </tr>
                      <tr>
                        <td className="label">My Fatoorah Status</td>
                        <td className="value">{coup.myfatoorahStatus}</td>
                      </tr>
                      <tr>
                        <td className="label">Store Verified</td>
                        <td className="value">{coup.isStoreVerified}</td>
                      </tr>
                      <tr>
                        <td className="label">Latitude</td>
                        <td className="value">{coup.lat}</td>
                      </tr>
                      <tr>
                        <td className="label">Longitude</td>
                        <td className="value">{coup.long}</td>
                      </tr>
                      <tr>
                        <td className="label">Map Url</td>
                        <td className="value">{coup.mapUrl}</td>
                      </tr>
                      <tr>
                        <td className="label">Fixed Commission Amount</td>
                        <td className="value">{coup.fixedCommissionAmount}</td>
                      </tr>
                      <tr>
                        <td className="label">Commission Percentage</td>
                        <td className="value">{coup.commissionPercentage}</td>
                      </tr>
                      <tr>
                        <td className="label">Instagram</td>
                        <td className="value">
                          {coup.instagram && (
                            <>
                              {coup.instagram.endsWith(".pdf") ? (
                                <a href={coup.instagram} target="_blank" rel="noopener noreferrer">
                                  {coup.instagram}
                                </a>
                              ) : (
                                <img
                                  src={coup.instagram}
                                  alt="Instagram"
                                  style={{ maxWidth: "100%", maxHeight: "150px" }}
                                />
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="label">Civil Id front</td>
                        <td className="value">
                          {coup.civilFront && (
                            <>
                              {coup.civilFront.endsWith(".pdf") ? (
                                <a href={coup.civilFront} target="_blank" rel="noopener noreferrer">
                                  {coup.civilFront}
                                </a>
                              ) : (
                                <img
                                  src={coup.civilFront}
                                  alt="Civil Front"
                                  style={{ maxWidth: "100%", maxHeight: "150px" }}
                                />
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="label">Civil Id Back</td>
                        <td className="value">
                          {coup.civilBack && (
                            <>
                              {coup.civilBack.endsWith(".pdf") ? (
                                <a href={coup.civilBack} target="_blank" rel="noopener noreferrer">
                                  {coup.civilBack}
                                </a>
                              ) : (
                                <img
                                  src={coup.civilBack}
                                  alt="Civil Back"
                                  style={{ maxWidth: "100%", maxHeight: "150px" }}
                                />
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="label">Civil Id Owner</td>
                        <td className="value">
                          {coup.civilOfOwner && (
                            <>
                              {coup.civilOfOwner.endsWith(".pdf") ? (
                                <a href={coup.civilOfOwner} target="_blank" rel="noopener noreferrer">
                                  {coup.civilOfOwner}
                                </a>
                              ) : (
                                <img
                                  src={coup.civilOfOwner}
                                  alt="Civil Of Owner"
                                  style={{ maxWidth: "100%", maxHeight: "150px" }}
                                />
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="label">Commercial License</td>
                        <td className="value">
                          {coup.commercialLicense && (
                            <>
                              {coup.commercialLicense.endsWith(".pdf") ? (
                                <a href={coup.commercialLicense} target="_blank" rel="noopener noreferrer">
                                  {coup.commercialLicense}
                                </a>
                              ) : (
                                <img
                                  src={coup.commercialLicense}
                                  alt="Commercial License"
                                  style={{ maxWidth: "100%", maxHeight: "150px" }}
                                />
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="label">Signature Authorization</td>
                        <td className="value">
                          {coup.signatureAuthorization && (
                            <>
                              {coup.signatureAuthorization.endsWith(".pdf") ? (
                                <a href={coup.signatureAuthorization} target="_blank" rel="noopener noreferrer">
                                  {coup.signatureAuthorization}
                                </a>
                              ) : (
                                <img
                                  src={coup.signatureAuthorization}
                                  alt="Signature Authorization"
                                  style={{ maxWidth: "100%", maxHeight: "150px" }}
                                />
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="label">Articles-of-Association</td>
                        <td className="value">
                          {coup.articlesOfAssociation && (
                            <>
                              {coup.articlesOfAssociation.endsWith(".pdf") ? (
                                <a href={coup.articlesOfAssociation} target="_blank" rel="noopener noreferrer">
                                  {coup.articlesOfAssociation}
                                </a>
                              ) : (
                                <img
                                  src={coup.articlesOfAssociation}
                                  alt="Articles Of Association"
                                  style={{ maxWidth: "100%", maxHeight: "150px" }}
                                />
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="label">3 Parties’ Contract/Agreement</td>
                        <td className="value">
                          {coup.agreement && (
                            <>
                              {coup.agreement.endsWith(".pdf") ? (
                                <a href={coup.agreement} target="_blank" rel="noopener noreferrer">
                                  {coup.agreement}
                                </a>
                              ) : (
                                <img
                                  src={coup.agreement}
                                  alt="3 Parties’ Contract/Agreement"
                                  style={{ maxWidth: "100%", maxHeight: "150px" }}
                                />
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="form-group">
                    <div className="d-flex justify-content-end">
                      <Link to="/sellers" className="btn btn-secondary waves-effect m-l-5">
                        Back
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AUX>
  );
};

export default SellerView;

import React, { useEffect, useState } from 'react'
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import {getPackage} from '../../../redux/MainAction' 
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import axios from "axios";
import { GET_PACKAGE } from "../../../redux/type";
import Constant from "../../../Constant";
import { setLoading } from "../../../redux/MainAction";

var config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("loged_In_auth"),
    },
};
const Package = () => {
    const dispatch = useDispatch()
    const PackList = useSelector(state=>state.Zeed.package);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20);

    useEffect(()=>{
        dispatch(setLoading(true));
        fetchPageData(1);
        // getRolList()
    },[])

    const fetchPageData = (pageNum) => {
        const DATA = {
            startRange: (pageNum - 1) * perPage,
            count: perPage,
            page: pageNum,
        };
        axios
            .post(Constant.getAPI() + "/package/backend-getAll", DATA, config)
            .then((res) => {
                // window.setLoading(true)
                dispatch({
                    type: GET_PACKAGE,
                    payload: res.data.data,
                    totalRows: res.data.totalRows
                });
                setTotalRows(res.data.totalRows)
                dispatch(setLoading(false));
            })
            .catch((err) => {
                console.log(err)
                dispatch(setLoading(false))
            }
            );
        setCurrentPage(pageNum);
    };

    const handlePageChange = (pageNum) => {
        dispatch(setLoading(true));
        fetchPageData(pageNum);
      };
    
      const handlePerPageChange = (event) => {
        setPerPage(Number(event.target.value));
        fetchPageData(1);
      };

    // const getRolList = ()=>{
    //     var DATA={
    //       startRange:'0',
    //       count:"1000"
    //     }
    //     dispatch(getPackage(DATA))
    // }
    // console.log("PackList", PackList);
    const columns =[
            {
                label: "Name",
                field: "name", 
            },
            {
                label: "Currency",
                field: "currency", 
            },
            {
                label: "Type",
                field: "type", 
            },
            {
                label: "Amount",
                field: "amount", 
            },
            {
                label: "Action",
                field: "id", 
            },
    ];

    const rows = PackList?.map((Item)=>{return {
        name: Item.name,
        currency: Item.currency,
        type: Item.type,
        amount: Item.amount,
        id:<Link to={"/package/edit/" + Item.id} title="Edit" className="mdi mdi-grease-pencil"></Link>,
    }});

    const data = {
        columns,
        rows,
    };

    const totalPages = Math.ceil((totalRows ? totalRows : 0) / perPage);

  return (
    <AUX>
        <div className="page-content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card m-b-20">
                            <div className="card-body">
                               <div className='d-flex justify-content-end'>
                               <Link to='/package/add'>
                                <Button size="sm" color="secondary" onClick >Add Package</Button>
                               </Link>
                               </div>
                                <MDBDataTable
                                    bordered
                                    theadColor="#2632384"
                                    paging={false}
                                    hover
                                    entries={20}
                                    data={data} />
                                  <div className="pagination-controls">
                                      <button className="pagination-btn first-btn" onClick={() => handlePageChange(1)} disabled={currentPage === 1}>First</button>
                                      <button className="pagination-btn prev-btn" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                      <span>Page {currentPage} of {totalPages}</span>
                                      <button className="pagination-btn next-btn" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                                      <button className="pagination-btn last-btn" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>Last</button>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div> 
        </div>
    </AUX>
  )
}

export default Package
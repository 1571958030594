import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import { getCategory, deleteCategory } from '../../../redux/MainAction';
import { useDispatch, useSelector } from 'react-redux';
import { MDBDataTable } from 'mdbreact';
import { Button } from 'reactstrap';
import axios from "axios";
import { GET_CATEGORY } from "../../../redux/type";

import Constant from "../../../Constant";
import { setLoading } from "../../../redux/MainAction";
var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

const CategoryList = () => {
  const dispatch = useDispatch();
  const CateList = useSelector(state => state.Zeed.category);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    dispatch(setLoading(true));
    fetchPageData(1);
  }, [searchQuery, perPage]);

  const fetchPageData = (pageNum) => {
    const DATA = {
      startRange: (pageNum - 1) * perPage,
      count: perPage,
      page: pageNum,
      search: searchQuery,
    };

    axios
      .post(Constant.getAPI() + "/category/backend-getAll", DATA, config)
      .then((res) => {
        dispatch({
          type: GET_CATEGORY,
          payload: res.data.data,
          totalRows: res.data.count.fulfillmentValue,
        });
        setTotalRows(res.data.count);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });

    setCurrentPage(pageNum);
  };

  const handlePageChange = (pageNum) => {
    dispatch(setLoading(true));
    fetchPageData(pageNum);
  };

  const handlePerPageChange = (event) => {
    setPerPage(Number(event.target.value));
    fetchPageData(1);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    fetchPageData(1);
  };

  const handleDelete = (id) => {
    const data = { categoryId: id };
    dispatch(deleteCategory(data));
  };

  const columns = [
    {
      label: "Category Name",
      field: "name",
    },
    {
      label: "Category Name : Arabic",
      field: "name_ar",
    },
    {
      label: "Action",
      field: "id",
    },
  ];

  const rows = CateList.map((itm) => ({
    name: itm.name,
    name_ar: itm.name_ar,
    id: (
      <>
        <Link to={'/category/edit/' + itm.id} title="Edit" className="mdi mdi-grease-pencil"></Link>
        <i onClick={() => handleDelete(itm.id)} title="Delete" className="mdi mdi-delete D_BTN"></i>
      </>
    ),
  }));

  const data = {
    columns,
    rows,
  };

  const totalPages = Math.ceil((totalRows ? totalRows.fulfillmentValue : 0) / perPage);

  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <div className='d-flex justify-content-between'>
                    <div className="d-flex">
                    </div>
                    <Link to='/category/add'>
                      <Button size="sm" color="secondary">Add Category</Button>
                    </Link>
                  </div>
                  <div className="d-flex justify-content-end m-t-5 m-b-5">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearch}
                      className="form-control"
                      style={{
                        width: '200px',
                        height: 'calc(1.5em + .5rem + 2px)',
                        padding: '.25rem .5rem',
                        fontSize: '.875rem',
                        lineHeight: '1.5',
                        borderRadius: '.2rem'
                      }}
                    />

                  </div>
                  <MDBDataTable
                    bordered
                    theadColor="#2632384"
                    paging={false}
                    searching={false}
                    hover
                    entries={perPage}
                    data={data}
                  />
                  <div className="pagination-controls">
                    <button className="pagination-btn first-btn" onClick={() => handlePageChange(1)} disabled={currentPage === 1}>First</button>
                    <button className="pagination-btn prev-btn" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button className="pagination-btn next-btn" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                    <button className="pagination-btn last-btn" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>Last</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
          .pagination-controls {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 20px;
          }
          select{
            width: 27%;
          }
          .pagination-btn {
            background-color: #263238;
            color: white;
            border: none;
            padding: 10px 20px;
            margin: 0 5px;
            cursor: pointer;
            border-radius: 5px;
            font-size: 14px;
          }
          
          .pagination-btn:disabled {
            background-color: #cccccc;
            cursor: not-allowed;
          }
          
          .pagination-btn:not(:disabled):hover {
            background-color: #263233;
          }
        `}
      </style>
    </AUX>
  );
};

export default CategoryList
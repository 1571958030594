/* eslint-disable no-unreachable */
import React, { useEffect, useState } from "react";
import AUX from "../../../hoc/Aux_";
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Button } from "reactstrap";
import Axios from "axios";
import Constant from "../../../Constant";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Swal from "sweetalert2";

const ContactUs = () => {
    const [langID , setlangID ] = useState("en")
    const [editorState, setEditorState] = useState();
    const [contentState, setContentState] = useState(null);

    const [about , setAbout] = useState([])

    const handleLang = (e)=>{
        // console.log("ed", e.target.id);
        setlangID(e.target.id)
    }
    const handleContentStateChange = (contentState) => {
        setContentState(draftToHtml(contentState));
    };
    
    const handleEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };
    
    useEffect(() => {
        if(langID==='en'){
            if (about.value) {
                const blocksFromHTML = convertFromHTML(about.value);
                const state = ContentState.createFromBlockArray(
                  blocksFromHTML.contentBlocks,
                  blocksFromHTML.entityMap
                );
                setEditorState(EditorState.createWithContent(state));
                setContentState(about?.value); // Set contentState with the provided data value
              }
        }else{
            if (about.value_ar) {
                const blocksFromHTML = convertFromHTML(about.value_ar);
                const state = ContentState.createFromBlockArray(
                  blocksFromHTML.contentBlocks,
                  blocksFromHTML.entityMap
                );
                setEditorState(EditorState.createWithContent(state));
                setContentState(about?.value_ar); // Set contentState with the provided data value
              }
        }
      }, [langID, about.value]);
    useEffect(()=>{
        AboutUs()
    },[])
    const AboutUs = async () => {
        var data = {
          name: "Contact Us",
          languageCode: "",
        }
        await Axios.post(Constant.getAPI() + `/config/getConfig`, data, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('loged_In_auth')}`
          }
        }).then(res => {
            setAbout(res.data?.data);
        }).catch((err) => {
        //   this.setState({ isData: false })
          if (err?.response?.status === 401) {
            Constant.getAuthLogout();
          }
          else {
            Swal.fire({
              title: err?.response?.data?.data?.details?.[0]?.message || err?.response?.data?.message,
              icon: "error",
              text: "",
              type: "error",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
            });
          }
        });
    }

    const saveValue = () => {
      if(langID==="en"){
        var data = {
            name: "Contact Us",
            value: contentState
        }
        Axios
            .post(Constant.getAPI() + `/config/editConfig/${about?.id}`, data, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("loged_In_auth")
                },
            })
            .then((res) => {
                // this.setState({ isData: false })
                if (res?.status) {
                Swal.fire({
                    title: "AboutUs Updated Successfully!",
                    icon: "success",
                    text: "",
                    type: "success",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ok",
                });
                }
            }).catch((err) => {
                // this.setState({ isData: false })
                if (err?.response?.status === 401) {
                Constant.getAuthLogout();
                }
                else {
                Swal.fire({
                    title: err?.response?.data?.data?.details?.[0]?.message || err?.response?.data?.message,
                    icon: "error",
                    text: "",
                    type: "error",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ok",
                });
                }
            });
        // console.log('data',data);
      }else{
        var ar = {
            "value": contentState,
            "name": "Contact Us",
            "languageCode": langID
            }
        // console.log('ar',ar);
        Axios
        .post(Constant.getAPI() + `/config/editConfig/${about?.id}`, ar, {
            headers: {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("loged_In_auth")
            },
        })
        .then((res) => {
            // this.setState({ isData: false })
            if (res.status) {
            Swal.fire({
                title: "AboutUs Updated Successfully!",
                icon: "success",
                text: "",
                type: "success",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
            });
            }
        }).catch((err) => {
            // this.setState({ isData: false })
            if (err?.response?.status === 401) {
            Constant.getAuthLogout();
            }
            else {
            Swal.fire({
                title: err?.response?.data?.data?.details?.[0]?.message || err?.response?.data?.message,
                icon: "error",
                text: "",
                type: "error",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
            });
            }
        });
        
      }

    };
  return (
    <AUX>
    <div className="page-content-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
               

                <ul className="nav nav-tabs" role="tablist" onClick={handleLang}>
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"                
                      href="#en"
                      role="tab"
                    >
                      <span  id="en" className="d-none d-md-block">Contact Us (En)</span>
                      <span className="d-block d-md-none">
                        <i className="mdi mdi-home-variant h5"></i>
                      </span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#ar"
                      role="tab"
                    >
                      <span  id="ar" className="d-none d-md-block">Contact Us (Ar)</span>
                      <span className="d-block d-md-none">
                        <i className="mdi mdi-account h5"></i>
                      </span>
                    </a>
                  </li>
                </ul> 
                <div className="tab-content mt-3" >
                  <div
                    className="tab-pane active p-3"
                    role="tabpanel"
                    
                  >
                      <div className="col-13">
                          <div className=" m-b-20">
                          
                              <Editor
                                  id='en'
                                  editorState={editorState}
                                  onEditorStateChange={handleEditorStateChange}
                                  onContentStateChange={handleContentStateChange}
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName" 
                                  editorStyle={{padding:"10px"}}    
                              />
                          </div>
                          <div className="d-flex justify-content-end">
                              <Button onClick={saveValue}>Save</Button>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </AUX>
  )
}

export default ContactUs
import React, {Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {getRole} from '../../redux/MainAction'
import $ from 'jquery';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Axios from 'axios';
import Constant from '../../Constant';

var config = {
   headers: {
       "Content-Type": "application/json",
       Authorization: localStorage.getItem("loged_In_auth"),
   },
};

class sidebar extends Component {
state = {
rolesList: [],
Admin:'',
allOrderCount:0,
atStore:0,
beingWrapped:0,
beingWrappedAssignDriver:0,
beingWrappedUnAssignDriver:0,
onTheWay:0,
delivered:0,
cancelled:0,
pending: 0,
placed: 0,
accepted: 0,
acceptedNotAtStore: 0,
vendorAll:0,
vendorPending:0,
vendorAccepted:0,
vendorReadyForPickUp:0,
vendorReadyForPickUpAssignDriver:0,
vendorReadyForPickUpUnAssignDriver:0,
vendorEnroute:0,
vendorAtStore:0,
vendorCancelled:0,
}
componentWillMount() {
this.props.getRole();
}
componentWillReceiveProps(nextProps) {
var RlsID = localStorage.getItem("RoleId") === "null" ? "all" :localStorage.getItem("RoleId")
   //
   const DATA = {};
   Axios
      .post(Constant.getAPI() + "/dashboard/sideBarOrderBadges", DATA, config)
      .then((res) => {
         this.state.allOrderCount = res.data.all;
         this.state.atStore = res.data.atStore;
         this.state.beingWrapped = res.data.beingWrapped;
         this.state.beingWrappedAssignDriver = res.data.beingWrappedAndInDeliveries;
         this.state.beingWrappedUnAssignDriver = res.data.beingWrappedAndNotInDeliveries;
         this.state.cancelled = res.data.cancelled;
         this.state.delivered = res.data.delivered;
         this.state.onTheWay = res.data.onTheWay;
         this.state.pending = res.data.pending;
         this.state.placed = res.data.placed;
         this.state.accepted = res.data.accepted;
         this.state.acceptedNotAtStore = res.data.acceptedNotAtStore;
      })
      .catch((err) => {
         console.log(err)
      }
      );
   //
   const vendorData = {};
   Axios
     .post(Constant.getAPI() + "/dashboard/sideBarVendorOrderBadges", vendorData, config)
     .then((res) => {
      this.state.vendorAll = res.data.all;
      this.state.vendorAtStore = res.data.atStore;
      this.state.vendorCancelled = res.data.cancelled;
      this.state.vendorPending = res.data.pending;
      this.state.vendorAccepted = res.data.accepted;
      this.state.vendorReadyForPickUp = res.data.readyForPickup;
      this.state.vendorReadyForPickUpAssignDriver = res.data.assignedDriverReadyForPickup;
      this.state.vendorReadyForPickUpUnAssignDriver = res.data.unassignedDriverReadyForPickup;
      this.state.vendorEnroute = res.data.enRouteWarehouse;
     })
     .catch((err) => {
       console.log(err)
     }
     );
// console.log("RlsID", RlsID);
var data = nextProps.Zeed.roles
if(RlsID !== "all"){
if(data?.length>0){
var fil = data?.filter(itm=>itm.id===localStorage.getItem("RoleId"))
this.setState({rolesList:fil[0]?.modules})
// console.log("nextProps", fil[0]?.modules);
}
}else{
this.setState({Admin:true})
}
// console.log("data",data);/
}
constructor(props) {
super(props);
this.state = {
Tab: 'index', SubTab: '', MoreTab: '',  dashboard_menu:false, email_menu : false, ui_menu : false,
form_menu: false, chart_menu: false, table_menu: false, icon_menu: false, map_menu: false,
auth_menu: false, extra_menu: false, eco_menu: false, emt_menu: false , user_menu:false , roles_menu:false ,transferRequest:false , banks:false,category:false , orders:false, master:false,
package:false,reportedPosts:false , location_menu:false, about:false ,privacy:false, tc:false,contactUs:false,product:false,attribute:false,push:false,masterOrder:false,admin_menu:false,vendorOrder:false
};
}
setActiveTab = (tab,subtab,moretab,toggleTab, e)=> {
this.setState({Tab: tab,SubTab: subtab,MoreTab: moretab});
// console.log("TT", tab,subtab,moretab,toggleTab);
}
componentDidMount() 
{
var now_route = "";
var pageUrl = window.location.pathname.split(/[?#]/)[0];
now_route = pageUrl.substr(1).replace(/_/g," ");
$('#now_routing').empty();
if(now_route == "") { now_route="Dashboard" } else {  }
$('#now_routing').append(now_route);
$('.toggle-search').on('click', function () {
var targetId = $(this).data('target');
var $searchBar;
if (targetId) {
$searchBar = $(targetId);
$searchBar.toggleClass('open');
}
});
$('.button-menu-mobile').on('click', function (event) {
event.preventDefault();
$("body").toggleClass("enlarged");
});
$('li.has_sub li').on('click', function (event) {
$("body").toggleClass("enlarged");
});
}
componentDidUpdate() 
{   
var now_route = "";
var pageUrl = window.location.pathname.split(/[?#]/)[0];
now_route = pageUrl.substr(1).replace("_"," ");
$('#now_routing').empty();
if(now_route == "") { now_route="Dashboard" } else {  }
$('#now_routing').append(now_route);
}
render() {
// const { roleList } = this.props;
// console.log("Admin ", this?.state?.Admin);
// console.log("roleList ",this?.state?.rolesList);
return (
<div className="left side-menu">
   <div className="topbar-left">
      <div className="">
         <Link to="/" className="logo">
         <img src="assets/images/newlogo.png" height="45" alt="logo" /></Link>
      </div>
   </div>
   <div className="sidebar-inner slimscrollleft" >
      <PerfectScrollbar>
         <div id="sidebar-menu">
            <ul>
               <li className="menu-title">Main</li>
               {this.state.Admin?<li className={this.state.dashboard_menu ? 'has_sub active-menu nav-active' : 'has_sub'} id='02cde0e3-1eae-4379-91ed-2cc0316670c5'>
               <Link className={this.state.SubTab === 'dashboard' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'index','dashboard','')}   to="/"><i className="mdi mdi-view-dashboard"></i>Dashboard</Link>
               {/* <Link to="#" onClick={()=>
               this.setState({ dashboard_menu: !this.state.dashboard_menu, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'index' ? 'waves-effect active-menu':'waves-effect'}  ><i className="mdi mdi-view-dashboard"></i> <span> Dashboard <span className='pull-right'><i className="mdi mdi-chevron-right"></i></span> </span> </Link> */}
               <ul className="list-unstyled" style={{display: this.state.dashboard_menu ? 'block' : 'none' }}>
               {/* 
               <li><Link className={this.state.SubTab === 'dashboard1' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'index','dashboard1','')}  to="/">Dashboard One</Link></li>
               */}
               {/* <li><Link className={this.state.SubTab === 'dashboard' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'index','dashboard','')}   to="/">Dashboard eee</Link></li> */}
               {/* <li><Link className={this.state.SubTab === 'dashboard' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'index','dashboard','')}   to="/dashboard">Analysis</Link></li> */}
            </ul>
            </li>:
            this?.state?.rolesList?.some(role => role?.id === "02cde0e3-1eae-4379-91ed-2cc0316670c5") ?
            <li className={this.state.dashboard_menu ? 'has_sub active-menu nav-active' : 'has_sub'} id='02cde0e3-1eae-4379-91ed-2cc0316670c5'>
            <Link to="#" onClick={()=>
            this.setState({ dashboard_menu: !this.state.dashboard_menu, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'index' ? 'waves-effect active-menu':'waves-effect'}  ><i className="mdi mdi-view-dashboard"></i> <span> Dashboard <span className="badge badge-pill badge-primary pull-right">20+</span></span> </Link>
            <ul className="list-unstyled" style={{display: this.state.dashboard_menu ? 'block' : 'none' }}>
            <li><Link className={this.state.SubTab === 'dashboard1' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'index','dashboard1','')}  to="/">Dashboard One</Link></li>
            <li><Link className={this.state.SubTab === 'dashboard2' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'index','dashboard2','')}   to="/dashboard2">Dashboard Two</Link></li>
            <li><Link className={this.state.SubTab === 'dashboard' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'index','dashboard','')}   to="/dashboard">Analysis</Link></li>
            </ul>
            </li>:''
            }
            {this.state.Admin?
            <li className={this.state.user_menu ? 'has_sub active-menu nav-active' : 'has_sub'} id='31af2094-4bae-4c4b-8788-559a3043b266'>
            <Link to="#"  onClick={()=>
            this.setState({ user_menu: !this.state.user_menu,dashboard_menu:false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'user_menu' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-account-multiple"></i> <span> Customers <span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span> </Link>
            <ul className="list-unstyled" style={{display: this.state.user_menu ? 'block' : 'none' }}>
            {/* <li><Link className={this.state.SubTab === 'roles' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'roles_menu','')}  to="/roles">Admin</Link></li> */}
            <li><Link className={this?.state?.SubTab === 'users' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'users','')}  to="/users">Users</Link></li>
            <li><Link className={this?.state?.SubTab === 'sellers' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'sellers','')}  to="/sellers">Sellers</Link></li>
            {/* <li><Link className={this?.state?.SubTab === 'warehouse' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'warehouse','')}  to="/warehouse">Warehouse Admin</Link></li> */}
            </ul>
            </li> :
            this.state?.rolesList?.some(role => role?.id === "31af2094-4bae-4c4b-8788-559a3043b266") ?
            <li className={this.state.user_menu ? 'has_sub active-menu nav-active' : 'has_sub'} id='31af2094-4bae-4c4b-8788-559a3043b266'>
            <Link to="#"  onClick={()=>
            this.setState({ user_menu: !this.state.user_menu,dashboard_menu:false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'user_menu' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-account-multiple"></i> <span> Customers <span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span> </Link>
            <ul className="list-unstyled" style={{display: this.state.user_menu ? 'block' : 'none' }}>
            <li><Link className={this.state.SubTab === 'users' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'users','')}  to="/users">Users</Link></li>
            <li><Link className={this.state.SubTab === 'sellers' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'sellers','')}  to="/sellers">Sellers</Link></li>
            {/* <li><Link className={this?.state?.SubTab === 'warehouse' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'warehouse','')}  to="/warehouse">Warehouse Admin</Link></li> */}
            </ul>
            </li>
            :''
            }
            {this.state.Admin?
            <li className={this.state.admin_menu ? 'has_sub active-menu nav-active' : 'has_sub'} id='31af2094-4bae-4c4b-8788-559a3043b266'>
            <Link to="#"  onClick={()=>
            this.setState({ admin_menu: !this.state.admin_menu,dashboard_menu:false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'admin_menu' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-account-multiple"></i> <span> Admin <span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span> </Link>
            <ul className="list-unstyled" style={{display: this.state.admin_menu ? 'block' : 'none' }}>
            <li><Link className={this.state.SubTab === 'roles' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'roles_menu','')}  to="/roles">Admin</Link></li>
            {/* <li><Link className={this?.state?.SubTab === 'sellers' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'sellers','')}  to="/sellers">Sellers</Link></li> */}
            <li><Link className={this?.state?.SubTab === 'warehouse' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'warehouse','')}  to="/warehouse">Warehouse Admin</Link></li>
            </ul>
            </li> :
            this.state?.rolesList?.some(role => role?.id === "31af2094-4bae-4c4b-8788-559a3043b266") ?
            <li className={this.state.admin_menu ? 'has_sub active-menu nav-active' : 'has_sub'} id='31af2094-4bae-4c4b-8788-559a3043b266'>
            <Link to="#"  onClick={()=>
            this.setState({ admin_menu: !this.state.admin_menu,dashboard_menu:false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'admin_menu' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-account-multiple"></i> <span> Admin <span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span> </Link>
            <ul className="list-unstyled" style={{display: this.state.admin_menu ? 'block' : 'none' }}>
            {/* <li><Link className={this.state.SubTab === 'users' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'users','')}  to="/users">Users</Link></li> */}
            {/* <li><Link className={this.state.SubTab === 'sellers' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'sellers','')}  to="/sellers">Sellers</Link></li> */}
            <li><Link className={this?.state?.SubTab === 'warehouse' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'warehouse','')}  to="/warehouse">Warehouse Admin</Link></li>
            </ul>
            </li>
            :''
            }
            {/* { this.state.Admin?
            <li id='f3457009-e0ce-4986-a099-9d2c6a04a43e'>
               <Link to="/orders" className={this.state.Tab === 'orders' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'orders','','')}><i className="typcn typcn-th-large-outline"></i><span> Orders </span></Link>
            </li>
            :this?.state?.rolesList?.some(role => role?.id === 'f3457009-e0ce-4986-a099-9d2c6a04a43e')?
            <li id='f3457009-e0ce-4986-a099-9d2c6a04a43e'>
               <Link to="/orders" className={this.state.Tab === 'orders' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'orders','','')}><i className="typcn typcn-th-large-outline"></i><span> Orders </span></Link>
            </li>
            :''
            } */}

            {this.state.Admin?
            <li className={this.state.masterOrder ? 'has_sub active-menu nav-active' : 'has_sub'} id='a87ec3a0-83a8-4441-9813-e53faaaa951e'>
            <Link to="#"  onClick={()=>
            this.setState({ masterOrder: !this.state.masterOrder,dashboard_menu:false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'master' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-cube-outline"></i> <span>Master Orders<span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span> </Link>
            <ul className="list-unstyled" style={{display: this.state.masterOrder ? 'block' : 'none' }}>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'Fixed' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'Fixed','')}  to="/orders">All</Link><span className="badge badge-success">{this.state.allOrderCount}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'pending' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'pending','')}  to="/pending">Pending</Link><span className="badge badge-success">{this.state.pending}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'pending' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'pending','')}  to="/pending">Placed</Link><span className="badge badge-success">{this.state.placed}</span></li>
            {/* <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'accepted' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'accepted','')}  to="/accepted">Accepted</Link><span className="badge badge-success">{this.state.accepted}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'being wrap' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'being wrap','')}  to="/being-wrapped-up">Being wrapped</Link><span className="badge badge-success">{this.state.beingWrapped}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'at store' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'at store','')}  to="/master-at-store">At store</Link><span className="badge badge-success">{this.state.atStore}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'on the way' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'on the way','')}  to="/on-the-way">On the way</Link><span className="badge badge-success">{this.state.onTheWay}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'delivered' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'delivered','')}  to="/delivered">Delivered</Link><span className="badge badge-success">{this.state.delivered}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'cancelled' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'cancelled','')}  to="/cancelled">Cancelled</Link><span className="badge badge-success">{this.state.cancelled}</span></li> */}
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'pending' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'pending','')}  to="/accepted">Pending (Accepted)</Link><span className="badge badge-success">{this.state.accepted}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'accepted' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'accepted','')}  to="/master-accepted-not-at-store">Accepted (Not At Store)</Link><span className="badge badge-success">{this.state.acceptedNotAtStore}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'accepted' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'accepted','')}  to="/master-at-store">Accepted (At Store)</Link><span className="badge badge-success">{this.state.atStore}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'being wrapped' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'being wrapped','')}  to="/being-wrapped-up">Being Wrapped</Link><span className="badge badge-success">{this.state.beingWrapped}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'ready for delivery' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ready for delivery','')}  to="/being-wrapped-up-unassign">Ready for Delivery (Unassigned)</Link><span className="badge badge-success">{this.state.beingWrappedUnAssignDriver}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'ready for delivery' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ready for delivery','')}  to="/being-wrapped-up-assign">Ready for Delivery (Assigned)</Link><span className="badge badge-success">{this.state.beingWrappedAssignDriver}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'out for delivery' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'out for delivery','')} to="/on-the-way">Out for Delivery</Link><span className="badge badge-success">{this.state.onTheWay}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'delivered' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'delivered','')}  to="/delivered">Delivered</Link><span className="badge badge-success">{this.state.delivered}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'cancelled' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'cancelled','')}  to="/cancelled">Cancelled</Link><span className="badge badge-success">{this.state.cancelled}</span></li>
            </ul>
            </li>:this?.state?.rolesList?.some(role=>role?.id==='a87ec3a0-83a8-4441-9813-e53faaaa951e')?
            <li className={this.state.masterOrder ? 'has_sub active-menu nav-active' : 'has_sub'} id='a87ec3a0-83a8-4441-9813-e53faaaa951e'>
            <Link to="#"  onClick={()=>
            this.setState({ masterOrder: !this.state.masterOrder,dashboard_menu:false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'master' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-cube-outline"></i> <span>Products<span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span> </Link>
            <ul className="list-unstyled" style={{display: this.state.masterOrder ? 'block' : 'none' }}>
            <li><Link className={this.state.SubTab === 'Fixed' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'Fixed','')}  to="/orders">All</Link></li>
            <li><Link className={this.state.SubTab === 'pending' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'pending','')}  to="/pending">Pending</Link></li>
            {/* <li><Link className={this.state.SubTab === 'accepted' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'accepted','')}  to="/accepted">Accepted</Link></li>
            <li><Link className={this.state.SubTab === 'being wrap' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'being wrap','')}  to="/being-wrapped-up">Being wrapped</Link></li>
            <li><Link className={this.state.SubTab === 'at store' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'at store','')}  to="/master-at-store">At store</Link></li>
            <li><Link className={this.state.SubTab === 'on the way' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'on the way','')}  to="/on-the-way">On the way</Link></li>
            <li><Link className={this.state.SubTab === 'delivered' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'delivered','')}  to="/delivered">Delivered</Link></li>
            <li><Link className={this.state.SubTab === 'cancelled' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'cancelled','')}  to="/cancelled">Cancelled</Link></li> */}
            <li><Link className={this.state.SubTab === 'pending' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'pending','')}  to="#">Pending (Accepted)</Link></li>
            <li><Link className={this.state.SubTab === 'accepted' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'accepted','')}  to="#">Accepted (Not At Store)</Link></li>
            <li><Link className={this.state.SubTab === 'accepted' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'accepted','')}  to="#">Accepted (At Store)</Link></li>
            <li><Link className={this.state.SubTab === 'being wrapped' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'being wrapped','')}  to="#">Being Wrapped</Link></li>
            <li><Link className={this.state.SubTab === 'ready for delivery' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ready for delivery','')}  to="#">Ready for Delivery (Unassigned)</Link></li>
            <li><Link className={this.state.SubTab === 'ready for delivery' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ready for delivery','')}  to="#">Ready for Delivery (Assigned)</Link></li>
            <li><Link className={this.state.SubTab === 'out for delivery' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'out for delivery','')}  to="#">Out for Delivery</Link></li>
            <li><Link className={this.state.SubTab === 'delivered' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'delivered','')}  to="/delivered">Delivered</Link></li>
            <li><Link className={this.state.SubTab === 'cancelled' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'cancelled','')}  to="/cancelled">Cancelled</Link></li>
            </ul>
            </li>
            :''
            }

            {this.state.Admin?
            <li className={this.state.vendorOrder ? 'has_sub active-menu nav-active' : 'has_sub'} id='31af2094-4bae-4c4b-8788-559a3043b266'>
            <Link to="#"  onClick={()=>
            this.setState({ vendorOrder: !this.state.vendorOrder,dashboard_menu:false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'master' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-account-multiple"></i> <span> Vendor Orders <span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span> </Link>
             <ul className="list-unstyled" style={{display: this.state.vendorOrder ? 'block' : 'none' }}>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'vendor order all' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'vendor order all','')}  to="/all-order">All</Link><span className="badge badge-success">{this.state.vendorAll}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'pending vendor order' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'pending vendor order','')}  to="/seller-pending-orders">Pending</Link><span className="badge badge-success">{this.state.vendorPending}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'accepted vendor order' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'accepted vendor order','')}  to="/seller-accepted-orders">Accepted</Link><span className="badge badge-success">{this.state.vendorAccepted}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'ready for pickup vendor order' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ready for pickup vendor order','')}  to="/ready-for-pickup">Ready for Pickup</Link><span className="badge badge-success">{this.state.vendorReadyForPickUp}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'ready for pickup vendor order' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ready for pickup vendor order','')}  to="/ready-for-pickup-unassign">Ready for Pickup (Unassigned)</Link><span className="badge badge-success">{this.state.vendorReadyForPickUpUnAssignDriver}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'ready for pickup vendor order' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ready for pickup vendor order','')}  to="/ready-for-pickup-assign">Ready for Pickup (Assign)</Link><span className="badge badge-success">{this.state.vendorReadyForPickUpAssignDriver}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'enroute to warehouse vendor order' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'enroute to warehouse vendor order','')}  to="/enroute-warehouse">EnRoute to Warehouse</Link><span className="badge badge-success">{this.state.vendorEnroute}</span></li>
            <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'reached warehouse vendor order' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'reached warehouse vendor order','')}  to="/at-store">Reached at Warehouse</Link><span className="badge badge-success">{this.state.vendorAtStore}</span></li>
            {/* <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}><Link className={this.state.SubTab === 'cancelled vendor order' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'cancelled vendor order','')}  to="/cancelled-vendor-order">Cancelled</Link><span className="badge badge-success">{this.state.vendorCancelled}</span></li> */}

            </ul>
            </li> :
            this.state?.rolesList?.some(role => role?.id === "31af2094-4bae-4c4b-8788-559a3043b266") ?
            <li className={this.state.vendorOrder ? 'has_sub active-menu nav-active' : 'has_sub'} id='31af2094-4bae-4c4b-8788-559a3043b266'>
            <Link to="#"  onClick={()=>
            this.setState( {vendorOrder : !this.state.vendorOrder,dashboard_menu:false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'master' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-account-multiple"></i> <span> Vendor Orders <span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span> </Link>
            <ul className="list-unstyled" style={{display: this.state.vendorOrder ? 'block' : 'none' }}>
            <li><Link className={this.state.SubTab === 'vendor order all' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'vendor order all','')}  to="/all-order">All</Link></li>
            <li><Link className={this.state.SubTab === 'pending vendor order' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'pending vendor order','')}  to="/seller-pending-orders">Pending</Link></li>
            <li><Link className={this.state.SubTab === 'accepted vendor order' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'accepted vendor order','')}  to="/accepted-vendor-order">Accepted</Link></li>
            <li><Link className={this.state.SubTab === 'ready pickup vendor order' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ready pickup vendor order','')}  to="/ready-pickup-vendor-order">Ready for Pickup (Unassigned)</Link></li>
            <li><Link className={this.state.SubTab === 'ready pickup vendor order' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ready pickup vendor order','')}  to="/ready-pickup-vendor-order">Ready for Pickup (Assigned)</Link></li>
            <li><Link className={this.state.SubTab === 'enroute warehouse vendor order' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'enroute warehouse vendor order','')}  to="/enroute-warehouse-vendor-order">EnRoute to Warehouse</Link></li>
            <li><Link className={this.state.SubTab === 'reached warehouse vendor order' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'reached warehouse vendor order','')}  to="/at-store">Reached at Warehouse</Link></li>
            <li><Link className={this.state.SubTab === 'cancelled vendor order' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'cancelled vendor order','')}  to="/cancelled-vendor-order">Cancelled</Link></li>
            </ul>
            </li>
            :''
            }           

            {this.state.Admin?
            <li className={this.state.product ? 'has_sub active-menu nav-active' : 'has_sub'} id='a87ec3a0-83a8-4441-9813-e53faaaa951e'>
            <Link to="#"  onClick={()=>
            this.setState({ product: !this.state.product,dashboard_menu:false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'master' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-cube-outline"></i> <span>Products<span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span> </Link>
            <ul className="list-unstyled" style={{display: this.state.product ? 'block' : 'none' }}>
            <li><Link className={this.state.SubTab === 'Fixed' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'Fixed','')}  to="/product-Fixed">Fixed</Link></li>
            {/* <li><Link className={this.state.SubTab === 'Auction' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'Auction','')}  to="/product-Auction">Auction </Link></li> */}
            {/* <li><Link className={this.state.SubTab === 'Completed Auction' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'Completed Auction','')}  to="/product-AuctionCompleted">Completed Auction</Link></li> */}
            <li><Link className={this.state.SubTab === 'Product upload' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'Product upload','')}  to="/product-upload">Product Upload</Link></li>
            {/* 
            <li><Link className={this.state.SubTab === 'productAdd' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'Auction','')}  to="/product-add">Product Add </Link></li>
            */}
            </ul>
            </li>:this?.state?.rolesList?.some(role=>role?.id==='a87ec3a0-83a8-4441-9813-e53faaaa951e')?
            <li className={this.state.product ? 'has_sub active-menu nav-active' : 'has_sub'} id='a87ec3a0-83a8-4441-9813-e53faaaa951e'>
            <Link to="#"  onClick={()=>
            this.setState({ product: !this.state.product,dashboard_menu:false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'master' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-cube-outline"></i> <span>Products<span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span> </Link>
            <ul className="list-unstyled" style={{display: this.state.product ? 'block' : 'none' }}>
            <li><Link className={this.state.SubTab === 'Fixed' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'Fixed','')}  to="/product-Fixed">Fixed</Link></li>
            {/* <li><Link className={this.state.SubTab === 'Auction' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'Auction','')}  to="/product-Auction">Auction </Link></li> */}
            {/* <li><Link className={this.state.SubTab === 'Completed Auction' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'Completed Auction','')}  to="/product-AuctionCompleted">Completed Auction</Link></li> */}
            <li><Link className={this.state.SubTab === 'Product Upload' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'Product Upload','')}  to="/product-upload">Upload Uxcel</Link></li>
            {/* 
            <li><Link className={this.state.SubTab === 'productAdd' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'Auction','')}  to="/product-add">Product Add </Link></li>
            */}
            </ul>
            </li>
            :''
            }
            {this?.state.Admin?
            <li id='ac822b95-0d77-4cd1-a721-e773a26df538'>
               <Link to="/category" className={this.state.Tab === 'category' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'category','','')}><i className="typcn typcn-th-large-outline"></i><span> Category </span></Link>
            </li>
            : this?.state?.rolesList?.some(role=>role?.id==='ac822b95-0d77-4cd1-a721-e773a26df538')?
            <li id='ac822b95-0d77-4cd1-a721-e773a26df538'>
               <Link to="/category" className={this.state.Tab === 'category' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'category','','')}><i className="typcn typcn-th-large-outline"></i><span> Category </span></Link>
            </li>
            :''
            }
            {this?.state.Admin?
            <li id='ac822b95-0d77-4cd1-a721-e773a26df538'>
               <Link to="/sub-category" className={this.state.Tab === 'sub-category' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'sub-category','','')}><i className="typcn typcn-th-large-outline"></i><span> Sub Category </span></Link>
            </li>
            : this?.state?.rolesList?.some(role=>role?.id==='ac822b95-0d77-4cd1-a721-e773a26df538')?
            <li id='ac822b95-0d77-4cd1-a721-e773a26df538'>
               <Link to="/sub-category" className={this.state.Tab === 'sub-category' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'sub-category','','')}><i className="typcn typcn-th-large-outline"></i><span> Sub Category </span></Link>
            </li>
            :''
            }
            {/* { this.state.Admin? 
            <li id='7d426b94-222c-473b-8441-5614f0389d8a'>
               <Link to="/roles" className={this.state.Tab === 'roles' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'roles_menu','','')}><i className="mdi mdi-briefcase"></i><span> Roles </span></Link>
            </li>
            :
            this?.state?.rolesList?.some(role => role?.id === "7d426b94-222c-473b-8441-5614f0389d8a")?
            <li id='7d426b94-222c-473b-8441-5614f0389d8a'>
               <Link to="/roles" className={this.state.Tab === 'roles' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'roles_menu','','')}><i className="mdi mdi-briefcase"></i><span> Roles </span></Link>
            </li>
            :''
            } */}
            {this.state.Admin?
            <li id='467d3dcc-d910-4352-b873-55457551f29c'>
               <Link to="/transfer-request" className={this.state.Tab === 'transfer-request' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'transfer-request','','')}><i className="mdi mdi-wallet"></i><span> Transfer Request </span></Link>
            </li>
            :
            this?.state?.rolesList?.some(role => role?.id === "467d3dcc-d910-4352-b873-55457551f29c")?
            <li id='467d3dcc-d910-4352-b873-55457551f29c'>
               <Link to="/transfer-request" className={this.state.Tab === 'transfer-request' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'transfer-request','','')}><i className="mdi mdi-wallet"></i><span> Transfer Request </span></Link>
            </li>
            :''
            }
            {this.state.Admin? 
            <li id='225d4b2b-fc2e-490b-a0a7-531f95461767'>
               <Link to="/banks" className={this.state.Tab === 'banks' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'banks','','')}><i className="mdi mdi-bank"></i><span> Banks </span></Link>
            </li>
            : this?.state?.rolesList?.some(role=>role?.id==='225d4b2b-fc2e-490b-a0a7-531f95461767')?
            <li id='225d4b2b-fc2e-490b-a0a7-531f95461767'>
               <Link to="/banks" className={this.state.Tab === 'banks' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'banks','','')}><i className="mdi mdi-bank"></i><span> Banks </span></Link>
            </li>
            :''
            }
            {this?.state.Admin?
            <li id='ac822b95-0d77-4cd1-a721-e773a26df538'>
               <Link to="/driver" className={this.state.Tab === 'driver' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'driver','','')}><i className="typcn typcn-th-large-outline"></i><span> Driver </span></Link>
            </li>
            : this?.state?.rolesList?.some(role=>role?.id==='ac822b95-0d77-4cd1-a721-e773a26df538')?
            <li id='ac822b95-0d77-4cd1-a721-e773a26df538'>
               <Link to="/driver" className={this.state.Tab === 'driver' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'driver','','')}><i className="typcn typcn-th-large-outline"></i><span> Driver </span></Link>
            </li>
            :''
            }
            {this.state.Admin?
            <li id='562e6d9d-0e01-4d6b-a528-de9a8d67c4de'>
               <Link to="/reported-Posts" className={this.state.Tab === 'reportedPosts' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'reportedPosts','','')}><i className="mdi mdi-cube-outline"></i><span>Reported Posts</span></Link>
            </li>
            :this?.state?.rolesList?.some(role=>role?.id==='562e6d9d-0e01-4d6b-a528-de9a8d67c4de')?
            <li id='562e6d9d-0e01-4d6b-a528-de9a8d67c4de'>
               <Link to="/reported-Posts" className={this.state.Tab === 'reportedPosts' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'reportedPosts','','')}><i className="mdi mdi-cube-outline"></i><span>Reported Posts</span></Link>
            </li>
            :''
            }
            <li className="menu-title">Master</li>
            {this.state.Admin?
            <li>
               <Link to="/order-status" id='f395e4b2-a6e2-4409-a860-866ae59027c8' className={this.state.Tab === 'order-status' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'morder-statusaster','','')}><i className="mdi mdi-clock-start"></i><span> Order Status Master </span></Link>
            </li>
            : this?.state?.rolesList?.some(role=>role.id === "f395e4b2-a6e2-4409-a860-866ae59027c8")?
            <li>
               <Link to="/order-status" id='f395e4b2-a6e2-4409-a860-866ae59027c8' className={this.state.Tab === 'order-status' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'morder-statusaster','','')}><i className="mdi mdi-clock-start"></i><span> Order Status Master </span></Link>
            </li>
            :''
            } 
            { this.state.Admin?
            <li id='0be12740-9a48-4996-b8cb-1fcffefecf88'>
               <Link to="/attribute-All" className={this.state.Tab === 'attribute' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'attribute','','')}><i className="mdi mdi-cube-outline"></i><span>Attribute Master</span></Link>
            </li>
            :  this?.state?.rolesList?.some(role=>role?.id==='0be12740-9a48-4996-b8cb-1fcffefecf88')?
            <li id='0be12740-9a48-4996-b8cb-1fcffefecf88'>
               <Link to="/attribute-All" className={this.state.Tab === 'attribute' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'attribute','','')}><i className="mdi mdi-cube-outline"></i><span>Attribute Master</span></Link>
            </li>
            :""
            }
            {this.state.Admin?
            <li id='b058a36d-f292-46a3-9616-b4ec2c2a8b53'>
               <Link to="package" className={this.state.Tab === 'package' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'package','','')}><i className="mdi mdi-cube-outline"></i><span>Package Master</span></Link>
            </li>
            : this?.state?.rolesList?.some(role=>role?.id==='b058a36d-f292-46a3-9616-b4ec2c2a8b53')?
            <li id='b058a36d-f292-46a3-9616-b4ec2c2a8b53'>
               <Link to="package" className={this.state.Tab === 'package' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'package','','')}><i className="mdi mdi-cube-outline"></i><span>Package Master</span></Link>
            </li>
            :''                       
            }
            {this.state.Admin?
            <li className={this.state.location_menu ? 'has_sub active-menu nav-active' : 'has_sub'} id='f6321b72-85fd-4525-8c03-d19ccba54fb7'>
            <Link to="#" onClick={()=>
            this.setState({ location_menu: !this.state.location_menu, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'index' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-map-marker"></i> <span>Location Master <span className='pull-right'><i className="mdi mdi-chevron-right"></i></span> </span> </Link>
            <ul className="list-unstyled" style={{display: this.state.location_menu ? 'block' : 'none' }}>
            <li><Link className={this.state.SubTab === 'country' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'index','country','')}  to="/country">Country</Link></li>
            <li><Link className={this.state.SubTab === 'state' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'index','state','')}   to="/state">State</Link></li>
            <li><Link className={this.state.SubTab === 'city' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'index','city','')}   to="/city">City</Link></li>
            </ul>
            </li>
            :this?.state?.rolesList?.some(role=>role?.id==='f6321b72-85fd-4525-8c03-d19ccba54fb7')?
            <li className={this.state.location_menu ? 'has_sub active-menu nav-active' : 'has_sub'} id='f6321b72-85fd-4525-8c03-d19ccba54fb7'>
            <Link to="#" onClick={()=>
            this.setState({ location_menu: !this.state.location_menu, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'index' ? 'waves-effect active-menu':'waves-effect'}  ><i className="mdi mdi-map-marker"></i> <span>Location Master <span className='pull-right'><i className="mdi mdi-chevron-right"></i></span> </span> </Link>
            <ul className="list-unstyled" style={{display: this.state.location_menu ? 'block' : 'none' }}>
            <li><Link className={this.state.SubTab === 'country' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'index','country','')}  to="/country">Country</Link></li>
            <li><Link className={this.state.SubTab === 'state' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'index','state','')}   to="/state">State</Link></li>
            <li><Link className={this.state.SubTab === 'city' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'index','city','')}   to="/city">City</Link></li>
            </ul>
            </li> :''
            }
            {/* {this.state.Admin?
            <li>
               <Link to="#"  onClick={()=>
               this.setState({ master: !this.state.master,dashboard_menu:false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'master' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-cube-outline"></i> <span> Master <span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span> </Link>
               <ul className="list-unstyled" style={{display: this.state.master ? 'block' : 'none' }}>
            <li><Link className={this.state.SubTab === 'order-status' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'morder-statusaster','')}  to="/order-status">Order Status Master</Link></li>
            </ul>
            </li>
            :this?.state?.rolesList?.some(role => role?.id === '7e550c18-d32b-4d39-93cb-e03f7b9acba2')?
            <li>
               <Link to="#"  onClick={()=>
               this.setState({ master: !this.state.master,dashboard_menu:false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'master' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-cube-outline"></i> <span> Master <span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span> </Link>
               <ul className="list-unstyled" style={{display: this.state.master ? 'block' : 'none' }}>
            <li><Link className={this.state.SubTab === 'order-status' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'morder-statusaster','')}  to="/order-status">Order Status Master</Link></li>
            </ul>
            </li>:''
            } */}
            <li className="menu-title">Marketing</li>
            {this.state.Admin?
            <li id='90810571-543c-4f40-b373-51d099f6acde'>
               <Link to="/coupon" className={this.state.Tab === 'coupon' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'coupon','','')}><i className="mdi mdi-percent"></i><span>Coupon Master</span></Link>
            </li>
            :this?.state?.rolesList?.some(role=>role?.id==='90810571-543c-4f40-b373-51d099f6acde')?
            <li id='90810571-543c-4f40-b373-51d099f6acde'>
               <Link to="/coupon" className={this.state.Tab === 'coupon' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'coupon','','')}><i className="mdi mdi-percent"></i><span>Coupon Master</span></Link>
            </li>
            :''
            }
            {this.state.Admin?
            <li id='5c11d43d-65ac-40b7-8ac5-88006285dfa4'>
               <Link to="/push-notification" className={this.state.Tab === 'push' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'push','','')}><i className="mdi mdi-cube-outline"></i><span>Push Notifications</span></Link>
            </li>
            : this?.state?.rolesList?.some(role=>role?.id==='5c11d43d-65ac-40b7-8ac5-88006285dfa4')?
            <li id='5c11d43d-65ac-40b7-8ac5-88006285dfa4'>
               <Link to="/push-notification" className={this.state.Tab === 'push' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'push','','')}><i className="mdi mdi-cube-outline"></i><span>Push Notifications</span></Link>
            </li>
            :''
            }
            {/* 
            <li>
               <Link to="widgets" className={this.state.Tab === 'widget' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'widget','','')}><i className="mdi mdi-cube-outline"></i><span> Widgets </span></Link>
            </li>
            <li className={this.state.email_menu ? 'has_sub active-menu nav-active' : 'has_sub'}>
            <Link to="#" onClick={()=>
            this.setState({ email_menu: !this.state.email_menu,dashboard_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) } className={this.state.Tab === 'email' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-email-outline"></i><span> Email <span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span></Link>
            <ul className="list-unstyled" style={{display: this.state.email_menu ? 'block' : 'none' }}>
            <li><Link  className={this.state.SubTab === 'email_inbox' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'email','email_inbox','')}  to="email_inbox">Inbox</Link></li>
            <li><Link  className={this.state.SubTab === 'email_read' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'email','email_read','')}  to="email_read">Email Read</Link></li>
            <li><Link  className={this.state.SubTab === 'email_compose' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'email','email_compose','')}  to="email_compose">Email Compose</Link></li>
            </ul>
            </li>
            <li>
               <Link to="calendar" className={this.state.Tab === 'calendar' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'calendar','','')}><i className="mdi mdi-calendar-check"></i><span> Calendar </span></Link>
            </li>
            */}
            {/*  */}
            <li className="menu-title">Reports</li>
                  {this.state.Admin ? (
                     <>
                        <li>
                           <Link
                              to="/sales-report"
                              id="f395e4b2-a6e2-4409-a860-866ae59027c8"
                              className={this.state.Tab === 'salesReport' ? 'waves-effect active-menu' : 'waves-effect'}
                              onClick={this.setActiveTab.bind(this, 'salesReport', '', '')}
                           >
                              <i className="mdi mdi-clock-start"></i>
                              <span> Master Sales Report </span>
                           </Link>
                        </li>
                        <li>
                           <Link
                              to="/vendor-sales-report"
                              id="vendor-sales-report-id"
                              className={this.state.Tab === 'vendorSalesReport' ? 'waves-effect active-menu' : 'waves-effect'}
                              onClick={this.setActiveTab.bind(this, 'vendorSalesReport', '', '')}
                           >
                              <i className="mdi mdi-clock-start"></i>
                              <span> Vendor Sales Report </span>
                           </Link>
                        </li>
                     </>
                  ) : this?.state?.rolesList?.some(role => role.id === "f395e4b2-a6e2-4409-a860-866ae59027c8") ? (
                     <li>
                        <Link
                           to="/sales-report"
                           id="f395e4b2-a6e2-4409-a860-866ae59027c8"
                           className={this.state.Tab === 'salesReport' ? 'waves-effect active-menu' : 'waves-effect'}
                           onClick={this.setActiveTab.bind(this, 'salesReport', '', '')}
                        >
                           <i className="mdi mdi-clock-start"></i>
                           <span> Sales Report </span>
                        </Link>
                     </li>
                  ) : ''}
            <li className="menu-title">Settings</li>
            {this.state.Admin?
            <li>
               <Link to="/time-slot" id='f395e4b2-a6e2-4409-a860-866ae59027c8' className={this.state.Tab === 'timeslot' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'timeslot','','')}><i className="mdi mdi-clock-start"></i><span> Timeslot </span></Link>
            </li>
            : this?.state?.rolesList?.some(role=>role.id === "f395e4b2-a6e2-4409-a860-866ae59027c8")?
            <li>
               <Link to="/time-slot" id='f395e4b2-a6e2-4409-a860-866ae59027c8' className={this.state.Tab === 'timeslot' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'timeslot','','')}><i className="mdi mdi-clock-start"></i><span> Timeslot </span></Link>
            </li>
            :''
            }
            {
            this.state.Admin?
            <li id='fdad1996-71ed-458d-9e28-d8b48966eed5'>
               <Link to="/about-us" className={this.state.Tab === 'about' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'about','','')}><i className="mdi mdi-file-document-box"></i><span>About Us</span></Link>
            </li>
            :this?.state?.rolesList?.some(role=>role?.id==='fdad1996-71ed-458d-9e28-d8b48966eed5')?
            <li id='fdad1996-71ed-458d-9e28-d8b48966eed5'>
               <Link to="/about-us" className={this.state.Tab === 'about' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'about','','')}><i className="mdi mdi-file-document-box"></i><span>About Us</span></Link>
            </li>
            :""
            }
            {
            this.state.Admin?
            <li id='d18956d9-ca7c-4bfe-981c-9dbfae6b10fe'>
               <Link to="/privacy-policy" className={this.state.Tab === 'privacy' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'privacy','','')}><i className="mdi mdi-file-document-box"></i><span>Privacy Policy</span></Link>
            </li>
            : this?.state?.rolesList?.some(role=>role?.id==='d18956d9-ca7c-4bfe-981c-9dbfae6b10fe')?
            <li id='d18956d9-ca7c-4bfe-981c-9dbfae6b10fe'>
               <Link to="/privacy-policy" className={this.state.Tab === 'privacy' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'privacy','','')}><i className="mdi mdi-file-document-box"></i><span>Privacy Policy</span></Link>
            </li>
            :''
            }
            {
            this.state.Admin?
            <li id='4305e36d-3bbd-484f-8d8c-16808fe12586'>
               <Link to="/terms-condition" className={this.state.Tab === 'tc' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'tc','','')}><i className="mdi mdi-file-document-box"></i><span>Terms & Condition</span></Link>
            </li>
            : this?.state?.rolesList?.some(role=>role?.id==='4305e36d-3bbd-484f-8d8c-16808fe12586')?
            <li id='4305e36d-3bbd-484f-8d8c-16808fe12586'>
               <Link to="/terms-condition" className={this.state.Tab === 'tc' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'tc','','')}><i className="mdi mdi-file-document-box"></i><span>Terms & Condition</span></Link>
            </li>
            :''
            }
            {
            this.state.Admin?
            <li id='021763a7-1d34-431e-afc3-c1924bdafd74'>
               <Link to="/contact-us" className={this.state.Tab === 'contactUs' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'contactUs','','')}><i className="mdi mdi-file-document-box"></i><span>Contact Us</span></Link>
            </li>
            : this?.state?.rolesList?.some(role=>role?.id==='021763a7-1d34-431e-afc3-c1924bdafd74')?
            <li id='021763a7-1d34-431e-afc3-c1924bdafd74'>
               <Link to="/contact-us" className={this.state.Tab === 'contactUs' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'contactUs','','')}><i className="mdi mdi-file-document-box"></i><span>Contact Us</span></Link>
            </li>
            :''
            }
            {
            this.state.Admin?
            <li id='021763a7-1d34-431e-afc3-c1924bdafd74'>
               <Link to="/benefits" className={this.state.Tab === 'benefits' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'benefits','','')}><i className="mdi mdi-file-document-box"></i><span>Benefits</span></Link>
            </li>
            : this?.state?.rolesList?.some(role=>role?.id==='021763a7-1d34-431e-afc3-c1924bdafd74')?
            <li id='021763a7-1d34-431e-afc3-c1924bdafd74'>
               <Link to="/benefits" className={this.state.Tab === 'benefits' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'benefits','','')}><i className="mdi mdi-file-document-box"></i><span>Benefits</span></Link>
            </li>
            :''
            }
            {
            this.state.Admin?
            <li id='021763a7-1d34-431e-afc3-c1924bdafd74'>
               <Link to="/features" className={this.state.Tab === 'features' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'features','','')}><i className="mdi mdi-file-document-box"></i><span>Features</span></Link>
            </li>
            : this?.state?.rolesList?.some(role=>role?.id==='021763a7-1d34-431e-afc3-c1924bdafd74')?
            <li id='021763a7-1d34-431e-afc3-c1924bdafd74'>
               <Link to="/features" className={this.state.Tab === 'features' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'features','','')}><i className="mdi mdi-file-document-box"></i><span>Features</span></Link>
            </li>
            :''
            }
            {/* <li  className={this.state.ui_menu ? 'has_sub active-menu nav-active' : 'has_sub'}>
            <Link to="#"  onClick={()=>
            this.setState({ ui_menu: !this.state.ui_menu, email_menu : false, dashboard_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'ui' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-buffer"></i> <span> User Interface <span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span> </Link>
            <ul className="list-unstyled" style={{display: this.state.ui_menu ? 'block' : 'none' }}>
            <li><Link className={this.state.SubTab === 'buttons' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','buttons','')} to="buttons">Buttons</Link></li>
            <li><Link className={this.state.SubTab === 'colors' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','colors','')} to="colors">Colors</Link></li>
            <li><Link className={this.state.SubTab === 'cards' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','cards','')} to="cards">Cards</Link></li>
            <li><Link className={this.state.SubTab === 'tabs_accordions' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','tabs_accordions','')} to="tabs_accordions">Tabs &amp; Accordions</Link></li>
            <li><Link className={this.state.SubTab === 'modals' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','modals','')} to="modals">Modals</Link></li>
            <li><Link className={this.state.SubTab === 'images' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','images','')} to="images">Images</Link></li>
            <li><Link className={this.state.SubTab === 'alerts' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','alerts','')} to="alerts">Alerts</Link></li>
            <li><Link className={this.state.SubTab === 'progressbars' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','progressbars','')} to="progressbars">Progress Bars</Link></li>
            <li><Link className={this.state.SubTab === 'dropdowns' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','dropdowns','')} to="dropdowns">Dropdowns</Link></li>
            <li><Link className={this.state.SubTab === 'lightbox' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','lightbox','')} to="lightbox">Lightbox</Link></li>
            <li><Link className={this.state.SubTab === 'navs' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','navs','')} to="navs">Navs</Link></li>
            <li><Link className={this.state.SubTab === 'pagination' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','pagination','')} to="pagination">Pagination</Link></li>
            <li><Link className={this.state.SubTab === 'popover_tooltips' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','popover_tooltips','')} to="popover_tooltips">Popover & Tooltips</Link></li>
            <li><Link className={this.state.SubTab === 'badge' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','badge','')} to="badge">Badge</Link></li>
            <li><Link className={this.state.SubTab === 'carousel' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','carousel','')} to="carousel">Carousel</Link></li>
            <li><Link className={this.state.SubTab === 'video' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','video','')} to="video">Video</Link></li>
            <li><Link className={this.state.SubTab === 'typography' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','typography','')} to="typography">Typography</Link></li>
            <li><Link className={this.state.SubTab === 'sweet_alert' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','sweet_alert','')} to="sweet_alert">Sweet-Alert</Link></li>
            <li><Link className={this.state.SubTab === 'grid' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','grid','')} to="grid">Grid</Link></li>
            <li><Link className={this.state.SubTab === 'animation' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','animation','')} to="animation">Animation</Link></li>
            <li><Link className={this.state.SubTab === 'highlight' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','highlight','')} to="highlight">Highlight</Link></li>
            <li><Link className={this.state.SubTab === 'rating' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','rating','')} to="rating">Rating</Link></li>
            <li><Link className={this.state.SubTab === 'nestable' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','nestable','')} to="nestable">Nestable</Link></li>
            <li><Link className={this.state.SubTab === 'alertify' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','alertify','')} to="alertify">Alertify</Link></li>
            <li><Link className={this.state.SubTab === 'rangeslider' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','rangeslider','')} to="rangeslider">Range Slider</Link></li>
            <li><Link className={this.state.SubTab === 'sessiontimeout' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'ui','sessiontimeout','')} to="sessiontimeout">Session Timeout</Link></li>
            </ul>
            </li> */}
            {/* <li  className={this.state.form_menu ? 'has_sub active-menu nav-active' : 'has_sub'}>
            <Link to="#"  onClick={()=>
            this.setState({ form_menu: !this.state.form_menu, dashboard_menu : false, email_menu : false, ui_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'form' ? 'waves-effect active':'waves-effect'}><i className="mdi mdi-clipboard-outline"></i><span> Forms <span className="badge badge-pill badge-success pull-right">9</span> </span></Link>
            <ul className="list-unstyled"  style={{display: this.state.form_menu ? 'block' : 'none' }}>
            <li><Link className={this.state.SubTab === 'form_elements' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'form','form_elements','')} to="form_elements">Form Elements</Link></li>
            <li><Link className={this.state.SubTab === 'form_validation' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'form','form_validation','')} to="form_validation">Form Validation</Link></li>
            <li><Link className={this.state.SubTab === 'form_advanced' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'form','form_advanced','')} to="form_advanced">Form Advanced</Link></li>
            <li><Link className={this.state.SubTab === 'form_wizard' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'form','form_wizard','')} to="form_wizard">Form Wizard</Link></li>
            <li><Link className={this.state.SubTab === 'form_editors' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'form','form_editors','')} to="form_editors">Form Editors</Link></li>
            <li><Link className={this.state.SubTab === 'form_uploads' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'form','form_uploads','')} to="form_uploads">Form File Upload</Link></li>
            <li><Link className={this.state.SubTab === 'form_mask' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'form','form_mask','')} to="form_mask">Form Mask</Link></li>
            <li><Link className={this.state.SubTab === 'form_summernote' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'form','form_summernote','')} to="form_summernote">Summernote</Link></li>
            <li><Link className={this.state.SubTab === 'form_xeditable' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'form','form_xeditable','')} to="form_xeditable">Form Xeditable</Link></li>
            </ul>
            </li> */}
            {/* <li  className={this.state.chart_menu ? 'has_sub active-menu nav-active' : 'has_sub'}>
            <Link to="#" onClick={()=>
            this.setState({ chart_menu: !this.state.chart_menu,dashboard_menu : false, email_menu : false, ui_menu: false,form_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'chart' ? 'waves-effect active':'waves-effect'}><i className="mdi mdi-chart-line"></i><span> Charts <span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span></Link>
            <ul className="list-unstyled" style={{display: this.state.chart_menu ? 'block' : 'none' }}>
            <li><Link className={this.state.SubTab === 'charts_easy' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'chart','charts_easy','')} to="charts_easy">Easy Chart</Link></li>
            <li><Link className={this.state.SubTab === 'chartjs' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'chart','chartjs','')} to="chartjs">ChartJs Chart</Link></li>
            <li><Link className={this.state.SubTab === 'apexchart' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'chart','apexchart','')} to="apexchart">Apex Chart</Link></li>
            <li><Link className={this.state.SubTab === 'sparkline' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'chart','sparkline','')} to="sparkline">SparkLine Chart</Link></li>
            </ul>
            </li> */}
            {/* <li className={this.state.table_menu ? 'has_sub active-menu nav-active' : 'has_sub'}>
            <Link to="#" onClick={()=>
            this.setState({ table_menu: !this.state.table_menu,dashboard_menu : false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'table' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-format-list-bulleted-type"></i><span> Tables <span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span></Link>
            <ul className="list-unstyled" style={{display: this.state.table_menu ? 'block' : 'none' }}>
            <li><Link className={this.state.SubTab === 'tables_basic' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'table','tables_basic','')} to="tables_basic">Basic Tables</Link></li>
            <li><Link className={this.state.SubTab === 'tables_datatable' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'table','tables_datatable','')} to="tables_datatable">Data Table</Link></li>
            <li><Link className={this.state.SubTab === 'tables_responsive' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'table','tables_responsive','')} to="tables_responsive">Responsive Table</Link></li>
            <li><Link className={this.state.SubTab === 'tables_editable' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'table','tables_editable','')} to="tables_editable">Editable Table</Link></li>
            </ul>
            </li> */}
            {/* <li className={this.state.icon_menu ? 'has_sub active-menu nav-active' : 'has_sub'}>
            <Link to="#" onClick={()=>
            this.setState({ icon_menu: !this.state.icon_menu,dashboard_menu : false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false}) }  className={this.state.Tab === 'icon' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-album"></i> <span> Icons  <span className="pull-right"><i className="mdi mdi-chevron-right"></i></span></span> </Link>
            <ul className="list-unstyled" style={{display: this.state.icon_menu ? 'block' : 'none' }}>
            <li><Link  className={this.state.SubTab === 'icons_material' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'icon','icons_material','')} to="icons_material">Material Design</Link></li>
            <li><Link  className={this.state.SubTab === 'icons_ion' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'icon','icons_ion','')} to="icons_ion">Ion Icons</Link></li>
            <li><Link  className={this.state.SubTab === 'icons_fontawesome' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'icon','icons_fontawesome','')} to="icons_fontawesome">Font Awesome</Link></li>
            <li><Link  className={this.state.SubTab === 'icons_themify' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'icon','icons_themify','')} to="icons_themify">Themify Icons</Link></li>
            <li><Link  className={this.state.SubTab === 'icons_dripicons' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'icon','icons_dripicons','')} to="icons_dripicons">Dripicons</Link></li>
            <li><Link  className={this.state.SubTab === 'icons_typicons' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'icon','icons_typicons','')} to="icons_typicons">Typicons I_ons</Link></li>
            <li><Link  className={this.state.SubTab === 'icons_weather' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'icon','icons_weather','')} to="icons_weather">Weather Icons</Link></li>
            <li><Link  className={this.state.SubTab === 'icons_mobirise' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'icon','icons_mobirise','')} to="icons_mobirise">Mobirise Icons</Link></li>
            </ul>
            </li> */}
            {/* <li className={this.state.map_menu ? 'has_sub active-menu nav-active' : 'has_sub'}>
            <Link to="#" onClick={()=>
            this.setState({ map_menu: !this.state.map_menu,dashboard_menu : false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'map' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-google-maps"></i><span> Maps <span className="badge badge-pill badge-danger pull-right">2</span> </span></Link>
            <ul className="list-unstyled" style={{display: this.state.map_menu ? 'block' : 'none' }}>
            <li><Link className={this.state.SubTab === 'maps_google' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'map','maps_google','')}  to="maps_google"> Google Map</Link></li>
            <li><Link className={this.state.SubTab === 'maps_vector' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'map','maps_vector','')}  to="maps_vector"> Vector Map</Link></li>
            </ul>
            </li> */}
            {/* 
            <li className="menu-title">Extras</li>
            <li className={this.state.auth_menu ? 'has_sub active-menu nav-active' : 'has_sub'}>
            <Link to="#" onClick={()=>
            this.setState({ auth_menu: !this.state.auth_menu,dashboard_menu : false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) } className="waves-effect"><i className="mdi mdi-account-location"></i><span> Authentication <span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span></Link>
            <ul className="list-unstyled"  style={{display: this.state.auth_menu ? 'block' : 'none' }}>
            <li>
               <Link to="pages_login">
               Login</Link>
            </li>
            <li>
               <Link to="pages_register">
               Register</Link>
            </li>
            <li>
               <Link to="pages_recoverpw">
               Recover Password</Link>
            </li>
            <li>
               <Link to="pages_lock_screen">
               Lock Screen</Link>
            </li>
            <li>
               <Link to="pages_login_2">
               Login 2</Link>
            </li>
            <li>
               <Link to="pages_register_2">
               Register 2</Link>
            </li>
            <li>
               <Link to="pages_recoverpw_2">
               Recover Password 2</Link>
            </li>
            <li>
               <Link to="pages_lock_screen_2">
               Lock Screen 2</Link>
            </li>
            </ul>
            </li>
            <li className={this.state.extra_menu ? 'has_sub active-menu nav-active' : 'has_sub'}>
            <Link to="#" onClick={()=>
            this.setState({ extra_menu: !this.state.extra_menu,dashboard_menu : false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'extra' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-google-pages"></i><span> Extra Pages <span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span></Link>
            <ul className="list-unstyled"  style={{display: this.state.extra_menu ? 'block' : 'none' }}>
            <li><Link className={this.state.SubTab === 'pages_timeline' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'extra','pages_timeline','')}  to="pages_timeline">Timeline</Link></li>
            <li><Link className={this.state.SubTab === 'pages_invoice' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'extra','pages_invoice','')}  to="pages_invoice">Invoice</Link></li>
            <li><Link className={this.state.SubTab === 'pages_directory' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'extra','pages_directory','')}  to="pages_directory">Directory</Link></li>
            <li><Link className={this.state.SubTab === 'pages_blank' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'extra','pages_blank','')}  to="pages_blank">Blank Page</Link></li>
            <li><Link className={this.state.SubTab === 'pages_400' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'extra','pages_400','')}  to="pages_400">Error 404</Link></li>
            <li><Link className={this.state.SubTab === 'pages_500' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'extra','pages_500','')}  to="pages_500">Error 500</Link></li>
            <li><Link className={this.state.SubTab === 'pages_pricing' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'extra','pages_pricing','')}  to="pages_pricing">Pricing</Link></li>
            <li><Link className={this.state.SubTab === 'pages_gallery' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'extra','pages_gallery','')}  to="pages_gallery">Gallery</Link></li>
            <li><Link className={this.state.SubTab === 'pages_maintenance' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'extra','pages_maintenance','')}  to="pages_maintenance">Maintenance</Link></li>
            <li><Link className={this.state.SubTab === 'pages_coming_soon' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'extra','pages_coming_soon','')}  to="pages_coming_soon">Coming Soon</Link></li>
            </ul>
            </li>
            <li className={this.state.eco_menu ? 'has_sub active-menu nav-active' : 'has_sub'}>
            <Link to="#" onClick={()=>
            this.setState({ eco_menu: !this.state.eco_menu,dashboard_menu : false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,emt_menu: false }) }   className={this.state.Tab === 'eco' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-cart-outline"></i><span> Ecommerce <span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span></Link>
            <ul className="list-unstyled" style={{display: this.state.eco_menu ? 'block' : 'none' }}>
            <li><Link className={this.state.SubTab === 'ecommerce_product_list' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'eco','ecommerce_product_list','')}  to="ecommerce_product_list">Product List</Link></li>
            <li><Link className={this.state.SubTab === 'ecommerce_product_grid' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'eco','ecommerce_product_grid','')}  to="ecommerce_product_grid">Product Grid</Link></li>
            <li><Link className={this.state.SubTab === 'ecommerce_order_history' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'eco','ecommerce_order_history','')}  to="ecommerce_order_history">Order History</Link></li>
            <li><Link className={this.state.SubTab === 'ecommerce_customers' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'eco','ecommerce_customers','')}  to="ecommerce_customers">Customers</Link></li>
            <li><Link className={this.state.SubTab === 'ecommerce_product_edit' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'eco','ecommerce_product_edit','')}  to="ecommerce_product_edit">Product Edit</Link></li>
            </ul>
            </li>
            <li>
               <a href="/" className="waves-effect" target="_blank"><i className="mdi mdi-airplane"></i><span> Front End </span></a>
            </li>
            <li className={this.state.emt_menu ? 'has_sub active-menu nav-active' : 'has_sub'}>
            <Link to="#" onClick={()=>
            this.setState({ emt_menu: !this.state.emt_menu,dashboard_menu : false, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false }) }  className={this.state.Tab === 'emt' ? 'waves-effect active-menu':'waves-effect'}><i className="mdi mdi-flask-outline"></i><span> Email Templates <span className="pull-right"><i className="mdi mdi-chevron-right"></i></span> </span></Link>
            <ul className="list-unstyled"  style={{display: this.state.emt_menu ? 'block' : 'none' }}>
            <li><Link  className={this.state.SubTab === 'email_templates_basic' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'emt','email_templates_basic','')} to="email_templates_basic">Basic Action Email</Link></li>
            <li><Link  className={this.state.SubTab === 'email_templates_alert' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'emt','email_templates_alert','')} to="email_templates_alert">Alert Email</Link></li>
            <li><Link  className={this.state.SubTab === 'email_templates_billing' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'emt','email_templates_billing','')} to="email_templates_billing">Billing Email</Link></li>
            </ul>
            </li> */}
            {/* 
            <li className="menu-title">Help & Support</li>
            <li>
               <Link to="pages_faq" className={this.state.Tab === 'faq' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'faq','','')}><i className="mdi mdi-help-circle"></i><span> FAQ </span></Link>
            </li>
            <li>
               <Link to="pages_contact" className={this.state.Tab === 'contact' ? 'waves-effect active-menu':'waves-effect'} onClick={this.setActiveTab.bind(this, 'contact','','')}><i className="mdi mdi-headset"></i><span> Contact <span className="badge badge-pill badge-warning pull-right">3</span> </span></Link>
            </li>
            <li>
               <Link to="#" className="waves-effect">
               <i className="mdi mdi-file-document-box"></i><span> Documentation </span></Link>
            </li>
            */}
            </ul>
         </div>
         <div className="clearfix"></div>
      </PerfectScrollbar>
   </div>
</div>
);
}
}
sidebar.propsTypes = {
getRole: PropTypes.func.isRequired
};
var mapStateToProps = (state) => ({
Zeed: state.Zeed,
});
export default withRouter(connect(mapStateToProps, { getRole })(sidebar));
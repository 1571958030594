import React, { useEffect, useState } from 'react';
import AUX from '../../../hoc/Aux_';
import { useParams, Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getUserDropdown, getCategory, getSubCategoryDropdown, getProduct, addProduct, updateProduct } from '../../../redux/MainAction';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Axios from "axios";
import Constant from "../../../Constant";
import Swal from 'sweetalert2';

const ProductAdd = () => {
    const role = localStorage.getItem("role");
    const vendorId = localStorage.getItem("vendorId");
    const params = useParams();
    const dispatch = useDispatch();
    const CateList = useSelector(state => state.Zeed.category);
    const fixedList = useSelector(state => state.Zeed.productList);
    const SubList = useSelector(state => state.Zeed.subCategory);

    const [catValue, setCategoryValue] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);

    const UserList = useSelector(state => state.Zeed.userList);

    const [usValue, setUserValue] = useState([]);
    const [userOptions, setUserOptions] = useState([]);

    const [subCatValue, setSubCategoryValue] = useState([]);
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    
    const [prductImageId, setProductImageId] = useState("");
    const [productImages, setProductImages] = useState([]);

    // const onImageChange = async (e) => {
    //     try {
    //         const imgData = e.target.files[0];
    //         const img = await UploadMedia(imgData, usValue);
    //         setProductImageId(img.id);
    //         setProductImg(img.url);
    //     } catch (error) {
    //         console.error("Error uploading media:", error);
    //     }
    // };

      const onImageChange = async (e) => {
        const files = Array.from(e.target.files);
        const uploadedImages = await Promise.all(
          files.map(async (file) => {
            try {
              const img = await UploadMedia(file, usValue);
              return img;
            } catch (error) {
              console.error("Error uploading media:", error);
              return null;
            }
          })
        );

        setProductImages((prevImages) => [
          ...prevImages,
          ...uploadedImages.filter((img) => img !== null),
        ]);
      };

    const UploadMedia = async (imageData, userId) => {
      const formData = new FormData();
      formData.append("media", imageData);
      formData.append("user", userId);
      try {
        const response = await Axios.post(
          `${Constant.getAPI()}/media/add`,
          formData,
          {
            headers: {
              Authorization: localStorage.getItem("Token"),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.status) {
          return {
            id: response.data.data[0]?.id,
            url: response.data.data[0]?.url,
          };
        } else {
          return "";
        }
      } catch (error) {
        // console.log(error.data);
        return "";
      }
    };

    const removeImage = (imageId) => {
      setProductImages((prevImages) =>
        prevImages.filter((img) => img.id !== imageId)
      );
    };
    
    useEffect(() => {
        getRolList();
    }, []);

    const path = window.location.pathname;
    const tri = path.slice(0, 14);

    const getRolList = () => {
        var DATA = {
            startRange: '0',
            count: "1000"
        };
        dispatch(getCategory(DATA));
        dispatch(getUserDropdown(DATA));
        dispatch(getSubCategoryDropdown(DATA));
        if (tri === "/product-Fixed") {
            var DATA = {
                startRange: '0',
                count: "10000",
                type: "Fixed",
                all: "1"
            };
            dispatch(getProduct(DATA));
        } else {
            var DATA = {
                startRange: '0',
                count: "10000",
                type: "Auction",
                all: "1"
            };
            dispatch(getProduct(DATA));
        }
    };

    const onCategoryChange = (selectedOption) => {
        if (selectedOption) {
            setCategoryValue(selectedOption.value);
            dispatch(getSubCategoryDropdown(selectedOption.value, role !== 'vendor' ? coup.userId : vendorId));
        }
        setCoup(prevState => ({
            ...prevState,
            categoryId: selectedOption.value
        }));
    };
    
    const onUserChange = (selectedOption) => {
        if (selectedOption) {
            setUserValue(selectedOption.value);
            dispatch(getSubCategoryDropdown(coup.categoryId, role !== 'vendor' ? selectedOption.value : vendorId));
        }
        setCoup(prevState => ({
            ...prevState,
            userId: selectedOption.value
        }));
    };

    useEffect(() => {
        if (CateList?.length > 0) {
            var data = CateList?.map(opt => {
                return {
                    label: opt.name,
                    value: opt.id
                };
            });
            setCategoryOptions(data);
        }
    }, [CateList]);

    useEffect(() => {
        if (UserList?.length > 0) {
            const data = UserList.map(opt => {
                return {
                    label: opt.userName,
                    value: opt.id
                };
            });
            setUserOptions(data);
        }
    }, [UserList]);

    useEffect(() => {
        if (SubList?.length > 0) {
            const data = SubList.map(opt => {
                return {
                    label: opt.nameEn,
                    value: opt.id
                };
            });
            setSubCategoryOptions(data);
        }else{
            setSubCategoryOptions([]);
        }
    }, [SubList]);

    const [coup, setCoup] = useState({
        categoryId: '',
        titleEn: "",
        titleAr: "",
        price: "",
        quantity: 0,
        descriptionEn: "",
        descriptionAr: "",
        type: tri === "/product-Fixed" ? "Fixed" : "Auction",
    });

    const handleChange = (e) => {
        const { value, name } = e.target;
        setCoup({ ...coup, [name]: value });
    };

    const [variants, setVariants] = useState([]);

    const calculateTotalQuantity = (variants) => {
        return variants.reduce((total, variant) => total + Number(variant.quantity || 0), 0);
    };

    const onAddVariant = () => {
        const newVariant = {
            nameEn: '',
            nameAr: '',
            var2En: '',
            var2Ar: '',
            price: '',
            quantity: ''
        };
        const newVariants = [...variants, newVariant];
        setVariants(newVariants);
        setCoup({ ...coup, quantity: calculateTotalQuantity(newVariants) });
    };

    const removeVariant = (index) => {
        const filteredVariants = [...variants];
        filteredVariants.splice(index, 1);
        setVariants(filteredVariants);
        setCoup({ ...coup, quantity: calculateTotalQuantity(filteredVariants) });
    };

    const variantHandleChange = (e, index) => {
        const updatedVariants = variants.map((data, i) =>
            index === i ? { ...data, [e.target.name]: e.target.value } : data
        );
        setVariants(updatedVariants);
        setCoup({ ...coup, quantity: calculateTotalQuantity(updatedVariants) });
    };

    const [tabID, setTabID] = useState("productDetail");

    const handleTabChange = (tab) => {
      setTabID(tab);
    };


    useEffect(() => {
        if (params.id) {
            var spr = fixedList?.filter(itm => itm.id === params.id);
            const CateList = spr[0]?.category || [];
            if (CateList?.length) {
                const cateArr = [];
                for (const ct of CateList) {
                    const checkCat = CateList?.find(ele => ele.id === ct);
                    if (checkCat) {
                        cateArr.push({
                            label: checkCat.name,
                            value: checkCat.id
                        });
                        onCategoryChange(cateArr);
                    }
                }
            }
            const UserList = spr[0]?.user || [];
            if (UserList?.length) {
                const userArr = [];
                for (const us of UserList) {
                    const checkUs = UserList?.find(ele => ele.id === us);
                    if (checkUs) {
                        userArr.push({
                            label: checkUs.userName,
                            value: checkUs.id
                        });
                        onUserChange(userArr);
                    }
                }
            }
            setCoup({
                titleEn: spr[0]?.titleEn,
                titleAr: spr[0]?.titleAr,
                price: spr[0]?.price,
                quantity: spr[0]?.quantity,
                descriptionEn: spr[0]?.descriptionEn,
                descriptionAr: spr[0]?.descriptionAr,
                type: spr[0]?.type,
            });
        }
    }, [params.id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const categoryId = coup.categoryId;
        const userId = role === "vendor" ? vendorId : coup.userId; 
        const subCategoryId = subCatValue.value;
        const formattedVariants = variants.map(variant => ({
            price: variant.price,
            quantity: variant.quantity,
            attributes: [
                { nameEn: variant.nameEn, nameAr: variant.nameAr },
                { nameEn: variant.var2En, nameAr: variant.var2Ar }
            ]
        }));

        if (params.coup_id) {
            var data = {
                postId: params?.coup_id,
                titleEn: coup.titleEn,
                titleAr: coup.titleAr,
                price: coup.price,
                quantity: coup.quantity,
                descriptionEn: coup.descriptionEn,
                descriptionAr: coup.descriptionAr,
                type: coup.type,
                categoryId: categoryId,
                userId: userId,
                variants: formattedVariants,
                subCategoryId: subCategoryId,
                productImageUrl: productImages,
            };
            dispatch(updateProduct(data))
        } else {
            var data = {
                titleEn: coup.titleEn,
                titleAr: coup.titleAr,
                price: coup.price,
                quantity: coup.quantity,
                descriptionEn: coup.descriptionEn,
                descriptionAr: coup.descriptionAr,
                type: coup.type,
                categoryId: categoryId,
                userId: userId,
                variants: formattedVariants,
                subCategoryId: subCategoryId,
                productImageUrl: productImages,
            };
            if (!data?.titleEn || !data?.titleAr) {
              showWarning("Please Add Title");
              return false;
            }
            if (!data?.categoryId) {
              showWarning("Please Add Category");
              return false;
            }
            if (!data?.subCategoryId) {
              showWarning("Please Add SubCategory");
              return false;
            }
            if (!data?.userId) {
              showWarning("Please Add User");
              return false;
            }
            if (!data?.price) {
              showWarning("Please Add Price");
              return false;
            }
            if (!data?.quantity) {
                showWarning("Please Add Quantity");
                return false;
              }
            if (data.quantity < 0) {
                showWarning("Quantity cannot be negative");
                return false;
            }
            if (data.productImageUrl.length <= 0) {
                showWarning("Please attach image");
                return false;
              }
            dispatch(addProduct(data))     
        }
    };

    function showWarning(message) {
      Swal.fire({
        title: "Warning",
        icon: "warning",
        text: message,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    }
    
    return (
        <AUX>
            <div className="page-content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className={`nav-link ${tabID === "productDetail" ? "active" : ""}`}
                                                data-toggle="tab" href="#productDetail" role="tab" onClick={() => handleTabChange("productDetail")}>
                                                <span className="d-none d-md-block"> Product Detail</span>
                                                <span className="d-block d-md-none"> <i className="mdi mdi-home-variant h5"></i></span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${tabID === "productImage" ? "active" : ""}`}
                                                data-toggle="tab" href="#productImage" role="tab" onClick={() => handleTabChange("productImage")}>
                                                <span className="d-none d-md-block">Product Image</span>
                                                <span className="d-block d-md-none"> <i className="mdi mdi-account h5"></i></span>
                                            </a>
                                        </li>
                                    </ul>
                                       

                                    <div className="tab-content mt-3">
                                        <div className={`tab-pane ${tabID === "productDetail" ? "active" : ""} p-3`} role="tabpanel">      
                                            
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <label>Title in English <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" placeholder="Title in English" name='titleEn' value={coup.titleEn} onChange={handleChange}/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Title in Arabic <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="text" className="form-control" placeholder="Title in Arabic" name='titleAr' value={coup.titleAr} onChange={handleChange}/>
                                                </div>
                                            </div>

                                     
                                            <div className="form-group row">
                                                {role !== 'vendor' ? (
                                                <div className="col-md-6">
                                                    <label>Seller <span style={{ color: 'red' }}>*</span></label>
                                                    <Select onChange={onUserChange} options={userOptions}
                                                        value={userOptions.find(option => option.value === usValue)} />
                                                </div>
                                                  ) : null}
                                                <div className="col-md-6">
                                                    <label>Sell Type <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="col-md-13">
                                                        <select className="form-control" name='status' value={coup.type} onChange={handleChange}>
                                                            <option value="Fixed">Fixed</option>
                                                            {/* <option value="Auction">Auction</option> */}
                                                        </select>
                                                    </div>
                                                </div>  
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <label>Category <span style={{ color: 'red' }}>*</span></label>
                                                    <Select onChange={onCategoryChange} options={categoryOptions}
                                                        value={categoryOptions.find(option => option.value === catValue)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Sub Category <span style={{ color: 'red' }}>*</span></label>
                                                    <Select onChange={setSubCategoryValue} options={subCategoryOptions}
                                                        value={subCategoryOptions.find(option => option.value === subCatValue)} />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <label>Description in English</label>
                                                    <textarea  className="form-control" placeholder="Description in English" name='descriptionEn' 
                                                        value={coup.descriptionEn} onChange={handleChange} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Description in Arabic</label>
                                                    <textarea  className="form-control" placeholder="Description in Arabic" name='descriptionAr' 
                                                        value={coup.descriptionAr} onChange={handleChange} />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <label>Price <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="number" min="0" className="form-control" placeholder="Price" name='price' value={coup.price} onChange={handleChange}/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Quantity <span style={{ color: 'red' }}>*</span></label>
                                                    <input type="number" min="0" className="form-control"  placeholder='Quantity' name='quantity' value={coup.quantity} onChange={handleChange}/>
                                                </div>     
                                            </div>
   
                                            <div className="form-group col-md-12">
                                                <button type='button' className="btn btn-info waves-effect m-l-5" onClick={onAddVariant}>+ Add Variant</button>
                                            </div>
                                            <div className='col-md-12 row'>
                                                {variants?.map((data, index) => {
                                                    return (
                                                        <div className='row col-md-12 attribt' key={index}>
                                                            <div className='col-md-6'>
                                                                <div className='form-group row'>
                                                                    <div className='col-sm-10'>
                                                                        <label>Variant 1 in English</label>
                                                                        <input type='text' className="form-control"  name="nameEn" placeholder="Variant 1 in English"
                                                                            required value={data.nameEn} onChange={(e) => variantHandleChange(e, index)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='form-group row'>
                                                                    <div className='col-sm-10'>
                                                                        <label>Variant 1 in Arabic</label>
                                                                        <input type='text' className="form-control" name="nameAr" placeholder="Variant 1 in Arabic"
                                                                            required value={data.nameAr} onChange={(e) => variantHandleChange(e, index)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='form-group row'>
                                                                    <div className='col-sm-10'>
                                                                        <label>Variant 2 in English</label>
                                                                        <input type='text' className="form-control"  name="var2En" placeholder="Variant 2 in English"
                                                                            required value={data.var2En} onChange={(e) => variantHandleChange(e, index)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='form-group row'>
                                                                    <div className='col-sm-10'>
                                                                        <label>Variant 2 in Arabic</label>
                                                                        <input type='text' className="form-control" name="var2Ar"  placeholder="Variant 2 in Arabic"
                                                                            required value={data.var2Ar} onChange={(e) => variantHandleChange(e, index)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='form-group row'>
                                                                    <div className='col-sm-10'>
                                                                        <label>Variant Price</label>
                                                                        <input type='number' className="form-control" name="price" placeholder="Variant Price"
                                                                            required value={data.price} onChange={(e) => variantHandleChange(e, index)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='form-group row'>
                                                                    <div className='col-sm-10'>
                                                                        <label>Variant Quantity</label>
                                                                        <input  type='number' className="form-control"  name="quantity" placeholder="Variant Quantity"
                                                                            required value={data.quantity} onChange={(e) => variantHandleChange(e, index)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 mt-3">
                                                                <button type='button' className="btn btn-secondary waves-effect m-l-5" onClick={() => removeVariant(index)}>Remove</button>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                        </div>

                                        <div className={`tab-pane ${tabID === "productImage" ? "active" : ""} p-3`} role="tabpanel">
                                            <div className="form-group col-md-6">
                                                <label> Images <span style={{ color: 'red' }}>*</span></label>
                                                <input type="file" accept="image/png, image/gif, image/jpeg, application/pdf , .doc, .docx"
                                                    className="form-control"  onChange={onImageChange} multiple/>
                                            </div>
                                            {/* {productImg && (
                                                <div className="form-group col-md-6">
                                                    <img src={productImg} alt="" className="img-fluid" />
                                                </div>
                                            )} */}
                                            
                                            {/* {productImg !== "" && (
                                                <div className="col-md-9">
                                                    <div className="imgDiv">
                                                    <a href={productImg} target="_blank" > {productImg}</a>
                                        
                                                    <button onClick={() => { setProductImageId(""); setProductImg(""); }}>REMOVE</button>
                                                    </div>
                                                </div>
                                            )} */}
                                            {productImages.map((img, index) => (
                                              <div key={index} className="col-md-9">
                                                <div className="imgDiv">
                                                  <a href={img.url} target="_blank" rel="noopener noreferrer">
                                                  <img src={img.url} alt={`Product ${index}`} className="product-image"/>
                                                    </a>
                                                  <button onClick={() => removeImage(img.id)}>REMOVE</button>
                                                </div>
                                              </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className='d-flex justify-content-end '>
                                            <button type="submit" onClick={handleSubmit} className="btn btn-primary waves-effect waves-light mr-2">
                                                Submit
                                            </button>
                                            <Link to={`${tri === "/product-Fixed" ? "/product-Fixed" : "/product-Auction"}`} className="btn btn-secondary waves-effect m-l-5">
                                                Cancel
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AUX>
    );
};

export default ProductAdd;


import React, { useEffect } from 'react'
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import {getProduct} from '../../../redux/MainAction'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Toggle from 'react-toggle';
import { Button } from 'reactstrap';

const Auction = () => {
    const dispatch = useDispatch()
    const fixedList = useSelector(state=>state.Zeed.productList)

    useEffect(()=>{
        getRolList()
    },[])
    

    const getRolList = ()=>{
        var DATA={
            startRange: '0',
            count: "10000",
            type: "Auction",
            all: "1"
        }
        dispatch(getProduct(DATA))
    }
    const data ={
        columns:[
            {
                label: "Image",
                field: "Media", 
            },
            {
                label: "Title: EN",
                field: "title", 
            },
            {
                label: "Title: Ar",
                field: "title_ar", 
            },
            {
                label: "Participants",
                field: "biddersCount", 
            },
            {
                label: "Category Name: Eng",
                field: "Category", 
            },
            {
                label: "Category Name: Ar",
                field: "Category_ar", 
            },
            {
                label: "Shop Name",
                field: "shop", 
            },
            {
                label: "Quantity",
                field: "quantity", 
            },
            // {
            //     label: "Variants",
            //     field: "Variants", 
            // },
            {
                label: "Date",
                field: "createdAt", 
            },
            {
                label: "Status",
                field: "status", 
            },
            {
                label: "Active",
                field: "active", 
            },
            {
                label: "Action",
                field: "id", 
            },
        ],
        rows: fixedList?.map((Item)=>{return {
            Media: <img src={Item?.Media?.[0]?.url} height={50} />,
            title: Item?.title,
            title_ar: Item?.title_ar,
            biddersCount: <Link to={`/product/AuctionParticipants/${Item?.id}`}>{Item?.biddersCount} <i className="mdi mdi-account-multiple"></i></Link>,
            Category: Item?.Category?.name,
            Category_ar: Item?.Category?.name_ar,
            shop: Item?.shop?.shopName,
            quantity: Item?.quantity,
            createdAt: moment(Item?.createdAt*1000).format("L"),
            status:Item?.status,
            active: <Toggle defaultChecked={Item.active} value={Item.active?true:false}/>,
            id:<Link to={"/product-Auction/edit/" + Item.id} title="Edit" className="mdi mdi-grease-pencil"></Link>
        }})
    }
  return (
    <AUX>
    <div className="page-content-wrapper">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card m-b-20">
                        <div className="card-body">
                            <div className='d-flex justify-content-end'>
                                <Link to='/product-Auction/add'>
                                    <Button size="sm" color="secondary" onClick >Add Product</Button>
                                </Link>
                            </div>
                            <MDBDataTable
                                bordered
                                hover
                                data={data}
                            />
                        </div>
                    </div>
                </div>
            </div> 
        </div> 
    </div>
    </AUX>
  )
}

export default Auction
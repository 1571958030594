import React, { useEffect, useState } from 'react';
import AUX from '../../../hoc/Aux_';
import { Link, useParams } from 'react-router-dom';
import { addCategory, getCategoryBackend, updateCategory } from "../../../redux/MainAction";
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';

const CategoryAdd = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);

  const [cate, setCate] = useState({
    name: "",
    name_ar: "",
    isActive: "true"
  });

  const CateList = useSelector(state => state.Zeed.category);

  useEffect(() => {
    getRolList();
  }, []);

  const getRolList = (pageNum) => {
    const DATA = {
      startRange: (pageNum - 1) * perPage,
      count: perPage,
      page: pageNum,
    };
    dispatch(getCategoryBackend(DATA));
  };

  useEffect(() => {
    if (params.id) {
      const spr = CateList.find(itm => itm.id === params.id);
      if (spr) {
        setCate({
          name: spr.name || "",
          name_ar: spr.name_ar || ""
        });
      }
    }
  }, [params.id, CateList]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setCate({ ...cate, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    
    // Check if the category name already exists
    const existingCategory = CateList.find(item => item.name.toLowerCase() === cate.name.toLowerCase());
    
    if (existingCategory && !params.id) {
      showWarning("A category with this name already exists. Please choose a different name.");
      return false;
    }

    if (!cate?.name) {
      showWarning("Please Add Name in English");
      return false;
    }
    if (!cate?.name_ar) {
      showWarning("Please Add Name in Arabic");
      return false;
    }
    if (params.id) {
      const data = {
        categoryId: params.id,
        name: cate.name,
        name_ar: cate.name_ar
      };
      dispatch(updateCategory(data));
    } else {
      const data = {
        name: cate.name,
        name_ar: cate.name_ar
      };
      dispatch(addCategory(data));
    }
  };

  function showWarning(message) {
    Swal.fire({
      title: "Warning",
      icon: "warning",
      text: message,
      confirmButtonColor: "#3ab1f7",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    });
  }

  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label>Category Name in English <span style={{ color: 'red' }}>*</span></label>
                      <input 
                        type="text" 
                        className="form-control" 
                        placeholder="Category English"  
                        name='name' 
                        value={cate.name} 
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>Category Name in Arabic <span style={{ color: 'red' }}>*</span></label>
                      <input 
                        type="text" 
                        className="form-control" 
                        placeholder="Category Arabic"  
                        name='name_ar' 
                        value={cate.name_ar} 
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className='d-flex justify-content-end '>
                      <button onClick={onSubmit} type="submit" className="btn btn-primary waves-effect waves-light mr-2">
                        Submit
                      </button>
                      <Link to='/category' className="btn btn-secondary waves-effect m-l-5">
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AUX>
  );
};

export default CategoryAdd;

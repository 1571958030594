import React, { useEffect, useState } from "react";
import AUX from "../../../hoc/Aux_";
import { MDBDataTable } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from 'moment';
import Constant from "../../../Constant";
import { setLoading } from "../../../redux/MainAction";
import Swal from "sweetalert2";

var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

const SalesReport = () => {
  const dispatch = useDispatch();
  const [totalRows, setTotalRows] = useState(0);
  const [salesReport, setSalesReport] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [orderTotalSum, setOrderTotalSum] = useState(0);
  const [filterOrderId, setFilterOrderId] = useState("");
  const [filterName, setFilterName] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterVendorName, setFilterVendorName] = useState("");
  const [filterPayMode, setFilterPayMode] = useState("");
  const [filterCouponCode, setFilterCouponCode] = useState("");
  const role = localStorage.getItem("role");
  const vendorId = localStorage.getItem("vendorId");

  const [appliedFilters, setAppliedFilters] = useState({
    filterOrderId: "",
    filterName: "",
    filterStartDate: "",
    filterEndDate: "",
    filterVendorName: "",
    filterPayMode: "",
    filterCouponCode: "",
  });

  useEffect(() => {
    dispatch(setLoading(true));
    fetchPageData(1);
  }, [appliedFilters]);

  const fetchPageData = (pageNum, isExcel) => {
    let setUrl = "";
    let adminUrl = "/seller/adminSalesReport";
    let vendorUrl = "/seller/vendorSalesReport";
    if(role == 'vendor') {
      setUrl = vendorUrl
    } else {
      setUrl = adminUrl
    }
    const DATA = {
      startRange: (pageNum - 1) * perPage,
      count: perPage,
      page: pageNum,
      isExcel: isExcel || false,
      orderId: appliedFilters.filterOrderId || '',
      customerName: appliedFilters.filterName || '',
      startDate: appliedFilters.filterStartDate || '',
      endDate: appliedFilters.filterEndDate || '',
      payMode: appliedFilters.filterPayMode || '',
      couponCode: appliedFilters.filterCouponCode || '',
    };

    if (role === 'admin' && appliedFilters.filterVendorName) {
      DATA.vendorName = appliedFilters.filterVendorName;
    }

    if (role === 'vendor' && vendorId) {
      DATA.vendorId = vendorId;
    }

    axios
      .post(Constant.getAPI() + setUrl, DATA, config)
      .then((res) => {
        if (isExcel) {
          const exportfile = res.data.url;
          const fileName = res.data.fileName;
          const a = document.createElement("a");
          a.href = exportfile;
          a.download = fileName;
          a.click();
        } else {
          setSalesReport(res?.data?.data);
          setOrderTotalSum(res?.data?.data.reduce((sum, itm) => sum + (itm.orderTotal || 0), 0));
          setTotalRows(res.data.count);
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
    setCurrentPage(pageNum);
  };

  const handlePageChange = (pageNum) => {
    if (pageNum < 1 || pageNum > totalPages) return;
    dispatch(setLoading(true));
    fetchPageData(pageNum);
  };

  const handleOrderIdChange = (event) => setFilterOrderId(event.target.value);
  const handleNameChange = (event) => setFilterName(event.target.value);
  const toggleFilterVisibility = () => setShowFilter(!showFilter);
  const handleStartDateChange = (event) => setFilterStartDate(event.target.value);
  const handleEndDateChange = (event) => {
    const newEndDate = new Date(event.target.value);
    const startDate = new Date(filterStartDate);
    if (newEndDate < startDate) {
      Swal.fire({
        title: "Invalid Date Range",
        text: "End date cannot be before the start date.",
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      }).then(() => {
        setFilterEndDate('');
      });
      return;
    }
    setFilterEndDate(event.target.value);
  };
  const handleVendorNameChange = (event) => setFilterVendorName(event.target.value);
  const handlePayModeChange = (event) => setFilterPayMode(event.target.value);
  const handleCouponCodeChange = (event) => setFilterCouponCode(event.target.value);

  const handleApplyFilter = () => {
    setAppliedFilters({
      filterOrderId,
      filterName,
      filterStartDate,
      filterEndDate,
      filterVendorName,
      filterPayMode,
      filterCouponCode
    });
  };

  const exportToExcel = () => {
    fetchPageData(1, true);
  };

  const columns = [
    {
      label: "Date",
      field: "dateTime",
    },
    {
      label: "Invoice No.",
      field: "invoiceNumber",
    },
    {
      label: "User Name",
      field: "userName",
    },
    {
      label: "User Phone No.",
      field: "mobileNumber",
    },
    ...(role !== "vendor"
      ? [
          {
            label: "Vendor Name",
            field: "shopName",
          },
        ]
      : []),
      ...(role !== "vendor"
        ? [
          {
            label: "Coupon Code",
            field: "couponCode",
          },
          ]
        : []),
      {
        label: "Sub Total",
        field: "subTotal",
      },
    ...(role !== "vendor" ? [
      {
        label: "Delivery Fee",
        field: "deliveryCharge",
      },
      {
        label: "Discount Amount",
        field: "discount",
      },
      {
        label: "Total",
        field: "orderTotal",
      },
    ] : []),
    {
      label: "Payment Type",
      field: "paymentMode",
    },
  ];
  
  const rows = salesReport.map((itm) => ({
    dateTime: moment.unix(itm.dateTime).format("DD-MMM-YYYY | hh:mm A"),
    invoiceNumber: itm.invoiceNumber,
    userName: itm.userName,
    mobileNumber: itm.mobileNumber,
    subTotal: itm.subTotal ? itm.subTotal.toFixed(3) : 0,
    discount: itm.discount ? itm.discount.toFixed(3) : 0,
    deliveryCharge: itm.deliveryCharge ? itm.deliveryCharge.toFixed(3) : 0,
    orderTotal: itm.orderTotal ? itm.orderTotal.toFixed(3) : 0,
    paymentMode: itm.paymentMode,
    shopName: itm.shopName,
    couponCode: itm.couponCode,
  }));

  const data = {
    columns,
    rows,
  };
  const totalPages = Math.ceil((totalRows ? totalRows : 0) / perPage);
  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <div>
                    <button
                      onClick={toggleFilterVisibility}
                      style={{
                        marginBottom: "10px",
                        padding: "10px 20px",
                        backgroundColor: "#007bff",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      {showFilter ? "Hide Filters" : "Show Filters"}
                    </button>

                    {showFilter && (
                      <div
                        style={{
                          border: "1px solid #ddd",
                          borderRadius: "4px",
                          padding: "20px",
                          backgroundColor: "#fff",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          marginBottom: "20px",
                        }}
                      >
                        <div style={{ display: "flex", marginBottom: "10px" }}>
                          <div style={{ flex: 1, marginRight: "10px" }}>
                            <label htmlFor="orderId" style={{ display: "block", marginBottom: "5px" }}>
                              Invoice Number
                            </label>
                            <input
                              type="text"
                              id="orderId"
                              value={filterOrderId}
                              onChange={handleOrderIdChange}
                              style={{ width: "100%", padding: "8px", borderRadius: "4px", border: "1px solid #ddd" }}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <label htmlFor="phone" style={{ display: "block", marginBottom: "5px" }}>
                              User Name
                            </label>
                            <input
                              type="text"
                              id="phone"
                              value={filterName}
                              onChange={handleNameChange}
                              style={{ width: "100%", padding: "8px", borderRadius: "4px", border: "1px solid #ddd" }}
                            />
                          </div>
                        </div>

                        <div style={{ display: "flex", marginBottom: "10px" }}>
                          <div style={{ flex: 1, marginRight: "10px" }}>
                            <label htmlFor="startDate" style={{ display: "block", marginBottom: "5px" }}>
                              Start Date
                            </label>
                            <input
                              type="date"
                              id="startDate"
                              value={filterStartDate}
                              onChange={handleStartDateChange}
                              style={{ width: "100%", padding: "8px", borderRadius: "4px", border: "1px solid #ddd" }}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <label htmlFor="endDate" style={{ display: "block", marginBottom: "5px" }}>
                              End Date
                            </label>
                            <input
                              type="date"
                              id="endDate"
                              value={filterEndDate}
                              onChange={handleEndDateChange}
                              style={{ width: "100%", padding: "8px", borderRadius: "4px", border: "1px solid #ddd" }}
                            />
                          </div>
                        </div>

                        {role === 'admin' && (
                          <div style={{ display: "flex", marginBottom: "10px" }}>
                            <div style={{ flex: 1, marginRight: "10px" }}>
                              <label htmlFor="vendorName" style={{ display: "block", marginBottom: "5px" }}>
                                Vendor Name
                              </label>
                              <input
                                type="text"
                                id="vendorName"
                                value={filterVendorName}
                                onChange={handleVendorNameChange}
                                style={{ width: "100%", padding: "8px", borderRadius: "4px", border: "1px solid #ddd" }}
                              />
                            </div>
                            <div style={{ flex: 1 }}>
                              <label htmlFor="payMode" style={{ display: "block", marginBottom: "5px" }}>
                                Payment mode
                              </label>
                              <select
                                id="payMode"
                                value={filterPayMode}
                                onChange={handlePayModeChange}
                                style={{ width: "100%", padding: "8px", borderRadius: "4px", border: "1px solid #ddd" }}
                              >
                                <option value="">Select a payment mode</option>
                                <option value="online">K Net</option>
                                <option value="card">Credit Card</option>
                                <option value="wallet">Wallet</option>
                              </select>
                            </div>
                          </div>
                        )}

                        {role !== "vendor" && (
                          <div style={{ display: "flex", marginBottom: "10px" }}>
                            <div style={{ flex: 1 }}>
                              <label htmlFor="couponCode" style={{ display: "block", marginBottom: "5px" }}>
                                Coupon
                              </label>
                              <input
                                type="text"
                                id="couponCode"
                                value={filterCouponCode}
                                onChange={handleCouponCodeChange}
                                style={{ width: "100%", padding: "8px", borderRadius: "4px", border: "1px solid #ddd" }}
                              />
                            </div>
                            <div style={{ flex: 1 }}></div>
                          </div>
                        )}

                        <button
                          onClick={handleApplyFilter}
                          style={{
                            padding: "10px 20px",
                            backgroundColor: "#28a745",
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          Apply Filters
                        </button>
                      </div>
                    )}
                  </div>
                  <MDBDataTable
                    bordered
                    theadColor="#2632384"
                    paging={false}
                    hover
                    searching={false}
                    entries={20}
                    data={data}
                  />
                  <div className="pagination-controls">
                    <button
                      className="pagination-btn first-btn"
                      onClick={() => handlePageChange(1)}
                      disabled={currentPage === 1}
                    >
                      First
                    </button>
                    <button
                      className="pagination-btn prev-btn"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <span>
                      Page {currentPage} of {totalPages} | Total Sum: {orderTotalSum.toFixed(2)}
                    </span>
                    <button
                      className="pagination-btn next-btn"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                    <button
                      className="pagination-btn last-btn"
                      onClick={() => handlePageChange(totalPages)}
                      disabled={currentPage === totalPages}
                    >
                      Last
                    </button>
                  </div>
                  <div className="export-button">
                    <button onClick={exportToExcel} className="btn btn-primary">
                      Export to Excel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AUX>
  );
};

export default SalesReport;

import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import AUX from "../../../hoc/Aux_";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getProduct } from "../../../redux/MainAction";

const ProductView = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.Zeed.productList);

  useEffect(() => {
    getProductList();
  }, []);

  const getProductList = () => {
    var DATA = {
      startRange: "0",
      count: "1000",
    };
    dispatch(getProduct(DATA));
  };
  const [coup, setCoup] = useState({
    titleEn: "",
    titleAr: "",
    categoryEn: "",
    categoryAr: "",
    shopName: "",
    descriptionEn: "",
    descriptionAr: "",
    quantity: "",
    date: "",
    status: "",
    active: "",
    price: "",
  });

  useEffect(() => {
    if (params.id && productList.length > 0) {
      const spr = productList.find((itm) => itm.id === params.id);
      if (spr) {
        setCoup({
          titleEn: spr.title,
          titleAr: spr.title_ar,
          descriptionEn: spr.about,
          descriptionAr: spr.about_ar,
          categoryEn: spr.Category.name,
          categoryAr: spr.Category.name_ar,
          shopName: spr.shop.shopName,
          price: spr.price,
          quantity: spr.quantity,
          date: moment(spr.updatedAt).format("L"),
          // status: spr.status,
          active: spr.active == true ? 'Yes' : 'No',
        });
      }
    }
  }, [params.id, productList]);


  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <div>
                    <h5>View Product Details</h5>
                    <hr />
                    <div className="row">
                      <div className="form-group col-md-6 sorting">
                       Title in English:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.titleEn}
                      </div>
                      <div className="form-group col-md-6 sorting">
                        Title in Arabic:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.titleAr}
                      </div>
                      <div className="form-group col-md-6 sorting">
                        Description in English:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.descriptionEn}
                      </div>
                      <div className="form-group col-md-6 sorting">
                        Description in Arabic:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.descriptionAr}
                      </div>
                      <div className="form-group col-md-6 sorting">
                        Category in English:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.categoryEn}
                      </div>
                      <div className="form-group col-md-6 sorting">
                        Category in Arabic:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.categoryAr}
                      </div>
                      <div className="form-group col-md-6 sorting">
                        Quantity:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.quantity}
                      </div>
                      <div className="form-group col-md-6 sorting">
                        Price:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.price}
                      </div>
                      {/* <div className="form-group col-md-6 sorting">
                        Status:
                      </div> */}
                      {/* <div className="form-group col-md-6 sorting">
                        {coup.status}
                      </div> */}
                      <div className="form-group col-md-6 sorting">
                        Created At:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.date}
                      </div>
                      <div className="form-group col-md-6 sorting">
                        Active:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.active}
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex justify-content-end">
                        <Link
                          to="/product-Fixed"
                          className="btn btn-secondary waves-effect m-l-5"
                        >
                          Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AUX>
  );

 
};

export default ProductView;

// import React, { useEffect, useState } from 'react'
// import AUX from '../../../hoc/Aux_';
// import { Link  } from 'react-router-dom';
// import { Button } from 'reactstrap';
// import {getallUsers ,SendNotification} from '../../../redux/MainAction'
// import { useDispatch, useSelector } from 'react-redux';
// import Swal from 'sweetalert2';

// const AddPushNotification = () => {
//     const dispatch = useDispatch()
//     const UsList = useSelector(state=>state.Zeed.userList)
  
//     useEffect(()=>{
//       getUser()
//     },[])
  
//     const getUser = ()=>{
//       var DATA={
//         startRange:'0',
//         count:"1000"
//       }
//       dispatch(getallUsers(DATA))
//     }

//     const [roles , setRoles]=useState({
//         type:'',
//         title:'',
//         text:'',
//     })
//     const handleChange = (e) =>{
//         const { value , name } = e.target
//         setRoles({...roles,[name]: value})
//     }

//     const [userId, setUserId] = useState([]);

//     const optionHandleChange = (e, index) => {
//         const updatedOption = userId.map((data, i) => (index === i ? e.target.value : data));
//         setUserId(updatedOption);
//     };

//     const onAddOption = () => {
//         const options = '';
//         setUserId([...userId, options]);
//     };
//     const removeOptions = (index) => {
//         const filteredOptions = [...userId];
//         filteredOptions.splice(index, 1);
//         setUserId(filteredOptions);
//     };


//     const onSubmit = (e)=>{
//         e.preventDefault()
//         if (!userId.length || userId.some(id => !id)) {
//             showWarning("Please Add User.");
//             return false;
//         }
//         var data = {
//             type:roles?.type,
//             text:roles?.text,
//             title:roles?.title,
//             userId:userId,
//         }
//         // console.log("Send",data);
//         dispatch(SendNotification(data))
//     }

//     function showWarning(message) {
//         Swal.fire({
//         title: "Warning",
//         icon: "warning",
//         text: message,
//         confirmButtonColor: "#3ab1f7",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Ok",
//         })
//     }

//   return (
//     <AUX>
//          <div className="page-content-wrapper">
//                  <div className="container-fluid">

//                 <div className="row">
//                     <div className="col-lg-12">
//                         <div className="card m-b-20">
//                             <div className="card-body">
//                                     <form onSubmit={onSubmit}>
//                                         <div className='row'>
//                                             <div className="form-group col-md-6">
//                                                 <label>Notification Type</label>
//                                                 <div className="col-md-13">
//                                                     <select className="form-control"
//                                                         name="type"
//                                                         id="type"
//                                                         onChange={handleChange}
//                                                         value={roles.type}
//                                                         required>

//                                                         <option value=""> - Select Type - </option>
//                                                         <option value="all">All</option>
//                                                         <option value="general">General</option>
//                                                         <option value="orderPlaced">Order Placed</option>
//                                                         <option value="liveStart">Live Start</option>
//                                                         <option value="follow">Follow</option>
//                                                         <option value="postApproved">Post Approved</option>
//                                                         <option value="postLike">Post Like</option>
//                                                         <option value="postComment">Post Comment</option>
//                                                         <option value="commentLike">Comment Like</option>
//                                                         <option value="biddingAlert">Bidding Alert</option>
                                                        
//                                                     </select>
//                                                 </div>
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Notification Title</label>
//                                                 <input type="text" className="form-control" placeholder="Notification Title" name='title' value={roles.title} onChange={handleChange}/>
//                                                 {/* <span id="err">{this.state.name_err}</span> */}
//                                             </div>
//                                             <div className="form-group col-md-6">
//                                                 <label>Notification Text</label>
//                                                 <input type="text" className="form-control" placeholder="Notification Title" name='text' value={roles.text} onChange={handleChange}/>
//                                                 {/* <span id="err">{this.state.name_err}</span> */}
//                                             </div>

//                                             <div className="form-group col-md-12">
//                                                 <Button outline color="blue-grey" onClick={onAddOption}>+ Add option</Button>
//                                             </div>
//                                             <div className='col-md-12 row'>
//                                             {userId?.map((usrID,index)=>{
//                                                 return <div className='row col-md-12 attribt' key={index}>
//                                                     <div className='col-md-8'>
//                                                         <div className='form-group row'>
//                                                             <div className='col-sm-10'>
//                                                             <label>User Name</label>
//                                                             <div className="col-md-13">
//                                                                 <select className="form-control"
//                                                                     name="usrID"
//                                                                     id="type"
//                                                                     onChange={e=> optionHandleChange(e,index)}
//                                                                     value={usrID}
//                                                                     required>

//                                                                     <option value=""> - Select User - </option>
//                                                                     {UsList!==undefined?UsList.map(user=>(
//                                                                         <option value={user.id}>{user.userName}</option>
//                                                                     )):null}
//                                                                 </select>
//                                                             </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>

//                                                     <div className="col-md-2 mt-3">
//                                                         <Button outline color='danger' onClick={()=> removeOptions(index)}>Remove</Button>
//                                                     </div>
//                                                 </div>
//                                             })}
//                                        </div>

//                                         </div>
//                                         <div className="form-group">
//                                             <div className='d-flex justify-content-end '>
//                                                 <button  onClick={"this.handleSubmit1"} type="submit" className="btn btn-primary waves-effect waves-light mr-2">
//                                                     Submit
//                                                 </button>
//                                                 <Link to='/push-notification' className="btn btn-secondary waves-effect m-l-5">
//                                                     Cancel
//                                                 </Link>
//                                             </div>
//                                         </div>
//                                 </form>
//                             </div>
//                         </div>
//                     </div> 

//                 </div> 
//             </div> 
//         </div>
//     </AUX>
//   )
// }

// export default AddPushNotification



import React, { useEffect, useState } from "react";
import AUX from "../../../hoc/Aux_";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { getallUsers, SendNotification } from "../../../redux/MainAction";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Select from "react-select";

const AddPushNotification = () => {
  const dispatch = useDispatch();
  const UsList = useSelector((state) => state.Zeed.userList);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    var DATA = {
      startRange: "0",
      count: "1000",
    };
    dispatch(getallUsers(DATA));
  };

  const [roles, setRoles] = useState({
    type: "",
    title: "",
    text: "",
  });

  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setRoles({ ...roles, [name]: value });
  };

  const handleUserChange = (selectedOptions) => {
    setSelectedUsers(selectedOptions);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!selectedUsers.length) {
      showWarning("Please select at least one user.");
      return false;
    }
    const userId = selectedUsers.map((user) => user.value);
    var data = {
      type: roles?.type,
      text: roles?.text,
      title: roles?.title,
      userId: userId,
    };
    dispatch(SendNotification(data));
  };

  function showWarning(message) {
    Swal.fire({
      title: "Warning",
      icon: "warning",
      text: message,
      confirmButtonColor: "#3ab1f7",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    });
  }

  const userOptions = UsList
    ? UsList.map((user) => ({
        value: user.id,
        label: user.userName,
      }))
    : [];

  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <form onSubmit={onSubmit}>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>Notification Type</label>
                        <div className="col-md-13">
                          <select
                            className="form-control"
                            name="type"
                            id="type"
                            onChange={handleChange}
                            value={roles.type}
                            required
                          >
                            <option value=""> - Select Type - </option>
                            <option value="all">All</option>
                            <option value="general">General</option>
                            <option value="orderPlaced">Order Placed</option>
                            <option value="liveStart">Live Start</option>
                            <option value="follow">Follow</option>
                            <option value="postApproved">Post Approved</option>
                            <option value="postLike">Post Like</option>
                            <option value="postComment">Post Comment</option>
                            <option value="commentLike">Comment Like</option>
                            <option value="biddingAlert">Bidding Alert</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label>Notification Title</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Notification Title"
                          name="title"
                          value={roles.title}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Notification Text</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Notification Text"
                          name="text"
                          value={roles.text}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label>Select Users<span style={{ color: 'red' }}>*</span></label>
                        <Select
                          isMulti
                          options={userOptions}
                          onChange={handleUserChange}
                          value={selectedUsers}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex justify-content-end ">
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light mr-2"
                        >
                          Submit
                        </button>
                        <Link
                          to="/push-notification"
                          className="btn btn-secondary waves-effect m-l-5"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AUX>
  );
};

export default AddPushNotification;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AUX from "../../../hoc/Aux_";
import { deleteDriver, getDriverList, updateDriverStatus } from "../../../redux/MainAction";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Button } from "reactstrap";
import Toggle from 'react-toggle';

const DriverList = () => {
    const dispatch = useDispatch();
    const driveList = useSelector((state) => state.Zeed.driveList);

    useEffect(() => {
        getRolList();
    }, []);

    const getRolList = () => {
        var DATA = {
            startRange: "0",
            count: "1000",
        };
        dispatch(getDriverList(DATA));
    };

    const handleToggleChange = (id, active) => {
        var data = {
            id: id,
            active: active,
        }
        dispatch(updateDriverStatus(data));
    }

    const handleDelete = (id) => {
        const data = { driverId: id };
        dispatch(deleteDriver(data));
      };

    const data = {
        columns: [
            {
                label: "First Name",
                field: "firstName",
            },
            {
                label: "Last Name",
                field: "lastName",
            },
            {
                label: "Mobile Number",
                field: "mobileNumber",
            },
            {
                label: "Status",
                field: "active",
            },
            {
                label: "Action",
                field: "id",
            },
        ],
        rows: driveList.map((itm) => {
            return {
                firstName: itm.firstName,
                lastName: itm.lastName,
                mobileNumber: itm.mobileNumber,
                active: <Toggle defaultChecked={itm.isActive} value={itm.isActive} onChange={(e) => handleToggleChange(itm.id, e.target.checked)} />,
                id: (
                    <>
                        <Link
                            to={"/driver/edit/" + itm.id}
                            title="Edit"
                            className="mdi mdi-grease-pencil"
                        ></Link>{" "}
              <i onClick={() => handleDelete(itm?.id)} title="Delete" className="mdi mdi-delete D_BTN"></i>

                    </>
                ),
            };
        }),
    };

    return (
        <AUX>
            <div className="page-content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <div className="d-flex justify-content-end">
                                        <Link to="/driver/add">
                                            <Button size="sm" color="secondary" onClick>
                                                Add Driver
                                            </Button>
                                        </Link>
                                    </div>
                                    <MDBDataTable bordered hover data={data} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AUX>
    );
};

export default DriverList;
import React, { useEffect } from 'react'
import { MDBDataTable } from 'mdbreact';
import AUX from '../../../hoc/Aux_';
import {getWinners} from "../../../redux/MainAction"
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const AuctionResult = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const winnerList = useSelector(state=>state.Zeed.winner)

    useEffect(()=>{
        getRolList()
    },[])

    const getRolList = ()=>{
        var DATA={
           postId:params?.id
        }
        dispatch(getWinners(DATA))
    }
    const data ={
        columns:[
            {
                label: "Result",
                field: "isWinner", 
            },
            {
                label: "User Name",
                field: "userName", 
            },
            {
                label: "First Name",
                field: "firstName", 
            },
            {
                label: "Last Name",
                field: "lastName", 
            },
            {
                label: "Mobile",
                field: "mobileNumber", 
            },
            {
                label: "Email",
                field: "email", 
            },
        ],
        rows: winnerList?.map((Item)=>{return {
            isWinner: <span style={Item?.isWinner?{color:'green'}:{color:'red'}}><b>{ Item.isWinner?"WINNER":"LOSE"}</b></span>,
            userName: Item?.User?.userName,
            firstName: Item?.User.firstName,
            lastName: Item?.User.lastName,
            mobileNumber: Item?.User.mobileNumber,
            email: Item?.User.email,
        }})
    }
  return (
    <AUX>
    <div className="page-content-wrapper">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card m-b-20">
                        <div className="card-body">
                            <MDBDataTable
                                bordered
                                hover
                                data={data}
                            />
                        </div>
                    </div>
                </div>
            </div> 
        </div> 
    </div>
    </AUX>
  )
}

export default AuctionResult
import React, { useEffect, useState } from "react";
import AUX from "../../../hoc/Aux_";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addSubCategory,
  getCategoryDropdown,
  getSellerDropdown,
  updateSubCategory,
} from "../../../redux/MainAction";
import Swal from "sweetalert2";

const SubCategoryAdd = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const sellersList = useSelector((state) => state.Zeed.sellersList);
  const categoryList = useSelector((state) => state.Zeed.category);

  const [cate, setCate] = useState({
    nameEn: "",
    nameAr: "",
    sellerId: "",
    categoryId: "",
  });
  const [langId, setLangId] = useState("en");
  const subCategory = useSelector((state) => state.Zeed.subCategory);

  const handleLang = (e) => {
    setLangId(e.target.id);
  };

  const getRolList = () => {
    const DATA = {
      startRange: "0",
      count: "1000",
    };
    dispatch(getCategoryDropdown(DATA));
    dispatch(getSellerDropdown(DATA));
  };

  useEffect(() => {
    getRolList();
  }, []);

  useEffect(() => {
    if (params.id && langId) {
      const spr = subCategory.filter((itm) => itm.id === params.id);
      setCate({
        nameEn: spr[0]?.nameEn,
        nameAr: spr[0]?.nameAr,
        categoryId: spr[0]?.CategoryId,
        sellerId: spr[0]?.SellerId,
      });
    }
  }, [params.id, langId]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setCate({ ...cate, [name]: value });
  };

  const onSubmit = (e) => {
    if (!cate?.nameEn) {
      showWarning("Please Add Name in English");
      return false;
    }
    if (!cate?.nameAr) {
      showWarning("Please Add Name in Arabic");
      return false;
    }
    if (!cate?.sellerId) {
      showWarning("Please Select Seller");
      return false;
    }
    if (!cate?.categoryId) {
      showWarning("Please Select Category");
      return false;
    }
    e.preventDefault();
    if (params.id) {
      const data = {
        nameEn: cate?.nameEn,
        nameAr: cate?.nameAr,
        sellerId: cate?.sellerId,
        categoryId: cate?.categoryId,
        subCategoryId: params.id,
      };
      dispatch(updateSubCategory(data));
    } else {
      const data = {
        nameEn: cate?.nameEn,
        nameAr: cate?.nameAr,
        sellerId: cate?.sellerId,
        categoryId: cate?.categoryId,
      };
      dispatch(addSubCategory(data));
    }
  };

  function showWarning(message) {
    Swal.fire({
      title: "Warning",
      icon: "warning",
      text: message,
      confirmButtonColor: "#3ab1f7",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    });
  }

  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <ul
                    className="nav nav-tabs"
                    role="tablist"
                    onClick={handleLang}
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#en"
                        role="tab"
                      >
                        <span id="en" className="d-none d-md-block">
                          Sub Category
                        </span>
                        <span className="d-block d-md-none">
                          <i className="mdi mdi-home-variant h5"></i>
                        </span>
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content mt-3">
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label>Sub Category English <span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Sub Category English"
                          name="nameEn"
                          value={cate.nameEn}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <label>Sub Category Arabic <span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Sub Category Arabic"
                          name="nameAr"
                          value={cate.nameAr}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Seller <span style={{ color: 'red' }}>*</span></label>
                        <div className="col-md-13">
                          <select
                            className="form-control"
                            name="sellerId"
                            value={cate.sellerId ? cate.sellerId : ""}
                            onChange={handleChange}
                          >
                            <option value="" disabled>
                              Select a seller
                            </option>
                            {sellersList && sellersList.length > 0
                              ? sellersList.map((itm) => (
                                  <option value={itm?.id} key={itm.id}>
                                    {itm.userName}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label>Category <span style={{ color: 'red' }}>*</span></label>
                        <div className="col-md-13">
                          <select
                            className="form-control"
                            name="categoryId"
                            value={cate.categoryId ? cate.categoryId : ""}
                            onChange={handleChange}
                          >
                            <option value="" disabled>
                              Select a category
                            </option>
                            {categoryList && categoryList.length > 0
                              ? categoryList.map((itm) => (
                                <option value={itm.id} key={itm.id}>
                                  {itm.name}
                                </option>
                              ))
                              : null}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="d-flex justify-content-end ">
                      <button
                        onClick={onSubmit}
                        type="submit"
                        className="btn btn-primary waves-effect waves-light mr-2"
                      >
                        Submit
                      </button>
                      <Link
                        to="/sub-category"
                        className="btn btn-secondary waves-effect m-l-5"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AUX>
  );
};

export default SubCategoryAdd;

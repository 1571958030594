import React, { useEffect, useState } from 'react';
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import { getallUsers, setLoading, updateUserStatus, updateUserVerified } from '../../../redux/MainAction';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import Toggle from 'react-toggle';
import { GET_ALL_USER } from '../../../redux/type';
import Axios from 'axios';
import Constant from '../../../Constant';
import Swal from 'sweetalert2';
var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

const Users = () => {
  const dispatch = useDispatch();
  const UsList = useSelector(state => state.Zeed.userList);
  const location = useLocation();
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [signUpParam, setActiveParam] = useState('');


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const signup = queryParams.get('signup') || '';
    dispatch(setLoading(true));
    setActiveParam(signup);
    fetchPageData(1, signup);
    // getUser();
  }, [location.search]);

  // const getUser = () => {
  //   var DATA = {
  //     startRange: '0',
  //     count: '1000'
  //   };
  //   dispatch(getallUsers(DATA));
  // };

  const fetchPageData = (pageNum, signUpParam, otherParams = "") => {
    const DATA = {
      startRange: (pageNum - 1) * perPage,
      count: perPage,
      page: pageNum,
      signup: signUpParam,
      keyword: otherParams
    };
    Axios
      .post(Constant.getAPI() + "/user/backend-getAll", DATA, config)
      .then((res) => {
        dispatch({
          type: GET_ALL_USER,
          payload: res.data.data,
          totalRows: res.data.totalRows,
        });
        setTotalRows(res.data.totalRows);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
    setCurrentPage(pageNum);
  };

  const handlePageChange = (pageNum) => {
    dispatch(setLoading(true));
    fetchPageData(pageNum, signUpParam);

  };

  const handleToggleChange = (id, active) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to change the user status",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          id: id,
          active: active,
        };
        dispatch(updateUserStatus(data));
        Swal.fire({
          title: "User status Updated",
          type: "success",
          imageHeight: 100,
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      } else {
        Swal.fire(
          'Cancelled',
          'The user status remains unchanged.',
          'error'
        ).then(() => {
          window.location.reload();
        });
      }
    });
  };

  const handleVerifiedToggleChange = (id, isVerified) => {
    var data = {
      id: id,
      isVerified: isVerified,
    };
    dispatch(updateUserVerified(data));
  };

  const filteredUsList = UsList.filter(user => {
    if (!signUpParam) return true;
    if (signUpParam == 'web' && user.deviceType === 'web') {
      return true;
    }
    if (signUpParam == 'iOS' && user.deviceType === 'iOS') {
      return true;
    }
  });


  const data = {
    columns: [
      { label: 'UserName', field: 'userName' },
      { label: 'Name', field: 'firstName' },
      { label: 'Mobile No', field: 'mobileNumber' },
      { label: 'Email', field: 'email' },
      { field: 'deviceType', label: 'Sign up with' },
      { field: 'created', label: 'Registered Date' },
      { label: 'Status', field: 'status' },
      { label: 'Verified', field: 'isVerified' },
      { label: 'Action', field: 'id' }
    ],
    rows: filteredUsList.map(Item => ({
      userName: Item.userName,
      firstName: Item.firstName,
      mobileNumber: Item.mobileNumber,
      email: Item.email,
      deviceType: Item.deviceType,
      created: moment(Item.created).format('L'),
      status: <Toggle defaultChecked={Item.status} value={Item.status} onChange={(e) => handleToggleChange(Item.id, e.target.checked)} />,
      isVerified: <Toggle defaultChecked={Item.isVerified} value={Item.isVerified} onChange={(e) => handleVerifiedToggleChange(Item.id, e.target.checked)} />,
      id: (
        <div>
          <Link to={`/users/edit/${Item.id}`} title="Edit" className="mdi mdi-grease-pencil"></Link>
          <Link to={`/users/view/${Item?.id}`} title="View" className="mdi mdi-eye"></Link>
        </div>
      ),
    }))
  };
  const [searchTerm, setSearchTerm] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const id = setTimeout(() => {
      dispatch(setLoading(true));
      fetchPageData(currentPage, signUpParam, searchTerm);
    }, 1000);

    setTimeoutId(id);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [searchTerm]);
  const totalPages = Math.ceil((totalRows ? totalRows : 0) / perPage);

  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <div data-test="datatable-input" style={{ display:"flex",justifyContent:"flex-end" }}>
                    <input type="text" className="form-control form-control-sm ml-0 my-1" placeholder="Search by" aria-label="Search"
                      value={searchTerm} style={{ width: "30%" }}
                      onChange={(e) => setSearchTerm(e.target.value)}>
                    </input>
                  </div>
                  <MDBDataTable
                    bordered
                    searching={false}
                    hover
                    data={data}
                    theadColor="#2632384"
                    paging={false}
                    entries={20}
                  />
                  <div className="pagination-controls">
                    <button
                      className="pagination-btn first-btn"
                      onClick={() => handlePageChange(1)}
                      disabled={currentPage === 1}
                    >
                      First
                    </button>
                    <button
                      className="pagination-btn prev-btn"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <span>
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      className="pagination-btn next-btn"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                    <button
                      className="pagination-btn last-btn"
                      onClick={() => handlePageChange(totalPages)}
                      disabled={currentPage === totalPages}
                    >
                      Last
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{`
        .pagination-controls {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 20px;
        }
        select{
          width: 27%;
        }
        .pagination-btn {
          background-color: #263238;
          color: white;
          border: none;
          padding: 10px 20px;
          margin: 0 5px;
          cursor: pointer;
          border-radius: 5px;
          font-size: 14px;
        }
        
        .pagination-btn:disabled {
          background-color: #cccccc;
          cursor: not-allowed;
        }
        
        .pagination-btn:not(:disabled):hover {
          background-color: #263233;
        }
      `}</style>
    </AUX>
  );
};

export default Users;


import React, { useEffect, useState } from 'react'
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import { Link, useLocation } from 'react-router-dom';
import {deleteProduct, getProduct, updateProductStatus } from '../../../redux/MainAction'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Toggle from 'react-toggle';
import { Button } from 'reactstrap';
import axios from "axios";
import { GET_PRODUCTS } from "../../../redux/type";
import Constant from "../../../Constant";
import { setLoading } from "../../../redux/MainAction";


var config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("loged_In_auth"),
    },
};

const Fixed = () => {
    const dispatch = useDispatch()
    const fixedList = useSelector(state=>state.Zeed.productList)
    const location = useLocation();
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [activeParam, setActiveParam] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filterTitle, setFilterTitle] = useState("");
    const [filterCategoryName, setFilterCategoryName] = useState("");
    const [filterVendorName, setFilterVendorName] = useState("");
    const [filterPrice, setFilterPrice] = useState("");
    const [filterQuantity, setFilterQuantity] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [showFilter, setShowFilter] = useState(false);

    const [appliedFilters, setAppliedFilters] = useState({
        filterTitle: "",
        filterCategoryName: "",
        filterVendorName: "",
        filterPrice: "",
        filterQuantity: "",
        filterStatus: "",
      });

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const active = queryParams.get('active') || '';
        dispatch(setLoading(true));
        setActiveParam(active);
        fetchPageData(1, active);
    }, [location.search,searchQuery,appliedFilters]);
    
    const fetchPageData = (pageNum,activeParam) => {
        const DATA = {
            startRange: (pageNum - 1) * perPage,
            count: perPage,
            page: pageNum,
            isActive:activeParam,
            search: searchQuery,
            title: appliedFilters.filterTitle || '',
            categoryName: appliedFilters.filterCategoryName || '',
            vendorName: appliedFilters.filterVendorName || '',
            price: appliedFilters.filterPrice || '',
            quantity: appliedFilters.filterQuantity || '',
            status: appliedFilters.filterStatus || '',
        };
        axios
            .post(Constant.getAPI() + "/admin/backend-getallpost", DATA, config)
            .then((res) => {
                // window.setLoading(true)
                dispatch({
                    type: GET_PRODUCTS,
                    payload: res.data.data,
                    totalRows: res.data.totalRows
                });
                setTotalRows(res.data.totalRows)
                dispatch(setLoading(false));
            })
            .catch((err) => {
                console.log(err)
                dispatch(setLoading(false))
            }
            );
        setCurrentPage(pageNum);
    };

    const handleTitleChange = (event) => setFilterTitle(event.target.value);
    const handleCategoryNameChange = (event) => setFilterCategoryName(event.target.value);
    const handleVendorNameChange = (event) => setFilterVendorName(event.target.value);
    const handlePriceChange = (event) => setFilterPrice(event.target.value);
    const handleQuantityChange = (event) => setFilterQuantity(event.target.value);
    const handleStatusChange = (event) => setFilterStatus(event.target.value);
    const toggleFilterVisibility = () => setShowFilter(!showFilter);

    const handleApplyFilter = () => {
        setAppliedFilters({
            filterTitle,
            filterCategoryName,
            filterVendorName,
            filterPrice,
            filterQuantity,
            filterStatus,
        });
    };

    const handlePageChange = (pageNum) => {
        dispatch(setLoading(true));
        fetchPageData(pageNum, activeParam);
    };
    
    const handlePerPageChange = (event) => {
        const perPageValue = Number(event.target.value);
        setPerPage(perPageValue);
        fetchPageData(1, activeParam);
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        fetchPageData(1);
    };

    // const getRolList = ()=>{
    //     var DATA={
    //         startRange: '0',
    //         count: "10000",
    //         type: "Fixed",
    //         all: "1"
    //     }
    //     dispatch(getProduct(DATA))
    // }

    const handleToggleChange = (id, active) => {
        var data = {
            id: id,
            active: active,
        }
        // console.log(data);
        dispatch(updateProductStatus(data));
    }

    const handleDelete = (id) => {
        const data = { postId: id };
        dispatch(deleteProduct(data));
      };

    const filteredUsList = fixedList.filter(post => {
        if (!activeParam) return true;
        return activeParam === '1' ? post.active : !post.active;
    });

    const columns = [
            {
                label: "Image",
                field: "Media", 
            },
            {
                label: "Title: EN",
                field: "title", 
            },
            {
                label: "Title: Ar",
                field: "title_ar", 
            },
            // {
            //     label: "Participants",
            //     field: "biddersCount", 
            // },
            {
                label: "Category Name: Eng",
                field: "Category", 
            },
            {
                label: "Category Name: Ar",
                field: "Category_ar", 
            },
            {
                label: "Shop Name",
                field: "shop", 
            },
            // {
            //     label: "Description in English",
            //     field: "about", 
            // },
            // {
            //     label: "Description in Arabic",
            //     field: "about_ar", 
            // },
            {
                label: "Price",
                field: "price", 
            },
            {
                label: "Quantity",
                field: "quantity", 
            },
            {
                label: "Variants",
                field: "Variants", 
            },
            {
                label: "Date",
                field: "createdAt", 
            },
            {
                label: "Status",
                field: "active", 
            },
            {
                label: "Action",
                field: "id", 
            },
    ];

    const rows = filteredUsList?.map((Item)=>{return {
        Media: <img src={Item?.Media?.[Item.Media.length - 1]?.url} height={50} />,
        title: Item?.title,
        title_ar: Item?.title_ar,
        // biddersCount: <Link to={`/product/AuctionParticipants/${Item?.id}`} className="mdi mdi-eye"></Link>,
        Category: Item?.Category?.name,
        Category_ar: Item?.Category?.name_ar,
        shop: Item?.shop?.shopName,
        quantity: Item?.quantity,
        price: Item?.price,
        about: Item?.about,
        about_ar: Item?.about_ar,
        Variants: Item?.Variants?.length ? <Link to={`/variant-list/${Item?.id}`} className="mdi mdi-eye"></Link> : "",
        createdAt: moment(Item?.createdAt*1000).format("L"),
        // status:Item?.status,
        active:<Toggle defaultChecked={Item.active} value={Item.active} onChange={(e) => handleToggleChange(Item.id, e.target.checked)} />,
        id: (
            <div>
             <Link to={"/product-Fixed/edit/" + Item.id} title="Edit" className="mdi mdi-grease-pencil"></Link>
              <Link to={`/product-Fixed/view/${Item?.id}`} title="View" className="mdi mdi-eye"></Link>
              <i onClick={() => handleDelete(Item?.id)} title="Delete" className="mdi mdi-delete D_BTN"></i>
            </div>
        ),
    }})

    const data = {
        columns,
        rows,
      };
    const totalPages = Math.ceil((totalRows ? totalRows : 0) / perPage);

    // console.log("fixedList",fixedList);
  return (
    <AUX>
        <div className="page-content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card m-b-20">
                            <div className="card-body">
                                  <div>
                                      <button
                                          onClick={toggleFilterVisibility}
                                          style={{
                                              marginBottom: "10px",
                                              padding: "10px 20px",
                                              backgroundColor: "#007bff",
                                              color: "#fff",
                                              border: "none",
                                              borderRadius: "4px",
                                              cursor: "pointer",
                                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                          }}
                                      >
                                          {showFilter ? "Hide Filters" : "Show Filters"}
                                      </button>

                                      {showFilter && (
                                          <div
                                              style={{
                                                  border: "1px solid #ddd",
                                                  borderRadius: "4px",
                                                  padding: "20px",
                                                  backgroundColor: "#fff",
                                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                  marginBottom: "20px",
                                              }}
                                          >
                                              <div style={{ display: "flex", marginBottom: "10px" }}>
                                                  <div style={{ flex: 1, marginRight: "10px" }}>
                                                      <label htmlFor="title" style={{ display: "block", marginBottom: "5px" }}>
                                                          Title
                                                      </label>
                                                      <input
                                                          type="text"
                                                          id="title"
                                                          value={filterTitle}
                                                          onChange={handleTitleChange}
                                                          style={{ width: "100%", padding: "8px", borderRadius: "4px", border: "1px solid #ddd" }}
                                                      />
                                                  </div>
                                                  <div style={{ flex: 1 }}>
                                                      <label htmlFor="categoryName" style={{ display: "block", marginBottom: "5px" }}>
                                                          Category Name
                                                      </label>
                                                      <input
                                                          type="text"
                                                          id="categoryName"
                                                          value={filterCategoryName}
                                                          onChange={handleCategoryNameChange}
                                                          style={{ width: "100%", padding: "8px", borderRadius: "4px", border: "1px solid #ddd" }}
                                                      />
                                                  </div>
                                              </div>

                                              <div style={{ display: "flex", marginBottom: "10px" }}>
                                                  <div style={{ flex: 1, marginRight: "10px" }}>
                                                      <label htmlFor="vendorName" style={{ display: "block", marginBottom: "5px" }}>
                                                          Vendor Name
                                                      </label>
                                                      <input
                                                          type="text"
                                                          id="vendorName"
                                                          value={filterVendorName}
                                                          onChange={handleVendorNameChange}
                                                          style={{ width: "100%", padding: "8px", borderRadius: "4px", border: "1px solid #ddd" }}
                                                      />
                                                  </div>
                                                  <div style={{ flex: 1 }}>
                                                      <label htmlFor="price" style={{ display: "block", marginBottom: "5px" }}>
                                                          Price
                                                      </label>
                                                      <input
                                                          type="text"
                                                          id="price"
                                                          value={filterPrice}
                                                          onChange={handlePriceChange}
                                                          style={{ width: "100%", padding: "8px", borderRadius: "4px", border: "1px solid #ddd" }}
                                                      />
                                                  </div>
                                              </div>

                                              <div style={{ display: "flex", marginBottom: "10px", width: "50%" }}>
                                                  <div style={{ flex: 1, marginRight: "10px" }}>
                                                      <label htmlFor="quantity" style={{ display: "block", marginBottom: "5px" }}>
                                                          Quantity
                                                      </label>
                                                      <input
                                                          type="text"
                                                          id="quantity"
                                                          value={filterQuantity}
                                                          onChange={handleQuantityChange}
                                                          style={{ width: "100%", padding: "8px", borderRadius: "4px", border: "1px solid #ddd" }}
                                                      />
                                                  </div>
                                              </div>
                                              
                                              <button
                                                  onClick={handleApplyFilter}
                                                  style={{
                                                      padding: "10px 20px",
                                                      backgroundColor: "#28a745",
                                                      color: "#fff",
                                                      border: "none",
                                                      borderRadius: "4px",
                                                      cursor: "pointer",
                                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                  }}
                                              >
                                                  Apply Filters
                                              </button>
                                          </div>
                                      )}
                                  </div>
                                <div className='d-flex justify-content-end mb-2'>
                                    <Link to='/product-Fixed/add'>
                                        <Button size="sm" color="secondary" onClick >Add Product</Button>
                                    </Link>
                                </div>
                                  {/* <div className="d-flex justify-content-end m-t-5 m-b-5">
                                      <input
                                          type="text"
                                          placeholder="Search..."
                                          value={searchQuery}
                                          onChange={handleSearch}
                                          className="form-control"
                                          style={{
                                              width: '200px',
                                              height: 'calc(1.5em + .5rem + 2px)',
                                              padding: '.25rem .5rem',
                                              fontSize: '.875rem',
                                              lineHeight: '1.5',
                                              borderRadius: '.2rem'
                                          }}
                                      />
                                  </div> */}
                                <MDBDataTable
                                    bordered
                                    theadColor="#2632384"
                                    paging={false}
                                    hover
                                    entries={20}
                                    data={data}
                                    searching={false}
                                />
                                <div className="pagination-controls">
                                      <button className="pagination-btn first-btn" onClick={() => handlePageChange(1)} disabled={currentPage === 1}>First</button>
                                      <button className="pagination-btn prev-btn" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                      <span>Page {currentPage} of {totalPages}</span>
                                      <button className="pagination-btn next-btn" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                                      <button className="pagination-btn last-btn" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>Last</button>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div> 
        </div>
    </AUX>
  )
}

export default Fixed

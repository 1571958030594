import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getRole } from "../../redux/MainAction";
import $ from "jquery";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Axios from 'axios';
import Constant from '../../Constant';

var config = {
   headers: {
       "Content-Type": "application/json",
       Authorization: localStorage.getItem("loged_In_auth"),
   },
};
class VendorSidebar extends Component {
  state = {
    rolesList: [],
    vendor: "",
    role: "",
    allOrderCount:0,
    atStore:0,
    readyForPickup:0,
    pending: 0,
    accepted: 0,
    enRouteWarehouse: 0,
    assignedDriverReadyForPickup: 0,
    unassignedDriverReadyForPickup: 0,
  };

  componentWillMount() {
    this.props.getRole();
    this.state.role =
      localStorage.getItem("role") == "vendor" ? "vendor" : "admin";
  }

  componentWillReceiveProps(nextProps) {
    var RlsID =
      localStorage.getItem("RoleId") === "null"
        ? "all"
        : localStorage.getItem("RoleId");
    //
    const DATA = {};
    Axios
      .post(Constant.getAPI() + "/dashboard/sideBarVendorOrderBadges", DATA, config)
      .then((res) => {
        this.state.allOrderCount = res.data.all;
        this.state.atStore = res.data.atStore;
        this.state.readyForPickup = res.data.readyForPickup;
        this.state.pending = res.data.pending;
        this.state.accepted = res.data.accepted;
        this.state.enRouteWarehouse = res.data.enRouteWarehouse;
        this.state.assignedDriverReadyForPickup = res.data.assignedDriverReadyForPickup;
        this.state.unassignedDriverReadyForPickup = res.data.unassignedDriverReadyForPickup;
      })
      .catch((err) => {
        console.log(err)
      }
      );
    //
    // console.log("RlsID", RlsID);
    var data = nextProps.Zeed.roles;
    if (RlsID !== "all") {
      if (data?.length > 0) {
        var fil = data?.filter(
          (itm) => itm.id === localStorage.getItem("RoleId")
        );
        this.setState({ rolesList: fil[0]?.modules });
        // console.log("nextProps", fil[0]?.modules);
      }
    } else {
      this.setState({ vendor: true });
    }
    // console.log("data",data);/
  }

  constructor(props) {
    super(props);
    this.state = {
      Tab: "index",
      SubTab: "",
      MoreTab: "",
      dashboard_menu: false,
      email_menu: false,
      ui_menu: false,
      form_menu: false,
      chart_menu: false,
      table_menu: false,
      icon_menu: false,
      map_menu: false,
      auth_menu: false,
      extra_menu: false,
      eco_menu: false,
      emt_menu: false,
      user_menu: false,
      roles_menu: false,
      transferRequest: false,
      banks: false,
      category: false,
      orders: false,
      master: false,
      package: false,
      reportedPosts: false,
      location_menu: false,
      about: false,
      privacy: false,
      tc: false,
      contactUs: false,
      product: false,
      attribute: false,
      push: false,
      vendorOder: false,
    };
  }

  setActiveTab = (tab, subtab, moretab, toggleTab, e) => {
    this.setState({ Tab: tab, SubTab: subtab, MoreTab: moretab });
    // console.log("TT", tab,subtab,moretab,toggleTab);
  };

  componentDidMount() {
    var now_route = "";
    var pageUrl = window.location.pathname.split(/[?#]/)[0];
    now_route = pageUrl.substr(1).replace(/_/g, " ");
    $("#now_routing").empty();
    if (now_route == "") {
      now_route = "Dashboard1";
    } else {
    }
    $("#now_routing").append(now_route);
    $(".toggle-search").on("click", function() {
      var targetId = $(this).data("target");
      var $searchBar;
      if (targetId) {
        $searchBar = $(targetId);
        $searchBar.toggleClass("open");
      }
    });
    $(".button-menu-mobile").on("click", function(event) {
      event.preventDefault();
      $("body").toggleClass("enlarged");
    });
    $("li.has_sub li").on("click", function(event) {
      $("body").toggleClass("enlarged");
    });
  }

  componentDidUpdate() {
    var now_route = "";
    var pageUrl = window.location.pathname.split(/[?#]/)[0];
    now_route = pageUrl.substr(1).replace("_", " ");
    $("#now_routing").empty();
    if (now_route == "") {
      now_route = "Dashboard1";
    } else {
    }
    $("#now_routing").append(now_route);
  }

  render() {
    // const { roleList } = this.props;
    // console.log("roleList ",this?.state?.rolesList);
    console.log("ROLE: ", this.state.role);
    return (
      <div className="left side-menu">
        <div className="topbar-left">
          <div className="">
            <Link to="/" className="logo">
              <img src="assets/images/newlogo.png" height="45" alt="logo" />
            </Link>
          </div>
        </div>
        <div className="sidebar-inner slimscrollleft">
          <PerfectScrollbar>
            <div id="sidebar-menu">
              <ul>
                <li className="menu-title">Main</li>
                <li
                  className={
                    this.state.dashboard_menu
                      ? "has_sub active-menu nav-active"
                      : "has_sub"
                  }
                  id="02cde0e3-1eae-4379-91ed-2cc0316670c5"
                >
                  <Link
                    to="/vendor-dashboard"
                    onClick={() =>
                      this.setState({
                        dashboard_menu: !this.state.dashboard_menu,
                        email_menu: false,
                        ui_menu: false,
                        form_menu: false,
                        chart_menu: false,
                        table_menu: false,
                        icon_menu: false,
                        map_menu: false,
                        auth_menu: false,
                        extra_menu: false,
                        eco_menu: false,
                        emt_menu: false,
                      })
                    }
                    className={
                      this.state.Tab === "index"
                        ? "waves-effect active-menu"
                        : "waves-effect"
                    }
                  >
                    <i className="mdi mdi-view-dashboard"></i>{" "}
                    <span> Dashboard</span>{" "}
                  </Link>
                  <ul
                    className="list-unstyled"
                    style={{
                      display: this.state.dashboard_menu ? "block" : "none",
                    }}
                  >
                    {/* <li><Link className={this.state.SubTab === 'dashboard1' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'index','dashboard1','')}  to="/">Dashboard One</Link></li>
                            <li><Link className={this.state.SubTab === 'dashboard2' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'index','dashboard2','')}   to="/dashboard2">Dashboard Two</Link></li>
                            <li><Link className={this.state.SubTab === 'dashboard' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'index','dashboard','')}   to="/dashboard">Analysis</Link></li> */}
                  </ul>
                </li>
                <li
                  className={
                    this.state.product
                      ? "has_sub active-menu nav-active"
                      : "has_sub"
                  }
                  id="a87ec3a0-83a8-4441-9813-e53faaaa951e"
                >
                  <Link
                    to="#"
                    onClick={() =>
                      this.setState({
                        product: !this.state.product,
                        dashboard_menu: false,
                        email_menu: false,
                        ui_menu: false,
                        form_menu: false,
                        chart_menu: false,
                        table_menu: false,
                        icon_menu: false,
                        map_menu: false,
                        auth_menu: false,
                        extra_menu: false,
                        eco_menu: false,
                        emt_menu: false,
                      })
                    }
                    className={
                      this.state.Tab === "master"
                        ? "waves-effect active-menu"
                        : "waves-effect"
                    }
                  >
                    <i className="mdi mdi-cube-outline"></i>{" "}
                    <span>
                      {" "}
                      Products{" "}
                      <span className="pull-right">
                        <i className="mdi mdi-chevron-right"></i>
                      </span>
                    </span>{" "}
                  </Link>
                  <ul
                    className="list-unstyled"
                    style={{ display: this.state.product ? "block" : "none" }}
                  >
                    <li>
                      <Link
                        className={
                          this.state.SubTab === "Fixed" ? "active-menu" : ""
                        }
                        onClick={this.setActiveTab.bind(this, "Fixed", "")}
                        to="/product-Fixed"
                      >
                        Fixed
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          this.state.SubTab === "Product Upload"
                            ? "active-menu"
                            : ""
                        }
                        onClick={this.setActiveTab.bind(
                          this,
                          "Product Upload",
                          ""
                        )}
                        to="/product-upload"
                      >
                        Upload products
                      </Link>
                    </li>
                  </ul>
                </li>
                <li
                  className={
                    this.state.product
                      ? "has_sub active-menu nav-active"
                      : "has_sub"
                  }
                  id="ac822b95-0d77-4cd1-a721-e773a26df538"
                >
                  <Link
                    to="/category"
                    className={
                      this.state.Tab === "category"
                        ? "waves-effect active-menu"
                        : "waves-effect"
                    }
                    onClick={this.setActiveTab.bind(this, "category", "", "")}
                  >
                    <i className="typcn typcn-th-large-outline"></i>
                    <span> Category </span>
                  </Link>
                </li>
                <li
                  className={
                    this.state.vendorOder
                      ? "has_sub active-menu nav-active"
                      : "has_sub"
                  }
                  id="a87ec3a0-83a8-4441-9813-e53faaaa951e"
                >
                  <Link
                    to="#"
                    onClick={() =>
                      this.setState({
                        vendorOder: !this.state.vendorOder,
                        dashboard_menu: false,
                        email_menu: false,
                        ui_menu: false,
                        form_menu: false,
                        chart_menu: false,
                        table_menu: false,
                        icon_menu: false,
                        map_menu: false,
                        auth_menu: false,
                        extra_menu: false,
                        eco_menu: false,
                        emt_menu: false,
                      })
                    }
                    className={
                      this.state.Tab === "master"
                        ? "waves-effect active-menu"
                        : "waves-effect"
                    }
                  >
                    <i className="mdi mdi-cube-outline"></i>{" "}
                    <span>
                      {" "}
                      Orders{" "}
                      <span className="pull-right">
                        <i className="mdi mdi-chevron-right"></i>
                      </span>
                    </span>{" "}
                  </Link>
                  <ul
                    className="list-unstyled"
                    style={{
                      display: this.state.vendorOder ? "block" : "none",
                    }}
                  >
                    <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}>
                      <Link
                        className={
                          this.state.SubTab === "Fixed" ? "active-menu" : ""
                        }
                        onClick={this.setActiveTab.bind(this, "Fixed", "")}
                        to="/all-order"
                      >
                        All
                      </Link>
                      <span className="badge badge-success">{this.state.allOrderCount}</span>
                    </li>
                    <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}>
                      <Link
                        className={
                          this.state.SubTab === "Ready for pickup"
                            ? "active-menu"
                            : ""
                        }
                        onClick={this.setActiveTab.bind(
                          this,
                          "Ready for pickup",
                          ""
                        )}
                        to="/ready-for-pickup"
                      >
                        Ready for pickup
                      </Link>
                      <span className="badge badge-success">{this.state.readyForPickup}</span>
                    </li> 
                    <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}>
                      <Link className={this.state.SubTab === 'ready pickup vendor order' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'ready pickup vendor order', '')} to="/ready-for-pickup-unassign">Ready for Pickup (Unassigned)
                      </Link>
                      <span className="badge badge-success">{this.state.unassignedDriverReadyForPickup}</span>
                    </li>
                    <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}>
                      <Link className={this.state.SubTab === 'ready pickup vendor order' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'ready pickup vendor order', '')} to="/ready-for-pickup-assign">Ready for Pickup (Assigned)
                      </Link>
                      <span className="badge badge-success">{this.state.assignedDriverReadyForPickup}</span>
                    </li>
                    <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}>
                      <Link
                        className={
                          this.state.SubTab === "EnRoute to Warehouse" ? "active-menu" : ""
                        }
                        onClick={this.setActiveTab.bind(this, "EnRoute to Warehouse", "" )}
                        to="/enroute-warehouse"
                      >
                        EnRoute to Warehouse
                      </Link>
                      <span className="badge badge-success">{this.state.enRouteWarehouse}</span>
                    </li>
                    <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}>
                      <Link
                        className={
                          this.state.SubTab === "At store" ? "active-menu" : ""
                        }
                        onClick={this.setActiveTab.bind(this, "At store", "")}
                        to="/at-store"
                      >
                        At store
                      </Link>
                      <span className="badge badge-success">{this.state.atStore}</span>
                    </li>
                    <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}>
                      <Link
                        className={
                          this.state.SubTab === "Seller Accepted Orders" ? "active-menu" : ""
                        }
                        onClick={this.setActiveTab.bind(this, "Seller Accepted Orders", "")}
                        to="/seller-accepted-orders"
                      >
                        Accepted
                      </Link>
                      <span className="badge badge-success">{this.state.accepted}</span>
                    </li>
                    <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}>
                      <Link
                        className={
                          this.state.SubTab === "Seller Pending Orders" ? "active-menu" : ""
                        }
                        onClick={this.setActiveTab.bind(this, "Seller Pending Orders", "")}
                        to="/seller-pending-orders"
                      >
                        Pending
                      </Link>
                      <span className="badge badge-success">{this.state.pending}</span>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li className="menu-title">Report</li>
                <li
                  className={
                    this.state.product
                      ? "has_sub active-menu nav-active"
                      : "has_sub"
                  }
                  id="f3457009-e0ce-4986-a099-9d2c6a04a43e"
                >
                  <Link
                    to="/sales-report"
                    onClick={this.setActiveTab.bind(
                      this,
                      "sales-report",
                      "",
                      ""
                    )}
                  >
                    <i className="typcn typcn-th-large-outline"></i>
                    <span> Sales report </span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="clearfix"></div>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
}
VendorSidebar.propsTypes = {
  getRole: PropTypes.func.isRequired,
};
var mapStateToProps = (state) => ({
  Zeed: state.Zeed,
});
export default withRouter(connect(mapStateToProps, { getRole })(VendorSidebar));

import React, {useEffect, useState } from 'react'
import AUX from '../../../hoc/Aux_';
import { Link  } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {getCategory , addAttribute} from '../../../redux/MainAction'
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';

const AttributeAdd = () => {
    const dispatch = useDispatch()
    const CateList = useSelector(state=>state.Zeed.category)
    const [cate , setCate]=useState({
        attributeName:'',
        attributeNameAr:'',
        categoryId:'',
    })

    const [attributeOptions, setAttributeOptions] = useState([]);

    const onAddOption = () => {
        const newOption = { name: '', name_ar: '' };
        setAttributeOptions([...attributeOptions, newOption]);
    };

    const removeOptions = (index) => {
        const filteredOptions = [...attributeOptions];
        filteredOptions.splice(index, 1);
        setAttributeOptions(filteredOptions);
    };

    const optionHandleChange = (e, index) => {
        const updatedOptions = attributeOptions.map((data, i) =>
          index === i ? { ...data, [e.target.name]: e.target.value } : data
        );
        setAttributeOptions(updatedOptions);
    };

    useEffect(()=>{
        getRolList()
      },[])
    
    const getRolList = ()=>{
        var DATA={
          startRange:'0',
          count:"1000"
        }
        dispatch(getCategory(DATA))
    }

    const handleChange = (e) =>{
        const { value , name } = e.target
        setCate({...cate,[name]: value})
    }
    const onSubmit = (e)=>{
        e.preventDefault()
        if (cate?.attributeName === "") {
            Swal.fire({
                title: "Warning",
                icon: "warning",
                text: "Please Add Attribute Name in English",
                type: "warning",
                confirmButtonColor: "#3ab1f7",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
            });
            return false;
        } else if (cate?.attributeNameAr === "") {
            Swal.fire({
                title: "Warning",
                icon: "warning",
                text: "Please Add Attribute Name in Arabic",
                type: "warning",
                confirmButtonColor: "#3ab1f7",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
            });
            return false;
        }
        else if (cate?.categoryId === "") {
            Swal.fire({
                title: "Warning",
                icon: "warning",
                text: "Please Add Category",
                type: "warning",
                confirmButtonColor: "#3ab1f7",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
            });
            return false;
        } else if (!attributeOptions.length || !attributeOptions[0].name || !attributeOptions[0].name_ar) {
            Swal.fire({
                title: "Warning",
                icon: "warning",
                text: "Please Add Attribute options",
                type: "warning",
                confirmButtonColor: "#3ab1f7",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
            });
            return false;
        }

        var data = {
            attributeName:cate?.attributeName,
            attributeNameAr:cate?.attributeNameAr,
            categoryId:cate?.categoryId,
            attributeOptions:attributeOptions
        }        
        dispatch(addAttribute(data))
        //  console.log("data",data);
        
    }
    // console.log("a",attributeOptions);
  return (
    <AUX>
    <div className="page-content-wrapper">
            <div className="container-fluid">

           <div className="row">
               <div className="col-lg-12">
                   <div className="card m-b-20">
                       <div className="card-body">
                               <form onSubmit={onSubmit}>
                                   <div className='row'>
                                       <div className="form-group col-md-6">
                                           <label>Attribute Name (En) <span style={{ color: 'red' }}>*</span></label>
                                           <input type="text" className="form-control" placeholder="Attribute Name (En)" name='attributeName' value={cate.attributeName} onChange={handleChange}/>
                                           {/* <span id="err">{this.state.name_err}</span> */}
                                       </div>
                                       <div className="form-group col-md-6">
                                           <label>Attribute Name (Ar) <span style={{ color: 'red' }}>*</span></label>
                                           <input type="text" className="form-control" placeholder="Attribute Name (Ar)" name='attributeNameAr' value={cate.attributeNameAr} onChange={handleChange}/>
                                           {/* <span id="err">{this.state.name_err}</span> */}
                                       </div>
                                       <div className="form-group col-md-6">
                                           <label>Category <span style={{ color: 'red' }}>*</span></label>
                                           <div className="col-md-13">
                                           <select className="form-control" name='categoryId' value={cate.categoryId ? cate.categoryId : ""} onChange={handleChange}>
                                           <option value="" disabled>Select a category</option>
                                               {CateList !== undefined &&
                                                CateList !== null &&
                                                CateList.length > 0
                                                    ? CateList.map((itm) => (
                                                    <option
                                                        value={itm.id}
                                                        key={itm.id}
                                                    >
                                                        {itm.name}
                                                    </option>
                                                    ))
                                                    : null}
                                               </select>
                                           </div>
                                       </div>
                                       <div className="form-group col-md-12">
                                          <Button outline color="blue-grey" onClick={onAddOption}>+ Add option</Button>
                                       </div>
                                       <div className='col-md-12 row'>
                                            {attributeOptions?.map((data,index)=>{
                                                return <div className='row col-md-12 attribt' key={index}>
                                                    <div className='col-md-5'>
                                                        <div className='form-group row'>
                                                            <div className='col-sm-10'>
                                                            <label>Attribute Option Name (En)</label>
                                                                <input 
                                                                    type='text'
                                                                    className="form-control"
                                                                    name="name"
                                                                    id="name"
                                                                    placeholder="Attibute Option Name English"
                                                                    required
                                                                    value={data.name}
                                                                    onChange={e=>optionHandleChange(e,index)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-5'>
                                                        <div className='form-group row'>
                                                            <div className='col-sm-10'>
                                                            <label>Attribute Option Name (En)</label>
                                                                <input 
                                                                    type='text'
                                                                    className="form-control"
                                                                    name="name_ar"
                                                                    id="name_ar"
                                                                    placeholder="Attibute Option Name Arabic"
                                                                    required
                                                                    value={data.name_ar}
                                                                    onChange={e=>optionHandleChange(e,index)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2 mt-3">
                                                        <Button outline color='danger' onClick={()=> removeOptions(index)}>Remove</Button>
                                                    </div>
                                                </div>
                                            })}
                                       </div>
                                   </div>
                                   <div className="form-group">
                                       <div className='d-flex justify-content-end '>
                                           <button  onClick={onSubmit} type="submit" className="btn btn-primary waves-effect waves-light mr-2">
                                               Submit
                                           </button>
                                           <Link to='/attribute-All' className="btn btn-secondary waves-effect m-l-5">
                                               Cancel
                                           </Link>
                                       </div>
                                   </div>
                           </form>
                       </div>
                   </div>
               </div> 

           </div> 
       </div> 
   </div>
</AUX>
  )
}

export default AttributeAdd
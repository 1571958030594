import React, { useEffect, useState } from "react";
import AUX from "../../../hoc/Aux_";
import { Link, useParams } from "react-router-dom";
import {
  addRole,
  editRole,
  getRole,
  updateProfile,
} from "../../../redux/MainAction";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-modal/styles.css";
import Swal from "sweetalert2";
import Axios from "axios";
import Constant from "../../../Constant";

var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

const ProfileUpdate = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const role = localStorage.getItem("ucs-userId");

  const [roles, setRoles] = useState({
    name: "",
    email: "",
    password: "",
    userName: "",
    isActive: "true",
  });

  useEffect(() => {
    getRolList();
  }, []);

  const getRolList = () => {
    dispatch(getRole());
  };

  useEffect(() => {
    let data = {
      id: role,
    };
    Axios.post(Constant.getAPI() + "/admin/view-profile", data, config)
      .then((res) => {
        let admin = res.data.data;
        setRoles({
          name: admin.name || "",
          isActive: admin.isActive === true ? "true" : "false",
          email: admin.email,
          userName: admin.userName || "",
          password: "",
        });
      })
      .catch((err) => console.log(err));
  }, [role]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setRoles({ ...roles, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      id: role,
      name: roles.name,
      isActive: roles.isActive === "true",
      email: roles.email,
      userName: roles.userName,
    };
    dispatch(updateProfile(data));
  };

  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <form onSubmit={onSubmit}>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>Roles Type</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Roles Type"
                          name="name"
                          value={roles.name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>User Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="User Name"
                          name="userName"
                          value={roles.userName}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Email</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          value={roles.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Password</label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          name="password"
                          value={roles.password}
                          onChange={handleChange}
                        />
                      </div>
                      {params?.id && (
                        <div className="form-group col-md-6">
                          <label>Status</label>
                          <div className="col-md-13">
                            <select
                              className="form-control"
                              name="isActive"
                              value={roles.isActive}
                              onChange={handleChange}
                            >
                              <option value="true">Active</option>
                              <option value="false">In-Active</option>
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <div className="d-flex justify-content-end ">
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light mr-2"
                        >
                          Submit
                        </button>
                        <Link
                          to="/roles"
                          className="btn btn-secondary waves-effect m-l-5"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AUX>
  );
};

export default ProfileUpdate;

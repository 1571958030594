import React, { useEffect, useState } from 'react'
import AUX from '../../../hoc/Aux_';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import {getCoupon, updateCouponDisplay, updateCouponStatus} from '../../../redux/MainAction'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Toggle from 'react-toggle';
import axios from "axios";
import { GET_COUPON } from "../../../redux/type";
import Constant from "../../../Constant";
import { setLoading } from "../../../redux/MainAction";


var config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("loged_In_auth"),
    },
};

const Coupon = () => {
    const dispatch = useDispatch()
    const CouponList = useSelector(state=>state.Zeed.coupon);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20);

    useEffect(()=>{
        dispatch(setLoading(true));
        fetchPageData(1);
          // getRolList()
    },[])
    
    const fetchPageData = (pageNum) => {
        const DATA = {
            startRange: (pageNum - 1) * perPage,
            count: perPage,
            page: pageNum,
        };
        axios
            .post(Constant.getAPI() + "/coupon/backend-getAll", DATA, config)
            .then((res) => {
                // window.setLoading(true)
                dispatch({
                    type: GET_COUPON,
                    payload: res.data.data,
                    totalRows: res.data.totalRows
                });
                setTotalRows(res.data.totalRows)
                dispatch(setLoading(false));
            })
            .catch((err) => {
                console.log(err)
                dispatch(setLoading(false))
            }
            );
        setCurrentPage(pageNum);
    };

    const handlePageChange = (pageNum) => {
        dispatch(setLoading(true));
        fetchPageData(pageNum);
    };

    const handlePerPageChange = (event) => {
        setPerPage(Number(event.target.value));
        fetchPageData(1);
    };

    const handleToggleChangeForStatus = (id, isStatus) => {
        var data = {
          id: id,
          isStatus: isStatus,
        };
        dispatch(updateCouponStatus(data));
      };

      const handleToggleChangeForDisplay = (id, isDisplay) => {
        var data = {
          id: id,
          isDisplay: isDisplay,
        };
        dispatch(updateCouponDisplay(data));
      };
    // const getRolList = ()=>{
    //     var DATA={
    //       startRange:'0',
    //       count:"1000"
    //     }
    //     dispatch(getCoupon(DATA))
    // }
    // console.log("CouponList",CouponList);
    const columns =[
            {
                label: "Title",
                field: "name", 
            },
            {
                label: "Code",
                field: "couponCode", 
            },
            {
                label: "Discount Value",
                field: "discountValue", 
            },
            {
                label: "Discount Type",
                field: "discountType", 
            },
            {
                label: "minimum PurchaseAmount",
                field: "minPurchaseAmount", 
            },
            {
                label: "Description",
                field: "description", 
            },
            {
                label: "Valid From",
                field: "validFrom", 
            },
            {
                label: "Valid To",
                field: "validTo", 
            },
            {
                label: "Maximum User",
                field: "maxUsers", 
            },
            {
                label: "Status",
                field: "status", 
            },
            {
                label: "Display",
                field: "display", 
            },
            {
                label: "Action",
                field: "id", 
            },
        ];
    const rows = CouponList?.map((Item)=>{return {
        name: Item.name,
        couponCode: Item.couponCode,
        discountValue: Item.discountValue,
        discountType: Item.discountType,
        minPurchaseAmount: Item.minPurchaseAmount,
        description: Item.description,
        validFrom: moment(Item.validFrom).format("L"),
        validTo: moment(Item.validTo).format("L"),
        maxUsers: Item.maxUsers,
        status:<Toggle defaultChecked={Item.status} value={Item.status} onChange={(e) => handleToggleChangeForStatus(Item.id, e.target.checked)} />,
        display:<Toggle defaultChecked={Item.display} value={Item.display} onChange={(e) => handleToggleChangeForDisplay(Item.id, e.target.checked)} />,
        id: (
            <div>
              <Link to={"/coupon/edit/" + Item.id} title="Edit" className="mdi mdi-grease-pencil"></Link>
              <Link to={`/coupon/view/${Item?.id}`} title="View" className="mdi mdi-eye"></Link>
            </div>
          ),
    }});

    const data = {
        columns,
        rows,
      };
    const totalPages = Math.ceil((totalRows ? totalRows : 0) / perPage);
  return (
    <AUX>
        <div className="page-content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card m-b-20">
                            <div className="card-body">
                               <div className='d-flex justify-content-end'>
                               <Link to='/coupon/add'>
                                <Button size="sm" color="secondary" onClick >Add Coupon</Button>
                               </Link>
                               </div>
                                <MDBDataTable
                                    bordered
                                    theadColor="#2632384"
                                    paging={false}
                                    hover
                                    entries={20}
                                    data={data}
                                />
                                <div className="pagination-controls">
                                      <button className="pagination-btn first-btn" onClick={() => handlePageChange(1)} disabled={currentPage === 1}>First</button>
                                      <button className="pagination-btn prev-btn" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                      <span>Page {currentPage} of {totalPages}</span>
                                      <button className="pagination-btn next-btn" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                                      <button className="pagination-btn last-btn" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>Last</button>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div> 
        </div>
    </AUX>
  )
}

export default Coupon
import React, { useEffect, useState } from 'react';
import AUX from '../../../hoc/Aux_';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../../redux/MainAction';
import axios from 'axios';
import Constant from '../../../Constant';

const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("loged_In_auth"),
    },
};

const options = [
    { label: "Active", name: "active", value: true },
    { label: "Inactive", name: "inactive", value: false },
];

const UserEditPass = () => {
    const dispatch = useDispatch();
    const usList = useSelector(state => state.Zeed.userList);
    const params = useParams();

    const [state, setState] = useState({
        firstName: "",
        userName: "",
        status: "",
        mobileNumber: "",
        email: "",
    });

    const handleChange = (e) => {
        const { value, name } = e.target;
        setState({ ...state, [name]: value });
    };

    useEffect(() => {
        if (params.id) {
            getUserDetails();
        }
    }, [params.id]);

    const getUserDetails = () => {
        axios
            .post(Constant.getAPI() + "/user/backend-getAll", {}, config)
            .then((res) => {
                const users = res.data?.data;
                console.log("users", users);
                const user = users?.find(itm => itm.id === params.id);
                setState({
                    firstName: user?.firstName,
                    userName: user?.userName,
                    status: user?.status,
                    email: user?.email,
                    mobileNumber: user?.mobileNumber,
                });
            })
            .catch((err) => console.log(err));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (params.id) {
            const data = {
                userId: params.id,
                status: state?.status,
                firstName: state?.firstName,
                email: state.email,
                userName: state?.userName,
                mobileNumber: state?.mobileNumber,
            };
            dispatch(updateUser(data));
        }
    };

    return (
        <AUX>
            <div className="page-content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <form onSubmit={onSubmit}>
                                        <div className='row'>
                                            <div className="form-group col-md-6">
                                                <label>User Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="User Name"
                                                    name='userName'
                                                    onChange={handleChange}
                                                    value={state?.userName || ""}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Name"
                                                    name='firstName'
                                                    onChange={handleChange}
                                                    value={state?.firstName || ""}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Email</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    name='email'
                                                    onChange={handleChange}
                                                    value={state?.email || ""}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Mobile Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Mobile Number"
                                                    name='mobileNumber'
                                                    onChange={handleChange}
                                                    value={state?.mobileNumber || ""}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Status</label>
                                                <div className="col-md-13">
                                                    <select
                                                        className="form-control"
                                                        name='status'
                                                        value={state?.status || ""}
                                                        onChange={handleChange}
                                                        placeholder='Status'
                                                    >
                                                        <option>Select status</option>
                                                        {options.map(itm =>
                                                            <option value={itm.value} key={itm.label} name={itm.name}>{itm.label}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className='d-flex justify-content-end'>
                                                <button type="submit" className="btn btn-primary waves-effect waves-light mr-2">
                                                    Submit
                                                </button>
                                                <Link to='/users' className="btn btn-secondary waves-effect m-l-5">
                                                    Cancel
                                                </Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AUX>
    );
};

export default UserEditPass;

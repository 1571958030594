import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { getCountry } from "../../../../redux/MainAction";
import AUX from '../../../../hoc/Aux_';
import moment from "moment";


const CountryView = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const countryList = useSelector((state) => state.Zeed.country);
  
    useEffect(() => {
      getCountryList();
    }, []);
  
    const getCountryList = () => {
      var DATA = {
        startRange: "0",
        count: "1000",
      };
      dispatch(getCountry(DATA));
    };
    const [coup, setCoup] = useState({
      name: "",
      currency: "",
      nameAr: "",
      status: "",
      createdAt: "",
    });
  
    useEffect(() => {
      if (params.id && countryList.length > 0) {
        const spr = countryList.find((itm) => itm.id === params.id);
        if (spr) {
          setCoup({
            name: spr.name,
            currency: spr.currency,
            nameAr: spr.nameAr_ar,
            status: spr.status == true ? 'Yes' : 'No',
            createdAt: moment(spr.updatedAt).format("L"),
          });
        }
      }
    }, [params.id, countryList]);
  
  
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div>
                      <h5>View Country Details</h5>
                      <hr />
                      <div className="row">
                        <div className="form-group col-md-6 sorting">
                         Country Name in English:
                        </div>
                        <div className="form-group col-md-6 sorting">
                          {coup.name}
                        </div>
                        {/* <div className="form-group col-md-6 sorting">
                           Country Name in Arabic:
                        </div>
                        <div className="form-group col-md-6 sorting">
                          {coup.nameAr}
                        </div> */}
                        <div className="form-group col-md-6 sorting">
                           Curreny:
                        </div>
                        <div className="form-group col-md-6 sorting">
                          {coup.currency}
                        </div>
                        <div className="form-group col-md-6 sorting">
                           Status:
                        </div>
                        <div className="form-group col-md-6 sorting">
                          {coup.status}
                        </div>
                        <div className="form-group col-md-6 sorting">
                           Created At:
                        </div>
                        <div className="form-group col-md-6 sorting">
                          {coup.createdAt}
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="d-flex justify-content-end">
                          <Link
                            to="/country"
                            className="btn btn-secondary waves-effect m-l-5"
                          >
                            Back
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  
   
  };
  

export default CountryView;
import React , { useEffect, useState } from 'react';
import AUX from '../../../hoc/Aux_';
import DonutChart from '../../../containers/Chartstypes/Apex/DonutChart';
import {getKPIAdmin} from '../../../redux/MainAction'
import { useDispatch, useSelector } from 'react-redux';
import MostViewPurchase from '../adminInsight/MostPurchase';
import HeaderAnim from '../adminInsight/HeaderAnim';
import {shake} from 'react-animations'
import Radium, {StyleRoot} from 'radium';
import { Button } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import C3Chart from 'react-c3js';
import 'c3/c3.css';
import MostViewPieChart from '../adminInsight/MostViewPieChart';
import moment from 'moment';

const KPI = () => {
    const dispatch = useDispatch()
    const DASHkPI = useSelector(state=>state?.Zeed?.adminKpi)

    const [dashApi , setDashApi] = useState([])

    const [ datefill , setSetDate ] =useState({
        startDate:"",
        endDate:'',
        select:""
    })

    const [top, setTop] = useState({
        // type: 'donut',
        // columns: []
        sold: [],
            options: {
              chart: {
                type: 'donut',
              },
        },
    });

    const [worst, setWorst] = useState({
        sold: [],
        options: {
          chart: {
            type: 'donut',
          },
        },
    });

    const [purchase , setPurchase ] = useState({
        sold: [],
        options: {
          chart: {
            type: 'donut',
          },
        },
    })

    const [ mostView , setMostView ] = useState({
        sold: [],
        options: {
          chart: {
            type: 'donut',
          },
        },
    })

    const control = "shake"

    useEffect(()=>{
        getList()
    },[])

    const getList = () =>{
        var data = {

        }
        dispatch(getKPIAdmin(data))
    }
    useEffect(()=>{
        if(DASHkPI){
            setDashApi(DASHkPI)
        }
    },[DASHkPI])
   
    const  handleDate = (e) =>{
        const {value , name} = e.target
        setSetDate({...datefill,[name]:value})
    }
    const btnSub = () =>{
        if(datefill?.startDate!== '' && datefill?.endDate!==''){
            var data = {
                startDate:datefill?.startDate,
                endDate:datefill?.endDate,
            }
            dispatch(getKPIAdmin(data))
        }
    }
    useEffect(()=>{
        const today = new Date();
        if(datefill.select==="1 week"){
            const previousWeekStartDate = new Date(today);
            previousWeekStartDate.setDate(today.getDate() - 6); // Start of the week, 7 days ago
            
            var data = {
                startDate:moment(previousWeekStartDate).format("YYYY-MM-DD"),
                endDate:moment(today).format("YYYY-MM-DD"),
            }
            dispatch(getKPIAdmin(data))
            // return previousWeekStartDate.toDateString();
            // console.log("previousWeekStartDate",previousWeekStartDate);
        }else if (datefill?.select==="1 month"){
            const previousMonth = new Date(today);
            previousMonth.setDate(today.getDate() - 30); // Start of the week, 7 days ago
            
            var month = {
                startDate:moment(previousMonth).format("YYYY-MM-DD"),
                endDate:moment(today).format("YYYY-MM-DD"),
            }
            dispatch(getKPIAdmin(month))
            // return previousWeekStartDate.toDateString();
            // console.log("previousWeekStartDate",previousMonth);
        }else if(datefill?.select==="1 year"){
            const previousYear = new Date(today);
            previousYear.setDate(today.getDate() - 365); // Start of the week, 7 days ago
            
            var year = {
                startDate:moment(previousYear).format("YYYY-MM-DD"),
                endDate:moment(today).format("YYYY-MM-DD"),
            }
            dispatch(getKPIAdmin(year))
            // return previousWeekStartDate.toDateString();
            // console.log("previousWeekStartDate",previousYear);
        }else if(datefill?.select==="1 day"){
            const day = new Date(today);
            day.setDate(today.getDate() - 1); // Start of the week, 7 days ago
            
            var days = {
                startDate:moment(day).format("YYYY-MM-DD"),
                endDate:moment(today).format("YYYY-MM-DD"),
            }
            dispatch(getKPIAdmin(days))
        }
    },[datefill.select])

    const revenue = dashApi?.revenueByCategoryResponse
    // const cate =  DASHkPI?.revenueByCategoryResponse
    const revenueData = dashApi?.salesByCategoryResponse?.map(item => ({
        name: item?.categoryName,
        totalSales: item?.totalSales
    }));

    const revNames = revenueData?.map(item => item?.name);
    const revSale = revenueData?.map(item => (item?.totalSales).toFixed());
    
//    console.log("ABC",dashApi);
    const dataRevenue = {
        options: {
            chart: {
              height: 350,
              type: 'bar',
              stacked: true,
          },
          plotOptions: {
              bar: {
                  horizontal: false,
              },   
          },
          xaxis: {
              categories: revNames,
          }, 
          yaxis: [
            {
              labels: {
                formatter: function(val) {
                  return val.toFixed(0);
                }
              }
            }
          ],
          
          tooltip: {
              y: {
                formatter: function (value, {dataPointIndex, w}) {
                    return w.config.series[0].data[dataPointIndex]
                    //or you can judt add 'lac' to value
                    //return `${value} lac`
                  }
              },
          },
          fill: {
              opacity: 1
          },
          colors:['#47b8c6', '#f2f2f2'],
          legend: {
              position: 'top',
              horizontalAlign: 'left',
              offsetX: 40
          }
        },
        series: [{
            name: 'Revenue',
            data: revSale
          }, 
        ],
    }

    // SALES BAR CHART DATA
    const salesD = dashApi?.salesByCategoryResponse

    const categoriesData = dashApi?.salesByCategoryResponse?.map(item => ({
        name: item?.categoryName,
        totalSales: item?.totalSales
    }));

    const categoryNames = categoriesData?.map(item => item?.name);
    const cateSale = categoriesData?.map(item => (item?.totalSales).toFixed());

    // console.log("DASHkPI"  ,DASHkPI );
    const dataSales = {
        options: {
            chart: {
              height: 350,
              type: 'bar',
              stacked: true,
          },
          plotOptions: {
              bar: {
                  horizontal: false,
              },   
          },
          xaxis: {
              categories: categoryNames,
          }, 
          yaxis: [
            {
              labels: {
                formatter: function(val) {
                  return val.toFixed(0);
                }
              }
            }
          ],
          tooltip: {
              y: {
                  formatter: function(val) {  return val  }
              },
          },
          fill: {
              opacity: 1
          },
          colors:['#47b8c6', '#f2f2f2'],
          legend: {
              position: 'top',
              horizontalAlign: 'left',
              offsetX: 40
          }
        },
        series: [{
            name: 'Sold',
            data: cateSale
          }, 
        ],
    }

    // MOST VIEW PIE CHART DATA
    const MVTab = dashApi?.mostViewedProducts
    const MVTD = MVTab?.map(prod => parseInt(prod?.views));
    const MVName = MVTab?.map(prod => prod?.title);

    // console.log("purchase",purchase);

    useEffect(() => {
    if (MVTab && MVTab.length > 0) {
        setMostView(prevState => ({
        ...prevState,
        sold: MVTD,
        options: {
            labels: MVName,
            colors: ['#1e2247', '#ECEE81', '#9EB384'],
            chart: {
            type: 'donut',
            },
            plotOptions: {
            pie: {
                donut: {
                labels: {
                    show: true,
                },
                },
            },
            },
        },
        }));
    }else{
        setMostView(prevState => ({
            ...prevState,
            sold: [],
            options: {
                labels: [],
                colors: ['#1e2247', '#ECEE81', '#9EB384'],
                chart: {
                type: 'donut',
                },
                plotOptions: {
                pie: {
                    donut: {
                    labels: {
                        show: true,
                    },
                    },
                },
                },
            },
            }));
    }

    }, [MVTab]);

    // MOST PURCHASE
    const SLos = dashApi?.mostViewedProductsWithPurchase
    const MostPurchasePie = SLos?.map(prod => parseInt(prod?.purchaseCount));
    const MostSaleName = SLos?.map(prod => prod?.title);

    // console.log("purchase",purchase);

    useEffect(() => {
    if (SLos && SLos.length > 0) {
        setPurchase(prevState => ({
        ...prevState,
        sold: MostPurchasePie,
        options: {
            labels: MostSaleName,
            colors: ['#F4CE14','#CE5A67','#A7D397',"#232D3F","#80B3FF"],
            chart: {
            type: 'donut',
            },
            plotOptions: {
            pie: {
                donut: {
                labels: {
                    show: true,
                },
                },
            },
            },
        },
        }));
    }else{
        setPurchase(prevState => ({
            ...prevState,
            sold: [],
            options: {
                labels: [],
                colors: ['#1e2247', '#ECEE81', '#9EB384'],
                chart: {
                type: 'donut',
                },
                plotOptions: {
                pie: {
                    donut: {
                    labels: {
                        show: true,
                    },
                    },
                },
                },
            },
            }));
    }
    }, [SLos]);
       
    // const MostPurchasePie = dashApi?.mostViewedProductsWithPurchase?.map(prod => {
    //     return [ prod?.title , prod?.quantitySold]
    // });
    // console.log("MostPurchasePie", purchase);
   
    // useEffect(() => {
    //     if (MostPurchasePie?.length > 0) {
    //         if (JSON.stringify(MostPurchasePie) !== JSON.stringify(purchase?.columns)) {
    //             setPurchase({ type: 'donut', columns: MostPurchasePie });
    //         }
    //     } else if (purchase?.columns?.length !== 0) {
    //         setPurchase({ type: 'donut', columns: [] });
    //     }
    // }, [MostPurchasePie , purchase.columns])
    // const colorsPurchase = {
    //     pattern : ['#86A789','#CE5A67','#A8DF8E',"#232D3F","#FF3FA4" ] 
    // };

    // WORST SELS


    const Wsale = dashApi?.worstSellingProductsTable
    const WorstSalePie = Wsale?.map(prod => parseInt(prod?.totalSold));
    const WorstSaleName = Wsale?.map(prod => prod?.title);

    useEffect(() => {
    if (dashApi?.worstSellingProductsTable && dashApi?.worstSellingProductsTable.length > 0) {
        setWorst(prevState => ({
        ...prevState,
        sold: WorstSalePie,
        options: {
            labels: WorstSaleName,
            colors: ['#1e2247', '#6ec1d6', '#a5d3b6'],
            chart: {
            type: 'donut',
            },
            plotOptions: {
            pie: {
                donut: {
                labels: {
                    show: true,
                },
                },
            },
            },
        },
        }));
    }else{
        setWorst(prevState => ({
            ...prevState,
            sold: [],
            options: {
                labels: [],
                colors: ['#1e2247', '#ECEE81', '#9EB384'],
                chart: {
                type: 'donut',
                },
                plotOptions: {
                pie: {
                    donut: {
                    labels: {
                        show: true,
                    },
                    },
                },
                },
            },
            }));
    }
    }, [Wsale]);
    // console.log("WorstSalePie", worst);
    // Top  SELS

    

    const get = dashApi?.topSellingProductsTable
    const totalSoldArray = get?.map(product => parseInt(product?.totalSold));
    const name = get?.map(product => (product?.title));

    // console.log("name" , name);

    useEffect(() => {
        if (totalSoldArray && totalSoldArray.length > 0) {
            setTop({
                sold:  totalSoldArray ,
                options: {
                    labels: name,
                    colors:['#61A3BA', '#0C356A', '#45474B'],
                    chart: {
                        type: 'donut',
                    },
                    plotOptions:{
                        pie:{
                            donut:{
                                labels:{
                                    show:true
                                }
                            }
                        }
                    }
                    
                },
                
                
            });
        }else{
            setTop(prevState => ({
                ...prevState,
                sold: [],
                options: {
                    labels: [],
                    colors: ['#1e2247', '#ECEE81', '#9EB384'],
                    chart: {
                    type: 'donut',
                    },
                    plotOptions: {
                    pie: {
                        donut: {
                        labels: {
                            show: true,
                        },
                        },
                    },
                    },
                },
                }));
        }
    }, [get]); // Watching for changes in the original data, not the derived totalSoldArray

    const totalSpentTime = dashApi?.customerSpentTimeResponse?.reduce((total, customer) => total + customer?.spentTimeInSeconds, 0);
    const averageSpentTime = totalSpentTime / dashApi?.customerSpentTimeResponse?.length;
    // console.log("totalSpentTime",averageSpentTime);
    
  return (
    
    <AUX>
			  <div className="page-content-wrapper">
              <div className="container-fluid">
                    <div className="form-group row ml-1">
                        <div className="selFill">
                            <select className="form-control" name='select' onChange={handleDate} value={datefill?.select}>
                                <option>- Select Range -</option>
                                <option value="1 day" >Day</option>
                                <option value="1 week">Week</option>
                                <option value="1 month">Month</option>
                                <option value="1 year">Year</option>
                                <option value="custom">Custom</option>
                            </select>
                        </div>
                       
                    </div>
                    { datefill?.select==='custom' ? 
                    <div className='row col-md-12 '> 
                        <div className="form-group row ml-2">
                            <label htmlFor="example-date-input" className="col-sm-2 col-form-label" >Start Date</label>
                            <div className="col-sm-4">
                            <input className="form-control" type="date"  id="example-date-input" name='startDate' value={datefill.startDate} onChange={handleDate} />
                            </div>
                            <label htmlFor="example-date-input" className="col-sm-2 col-form-label">End Date</label>
                            <div className="col-sm-4">
                            <input className="form-control" type="date" name='endDate' id="example-date-input" value={datefill.endDate} onChange={handleDate} />
                            </div>
                        </div>
                        <div className='col-sm-6 mt-1'>
                            <Button className='col-sm-2' size="sm" color="primary" onClick={btnSub} >Submit</Button>
                        </div>
                    </div>
                    :""
                    }
                    <HeaderAnim now_control={control}></HeaderAnim>
                {/* <h4 className="mt-5 " style={{color:"#9BABB8" , borderBottom: "3px solid #C4DFDF" ,width:"190px", letterSpacing:"1px" }}>Sales OverView</h4> */}
                <div className="row mt-3">
                    <div className="col-md-6 col-xl-3">
                        <div className="mini-stat clearfix bg-white">
                      
                            <span className="mini-stat-icon bg-success mr-0 float-right"><i className="mdi mdi-currency-usd"></i></span>
                            <div className="mini-stat-info">
                                <span className="counter text-purple">{dashApi?.totalSales ? dashApi?.totalSales.toFixed(3) : ""}</span>
                                {datefill?.select} Sales
                            </div>
                            <div className="clearfix"></div>
                            <p className=" mb-0 m-t-20 text-muted">Total Sales: KWD {dashApi?.totalSalesAllTime ? dashApi?.totalSalesAllTime.toFixed(3) : ""} </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3">
                        <div className="mini-stat clearfix bg-white">
                            <span className="mini-stat-icon bg-blue-grey mr-0 float-right"><i className="mdi mdi-black-mesa"></i></span>
                            <div className="mini-stat-info">
                                <span className="counter text-blue-grey">{dashApi.totalRevenue}</span>
                                {datefill?.select} Revenue
                            </div>
                            <div className="clearfix"></div>
                            <p className="text-muted mb-0 m-t-20">Total Revenue: KWD {dashApi?.totalRevenueAllTime} </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3">
                        <div className="mini-stat clearfix bg-white">
                          {/* <span className="mini-stat-icon bg-primary"><i className="mdi mdi-cart-outline"></i></span> */}
                            <span className="mini-stat-icon bg-brown mr-0 float-right"><i className="mdi mdi-cart-outline"></i></span>
                            <div className="mini-stat-info">
                                <span className="counter text-brown">{dashApi.orderCount}</span>
                                {datefill?.select} Orders Placed
                            </div>
                            <div className="clearfix"></div>
                            <p className="text-muted mb-0 m-t-20">Total Orders: {dashApi.orderCount} </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3">
                        <div className="mini-stat clearfix bg-white">
                            <span className="mini-stat-icon bg-info mr-0 float-right"><i className="mdi mdi-truck-delivery"></i></span>
                            <div className="mini-stat-info">
                                <span className="counter text-info">{dashApi.deliveredOrderCount}</span>
                                {datefill?.select} Orders Completed
                            </div>
                            <div className="clearfix"></div>
                            <p className="text-muted mb-0 m-t-20">Completed Orders: {dashApi.deliveredOrderCount} </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3">
                        <div className="mini-stat clearfix bg-white">
                            <span className="mini-stat-icon bg-indigo mr-0 float-right"><i className="mdi mdi-cart-outline"></i></span>
                            <div className="mini-stat-info">
                                <span className="counter text-indigo">{dashApi.canceledOrderCount}</span>
                                {datefill?.select} Orders Cancelled 
                            </div>
                            <div className="clearfix"></div>
                            <p className="text-muted mb-0 m-t-20">Cancelled  Orders: {dashApi.canceledOrderCount} </p>
                        </div>
                    </div>
                </div>

                <div className='row'>
                <div className={salesD?.length>9?"col-xl-12" :"col-xl-6"}>
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <h4 className="mt-0 header-title" style={{color:"#8294C4"}}>Sales By Category</h4>
                                    <AUX><ReactApexChart options={dataSales?.options} series={dataSales?.series} type="bar" height={salesD?.length>9?"600" :"400"}/></AUX>
                                </div>
                            </div>
                    </div>
                    {/* salesD */}
                    <div className={revenue?.length>9?"col-xl-12" :"col-xl-6"}>
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <h4 className="mt-0 header-title" style={{color:"#8294C4"}}>Revenue By Category {datefill?.select}</h4>
                                    <AUX><ReactApexChart options={dataRevenue?.options} series={dataRevenue?.series} type="bar" height={revenue?.length>9?"600" :"400"} /></AUX>
                                </div>
                            </div>
                    </div>
                </div>
                
                <div className='row'>
                <div className="col-xl-7">
                        <div className="card m-b-20">
                            <div className="card-body">
                            <h4 className="mt-0 m-b-30 header-title" style={{color:"#8294C4"}}>Top Selling Products {datefill?.select}</h4>
                                <div className="table-responsive tbl">
                                    {dashApi && dashApi.topSellingProductsTable ? (
                                        <table className="table table-vertical mb-0 scrolldown">
                                            <thead>
                                            <tr>
                                                <th>Product Name</th>
                                                <th style={{marginLeft:10}}>Product Sales</th>
                                                <th>Product Sold</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {dashApi.topSellingProductsTable.map((itm, index) => (
                                                <tr key={index}>
                                                <td>{itm.title}</td>
                                                <td>{itm.totalSales.toFixed(3)} KWD</td>
                                                <td>{itm.totalSold}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        ) : (
                                        <p>Loading or no data available</p>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5">
                        <div className="card m-b-20">
                            <div className="card-body">
                                 <div align="center" style={{ height:"360px" }}>
                                 <ReactApexChart options={top.options} series={top.sold} type="donut" style={{marginTop:'70px'}} height={'300'} />
                                    {/* <C3Chart style={{marginTop:'70px'}} data={top} color={colorstop} /> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-7">
                        <div className="card m-b-20">
                            <div className="card-body">
                            <h4 className="mt-0 m-b-30 header-title" style={{color:"#8294C4"}}>Least Selling Products {datefill?.select}</h4>
                                <div className="table-responsive tbl">
                                  
                                    {dashApi && dashApi.worstSellingProductsTable ? (
                                        <table className="table table-vertical mb-0 scrolldown">
                                            <thead>
                                            <tr>
                                                <th>Product Name</th>
                                                <th style={{marginLeft:10}}>Product Sales</th>
                                                <th>Product Sold</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {dashApi.worstSellingProductsTable.map((itm, index) => (
                                                <tr key={index}>
                                                    <td>{itm.title}</td>
                                                    <td>{itm.totalSales.toFixed(3)} KWD</td>
                                                    <td>{itm.totalSold}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        ) : (
                                        <p>Loading or no data available</p>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5">
                        <div className="card m-b-20">
                            <div className="card-body">
                                 <div align="center"  style={{ height:"360px" }}>
                                    {/* <C3Chart style={{marginTop:'70px'}} data={worst} color={colorsWorstSale} /> */}
                                    <ReactApexChart options={worst.options} series={worst.sold} type="donut" style={{marginTop:'70px'}} height={'300'} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <StyleRoot><div style={{ animation: 'x 3s', animationName: Radium.keyframes(shake, 'shake') , width:"15vw"}}>
                    <span style={{color:"#9BABB8", letterSpacing:"1px" ,  fontSize:"1.5vw" , fontWeight:"600"}}>Customer Insights</span>
                </div></StyleRoot>
                {/* <h4 className="mt-5 " style={{color:"#9BABB8"}}>Customer Insights</h4> */}
                <div className='row mt-3'>
                <div className="col-md-6 col-xl-3">
                        <div className="mini-stat clearfix bg-white">
                            <span className="mini-stat-icon bg-success mr-0 float-right"><i className="mdi mdi-account-plus"></i></span>
                            <div className="mini-stat-info">
                                <span className="counter text-purple">{dashApi.newCustomerCount}</span>
                                {datefill?.select}  New Customers
                            </div>
                            <div className="clearfix"></div>
                            <p className=" mb-0 m-t-20 text-muted">New Customers: {dashApi.newCustomerCount} </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3">
                        <div className="mini-stat clearfix bg-white">
                            <span className="mini-stat-icon bg-blue-grey mr-0 float-right"><i className="mdi mdi-account"></i></span>
                            <div className="mini-stat-info">
                                <span className="counter text-blue-grey">{dashApi?.repeatCustomerCount}</span>
                                {datefill?.select} Repeat Customers
                            </div>
                            <div className="clearfix"></div>
                            <p className="text-muted mb-0 m-t-20"> Repeat Customers: {dashApi?.repeatCustomerCount} </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3">
                        <div className="mini-stat clearfix bg-white">
                          {/* <span className="mini-stat-icon bg-primary"><i className="mdi mdi-cart-outline"></i></span> */}
                            <span className="mini-stat-icon bg-brown mr-0 float-right"><i className="mdi mdi-account-check"></i></span>
                            <div className="mini-stat-info">
                                <span className="counter text-brown">{dashApi.customerRetentionRate}</span>
                                {datefill?.select} Customer Retention Rate
                            </div>
                            <div className="clearfix"></div>
                            <p className="text-muted mb-0 m-t-20"> Customer Retention Rate: {dashApi.customerRetentionRate} </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3">
                        <div className="mini-stat clearfix bg-white">
                            <span className="mini-stat-icon bg-info mr-0 float-right"><i className="mdi mdi-alarm-check"></i></span>
                            <div className="mini-stat-info">
                                <span className="counter text-info">{(averageSpentTime/60).toFixed(2)} min</span>
                                {datefill?.select} Average time spent by Customers
                            </div>
                            <div className="clearfix"></div>
                            <p className="text-muted mb-0 m-t-20">Average time spent by Customers: {(averageSpentTime/60).toFixed(2)} min </p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                <div className="col-xl-7">
                        <div className="card m-b-20">
                            <div className="card-body">
                            <h4 className="mt-0 m-b-30 header-title" style={{color:"#8294C4"}}>Most Viewed Product {datefill?.select}</h4>
                                <div className="table-responsive tbl">
                                    {dashApi && dashApi.mostViewedProducts ? (
                                        <table className="table table-vertical mb-0 scrolldown">
                                            <thead>
                                            <tr>
                                                <th>Product Name</th>
                                                <th style={{marginLeft:10}}>Product Views</th>
                                                <th>Product Price</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {dashApi.mostViewedProducts.map((itm, index) => (
                                                <tr key={index}>
                                                    <td >{itm.title}</td>
                                                    <td>{itm.views} </td>
                                                    <td>{itm.price} KWD</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        ) : (
                                        <p>Loading or no data available</p>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5">
                        <div className="card m-b-20">
                            <div className="card-body">

                                 <div align="center" style={{ height:"360px" }}>
                                 <ReactApexChart options={mostView.options} series={mostView.sold} type="donut" style={{marginTop:'70px'}} height={'300'} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                <div className="col-xl-7">
                        <div className="card m-b-20">
                            <div className="card-body">
                            <h4 className="mt-0 m-b-30 header-title" style={{color:"#8294C4"}}> Product with Most Conversion {datefill?.select}</h4>
                                <div className="table-responsive tbl">
                                    {dashApi && dashApi.mostViewedProductsWithPurchase ? (
                                        <table className="table table-vertical mb-0 scrolldown">
                                            <thead>
                                            <tr>
                                                <th>Product Name</th>
                                                <th style={{marginLeft:10}}>Product Price</th>
                                                <th>Product Purchase</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {dashApi.mostViewedProductsWithPurchase.map((itm, index) => (
                                                <tr key={index}>
                                                    <td >{itm.title}</td>
                                                    <td>{itm.price} KWD</td>
                                                    <td>{itm?.purchaseCount}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        ) : (
                                        <p>Loading or no data available</p>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5">
                        <div className="card m-b-20">
                            <div className="card-body">
                                 <div align="center" style={{ height:"360px" }}>
                                 <ReactApexChart options={purchase.options} series={purchase.sold} type="donut" style={{marginTop:'70px'}} height={'300'} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
           </AUX>
  )
}

export default KPI
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import AUX from "../../../hoc/Aux_";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getCoupon } from "../../../redux/MainAction";

const CouponView = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const couponList = useSelector((state) => state.Zeed.coupon);

  useEffect(() => {
    getCouponList();
  }, []);

  const getCouponList = () => {
    var DATA = {
      startRange: "0",
      count: "1000",
    };
    dispatch(getCoupon(DATA));
  };
  const [coup, setCoup] = useState({
    name: "",
    couponCode: "",
    description: "",
    discountType: "",
    discountValue: "",
    validFrom: "",
    validTo: "",
    minPurchaseAmount: "",
    maxUsers: "",
    perUserLimit: "",
    usedCount: "",
    display: "",
    status: "",
  });

  useEffect(() => {
    if (params.id && couponList.length > 0) {
      const spr = couponList.find((itm) => itm.id === params.id);
      if (spr) {
        setCoup({
          name: spr.name,
          couponCode: spr.couponCode,
          description: spr.description,
          discountType: spr.discountType,
          discountValue: spr.discountValue,
          validFrom: moment(spr.validFrom).format("L"),
          validTo: moment(spr.validTo).format("L"),
          minPurchaseAmount: spr.minPurchaseAmount,
          maxUsers: spr.maxUsers,
          perUserLimit: spr.perUserLimit,
          usedCount: spr.usedCount,
          display: spr.display ? 'Yes' : 'No',
          status: spr.status ? 'Yes' : 'No',
        });
      }
    }
  }, [params.id, couponList]);


  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <div>
                    <h5>View Coupon Details</h5>
                    <hr />
                    <div className="row">
                      <div className="form-group col-md-6 sorting">
                       Title:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.name}
                      </div>
                      <div className="form-group col-md-6 sorting">
                        Coupon Code:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.couponCode}
                      </div>
                      <div className="form-group col-md-6 sorting">
                        Description:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.description}
                      </div>
                      <div className="form-group col-md-6 sorting">
                        Discount Type:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.discountType}
                      </div>
                      <div className="form-group col-md-6 sorting">
                        Discount Value:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.discountValue}
                      </div>
                      <div className="form-group col-md-6 sorting">
                        Valid From:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.validFrom}
                      </div>
                      <div className="form-group col-md-6 sorting">
                       Valid To:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.validTo}
                      </div>
                      <div className="form-group col-md-6 sorting">
                        Min Purchase Amount:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.minPurchaseAmount}
                      </div>
                      <div className="form-group col-md-6 sorting">
                        Max Users:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.maxUsers}
                      </div>
                      <div className="form-group col-md-6 sorting">
                        Per User Limit:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.perUserLimit}
                      </div>
                      <div className="form-group col-md-6 sorting">
                        Used Count:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.usedCount}
                      </div>
                      <div className="form-group col-md-6 sorting">
                        Display:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.display}
                      </div>
                      <div className="form-group col-md-6 sorting">
                       Status:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.status}
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex justify-content-end">
                        <Link
                          to="/coupon"
                          className="btn btn-secondary waves-effect m-l-5"
                        >
                          Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AUX>
  );

 
};

export default CouponView;

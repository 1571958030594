import React, { useEffect, useState } from 'react'
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getWalletList } from '../../../redux/MainAction'
import moment from 'moment';
import Toggle from 'react-toggle'
import { Button } from 'reactstrap';
import axios from "axios";
import { GET_WALLET_LIST } from "../../../redux/type";
import Constant from "../../../Constant";
import { setLoading } from "../../../redux/MainAction";
import Swal from 'sweetalert2';

var config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("loged_In_auth"),
    },
  };

const WalletList = () => {
    const dispatch = useDispatch()
    const walletList = useSelector(state=>state.Zeed.wallet)
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20);

    useEffect(()=>{
        dispatch(setLoading(true));
        fetchPageData(1);
        // getRolList()
      },[])

      const fetchPageData = (pageNum) => {
        const DATA = {
            startRange: (pageNum - 1) * perPage,
            count: perPage,
            page: pageNum,
        };
        axios
            .post(Constant.getAPI() + "/admin/backend-listSendMoneyToBank", DATA, config)
            .then((res) => {
                // window.setLoading(true)
                dispatch({
                    type: GET_WALLET_LIST,
                    payload: res.data.data,
                    totalRows: res.data.totalRows
                });
                setTotalRows(res.data.totalRows)
                dispatch(setLoading(false));
            })
            .catch((err) => {
                console.log(err)
                dispatch(setLoading(false))
            }
            );
        setCurrentPage(pageNum);
    };

    const handlePageChange = (pageNum) => {
        dispatch(setLoading(true));
        fetchPageData(pageNum);
    };

    const handlePerPageChange = (event) => {
        setPerPage(Number(event.target.value));
        fetchPageData(1);
    };
    // const getRolList = ()=>{
    //     var DATA={
    //       startRange:'0',
    //       count:"1000"
    //     }
    //     dispatch(getWalletList(DATA))
    // }
    const columns =[
            {
                label: "First Name",
                field: "firstName", 
            },
            {
                label: "Last Name",
                field: "lastName", 
            },
            {
                label: "Email",
                field: "email", 
            },
            {
                label: "Mobile No.",
                field: "mobileNumber", 
            },
            {
                label: "IBAN No.",
                field: "IBAN", 
            },
            {
                label: "Bank Name",
                field: "bankName", 
            },
            {
                label: "Service Charge",
                field: "serviceCharge", 
            },
            {
                label: "Sub Total",
                field: "subTotal", 
            },
            {
                label: "Total",
                field: "total", 
            },
            {
                label: "status",
                field: "status", 
            },
        ];
    const rows = walletList.map((Item)=>{return  { 
        firstName: Item.User.firstName ,
        lastName: Item.User.lastName ,
        email: Item.User.email ,
        mobileNumber: Item.User.mobileNumber ,
        IBAN: Item.IBAN ,
        bankName: Item.bankName ,
        serviceCharge: Item.serviceCharge ,
        subTotal: Item.subTotal ,
        total: Item.total ,
        status: (
            <Toggle
              checked={Item.status}
              onChange={() => handleStatusChange(Item.id)}
            />
          ),
      } })

      const handleStatusChange = (id) => {
        var data = {
          id: id,
        };
        axios
          .post(Constant.getAPI() + '/admin/toggleTransferStatus', data, config)
          .then(res => {
            if(res.data.status) {
              var data = {
                startRange: '0',
                count: "1000",
              };
              dispatch(getWalletList(data));
              Swal.fire({
                title: "Updated",
                text: "Transfer request Status has been updated",
                icon: "success",
                confirmButtonColor: "#3ab1f7",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
              });
            }
          })
          .catch(err => {
            console.log(err);
          });
      };

    const data = {
        columns,
        rows,
    };
    const totalPages = Math.ceil((totalRows ? totalRows : 0) / perPage);

  return (
    <AUX>
        <div className="page-content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card m-b-20">
                            <div className="table-responsive">
                               {/* <div className='d-flex justify-content-end'>
                               <Link to='/wallet/add'>
                                <Button size="sm" color="secondary" onClick >Add Roles</Button>
                               </Link>
                               </div> */}
                                <MDBDataTable
                                    bordered
                                    theadColor="#2632384"
                                    paging={false}
                                    hover
                                    entries={20}
                                    data={data}
                                />
                                  <div className="pagination-controls">
                                      <button className="pagination-btn first-btn" onClick={() => handlePageChange(1)} disabled={currentPage === 1}>First</button>
                                      <button className="pagination-btn prev-btn" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                      <span>Page {currentPage} of {totalPages}</span>
                                      <button className="pagination-btn next-btn" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                                      <button className="pagination-btn last-btn" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>Last</button>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div> 
        </div>
    </AUX>
  )
}

export default WalletList
// USER LOGIn
export const USER_LOGIN_DETAILS = "USER_LOGIN_DETAILS";

// User Listing
export const SET_LOADING = "SET_LOADING";
export const GET_ALL_USER = "GET_ALL_USER";
export const GET_ALL_SELLER="GET_ALL_SELLER";
export const GET_ROLES="GET_ROLES";
export const GET_WALLET_LIST="GET_WALLET_LIST";
export const GET_BANK_LIST="GET_BANK_LIST";
export const GET_CATEGORY="GET_CATEGORY";
export const GET_TIMESLOT="GET_TIMESLOT";
export const GET_ORDER="GET_ORDER";
export const GET_ADMIN_KPI = "GET_ADMIN_KPI";
export const GET_ORDER_STATUS = "GET_ORDER_STATUS";
export const GET_PACKAGE = "GET_PACKAGE";
export const GET_COUPON = "GET_COUPON";
export const GET_REPORTED_POSTS = "GET_REPORTED_POSTS";
export const GET_COUNTRY = "GET_COUNTRY";
export const GET_STATE = "GET_STATE";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_WINNERS_LIST = "GET_WINNERS_LIST";
export const GET_ATTRIBUTE = "GET_ATTRIBUTE";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_ROLES_MODULE_LIST = "GET_ROLES_MODULE_LIST";
export const GET_CITY = "GET_CITY";
export const GET_SELLER_DROPDOWN = "GET_SELLER_DROPDOWN";
export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_USERS_DROPDOWN = "GET_USERS_DROPDOWN";
export const GET_SUB_CATEGORY_DROPDOWN = "GET_SUB_CATEGORY_DROPDOWN";
export const GET_DRIVERS_DROPDOWN ="GET_DRIVERS_DROPDOWN";
export const GET_WAREHOUSE = "GET_WAREHOUSE";

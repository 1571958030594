import React, { useEffect, useState } from "react";
import AUX from "../../../hoc/Aux_";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getSellerBackend, sellerUpdate } from "../../../redux/MainAction";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import Constant from "../../../Constant";

const SellerEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const SellerList = useSelector((state) => state.Zeed.sellerList);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);


  const [civilIdFront, setCivilIdFront] = useState('');
  const [civilIdImgFront, setCivilIdImgFront] = useState('');
  const onCivilIdFrontChange = async (e) => {
    const imgData = e.target.files[0];
    const imgURL = URL.createObjectURL(imgData);
    const img = await UploadMedia(imgData);
    setCivilIdFront(img.id);
    setCivilIdImgFront(img.url);
  };

  const [civilIdBack, setCivilIdBack] = useState("");
  const [civilIdImgBack, setCivilIdImgBack] = useState("");
  const onCivilIdBackChange = async (e) => {
    const imgData = e.target.files[0];
    const imgURL = URL.createObjectURL(imgData);
    try {
      const img = await UploadMedia(imgData);
      setCivilIdBack(img.id);
      setCivilIdImgBack(img.url);
    } catch (error) {
      console.error("Error uploading media:", error);
    }
  };

  const [ownerId, setOwnerId] = useState("");
  const [ownerImg, setOwnerImg] = useState("");
  const onOwnerChange = async (e) => {
    try {
      const imgData = e.target.files[0];
      const imgURL = URL.createObjectURL(imgData);
      const img = await UploadMedia(imgData);
      setOwnerId(img.id);
      setOwnerImg(img.url);
    } catch (error) {
      console.error("Error uploading media:", error);
    }
  };

  const [licenseId, setLicenseId] = useState("");
  const [licenseImg, setLicenseImg] = useState("");
  const onLicenseChange = async (e) => {
    const imgData = e.target.files[0];
    const imgURL = URL.createObjectURL(imgData);
    try {
      const img = await UploadMedia(imgData);
      setLicenseId(img.id);
      setLicenseImg(img.url);
    } catch (error) {
      console.error("Error uploading media:", error);
    }
  };

  const [signatureId, setSignatureId] = useState("");
  const [signatureImg, setSignatureImg] = useState("");
  const onSignatureChange = async (e) => {
    var imgData = e.target.files[0];
    var imgURL = URL.createObjectURL(imgData);
    try {
      var img = await UploadMedia(imgData);
      setSignatureId(img.id);
      setSignatureImg(img.url);
    } catch (error) {
      console.error("Error uploading media:", error);
    }
  };

  const [articleAssoId, setArticleAssoId] = useState("");
  const [articleAssoImg, setArticleAssoImg] = useState("");
  const onArticleAssoChange = async (e) => {
    var imgData = e.target.files[0];
    var imgURL = URL.createObjectURL(imgData);
    try {
      var img = await UploadMedia(imgData); // Assuming UploadMedia is a separate function
      setArticleAssoId(img.id);
      setArticleAssoImg(img.url);
    } catch (error) {
      console.error("Error uploading media:", error);
    }
  };

  const [instagramId, setInstagramId] = useState("");
  const [instagramImg, setInstagramImg] = useState("");
  const onInstagramChange = async (e) => {
    var imgData = e.target.files[0];
    var imgURL = URL.createObjectURL(imgData);
    var img = await UploadMedia(imgData);
    setInstagramId(img.id);
    setInstagramImg(img.url);
  };

  const [agreementId, setAgreementId] = useState("");
  const [agreementImg, setAgreementImg] = useState("");
  const onAgreementChange = async (e) => {
    var imgData = e.target.files[0];
    var imgURL = URL.createObjectURL(imgData);
    try {
      var img = await UploadMedia(imgData);
      setAgreementId(img.id);
      setAgreementImg(img.url);
    } catch (error) {
      console.error("Error uploading media:", error);
    }
  };

  const [otherMediaId, setOtherMediaId] = useState("");
  const [otherMediaImg, setOtherMediaImg] = useState("");
  const onOtherMediaChange = async (e) => {
    var imgData = e.target.files[0];
    var imgURL = URL.createObjectURL(imgData);
    try {
      var img = await UploadMedia(imgData);
      setOtherMediaId(img.id);
      setOtherMediaImg(img.url);
    } catch (error) {
      console.error("Error uploading media:", error);
    }
  };

  const UploadMedia = async (imageData) => {
    const formData = new FormData();
    formData.append("media", imageData);
    try {
      const response = await Axios.post( `${Constant.getAPI()}/media/add`, formData,
        {
          headers: {
            Authorization: localStorage.getItem("Token"), "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status) {
        return {
          id: response.data.data[0]?.id,
          url: response.data.data[0]?.url,
        };
      } else {
        return "";
      }
    } catch (error) {
      // console.log(error.data);
      return "";
    }
  };

  useEffect(() => {
    getSellerList();
  }, []);

  const getSellerList = (pageNum) => {
    const DATA = {
      startRange: (pageNum - 1) * perPage,
      count: perPage,
      page: pageNum,
    };
    dispatch(getSellerBackend(DATA));
  };

  const [formData, setFormData] = useState({
    shopName: "",
    email: "",
    mobile: "",
    myfatoorahStatus: "",
    type: "Company",
    address: "",
    aboutBusiness: "",
    bankName: "",
    IBAN: "",
    isStoreVerified: "",
    myFatoorahSellerCode: "",
    mapUrl: "",
    lat: "",
    long: "",
    commissionPercentage: "",
    fixedCommissionAmount: "",
  });

  const [tabID, setTabID] = useState("businessInformation");

  const handleTabChange = (tab) => {
    setTabID(tab);
  };

  useEffect(() => {
    if (params.id && SellerList) {
      var spr = SellerList?.filter((itm) => itm.id === params.id);
      // console.log("spr", spr);
      var SELLER = spr[0];
      setFormData({
        userName: SELLER?.userName,
        shopName: SELLER?.storeDetails?.shopName,
        email: SELLER?.storeDetails?.sellerEmail,
        mobile: SELLER?.mobileNumber,
        myfatoorahStatus: SELLER?.storeDetails?.myfatoorahStatus ? "true" : "false",
        type: SELLER?.storeDetails?.type?SELLER?.storeDetails?.type:'Company',
        address: SELLER?.storeDetails?.address,
        aboutBusiness: SELLER?.storeDetails?.aboutBusiness,
        bankName: SELLER?.storeDetails?.bankName,
        IBAN: SELLER?.storeDetails?.IBAN,
        bankAccountHolderName: SELLER?.storeDetails?.bankAccountHolderName,
        bankAccount: SELLER?.storeDetails?.bankAccount,
        isStoreVerified: SELLER?.storeDetails?.isStoreVerified === 1 ? "true" : "false",
        myFatoorahSellerCode: SELLER?.storeDetails?.myFatoorahSellerCode,
        mapUrl: SELLER?.storeDetails?.mapUrl,
        lat: SELLER?.storeDetails?.lat,
        long: SELLER?.storeDetails?.long,
        commissionPercentage: SELLER?.storeDetails?.commissionPercentage,
        fixedCommissionAmount: SELLER?.storeDetails?.fixedCommissionAmount,
      });
    // console.log(SELLER?.storeDetails?.type ,'data')
      setCivilIdFront(SELLER?.storeDetails?.CivilFrontId);
      setCivilIdImgFront(SELLER?.storeDetails?.civilFront !== null ? SELLER?.storeDetails?.civilFront : "");
      setCivilIdBack(SELLER?.storeDetails?.CivilBackId);
      setCivilIdImgBack(SELLER?.storeDetails?.civilBack !== null ? SELLER?.storeDetails?.civilBack : "");
      setOwnerId(SELLER?.storeDetails?.CivilOfOwnerId);
      setOwnerImg(SELLER?.storeDetails?.civilOfOwner !== null ? SELLER?.storeDetails?.civilOfOwner : "");
      setLicenseId(SELLER?.storeDetails?.CommercialLicenseId);
      setLicenseImg(SELLER?.storeDetails?.commercialLicense !== null ? SELLER?.storeDetails?.commercialLicense : "");
      setSignatureId(SELLER?.storeDetails?.SignatureAuthorizationId);
      setSignatureImg(SELLER?.storeDetails?.signatureAuthorization !== null ? SELLER?.storeDetails?.signatureAuthorization : "");
      setArticleAssoId(SELLER?.storeDetails?.ArticlesOfAssociationId);
      setArticleAssoImg(SELLER?.storeDetails?.articlesOfAssociation !== null ? SELLER?.storeDetails?.articlesOfAssociation : "");
      setInstagramId(SELLER?.storeDetails?.InstagramId);
      setInstagramImg(SELLER?.storeDetails?.instagram !== null ? SELLER?.storeDetails?.instagram : "");
      setAgreementId(SELLER?.storeDetails?.AgreementId);
      setAgreementImg(SELLER?.storeDetails?.agreement !== null ? SELLER?.storeDetails?.agreement : "");
      setOtherMediaId(SELLER?.storeDetails?.OtherMediaId);
      setOtherMediaImg(SELLER?.storeDetails?.otherMedia !== null ? SELLER?.storeDetails?.otherMedia : "");
    }
  }, [params.id, SellerList]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const data = {
      shopName: formData.shopName,
      type: formData.type,
      userName: formData.userName,
      mobile: formData.mobile,
      userId: params.id,
      email: formData.email,
      myfatoorahStatus: formData.myfatoorahStatus === "true" ? true : false,
      address: formData.address,
      aboutBusiness: formData.aboutBusiness,
      bankName: formData.bankName,
      IBAN: formData.IBAN,
      bankAccountHolderName: formData.bankAccountHolderName,
      bankAccount: formData.bankAccount,
      isStoreVerified: formData.isStoreVerified,
      myFatoorahSellerCode: formData.myFatoorahSellerCode,
      mapUrl: formData.mapUrl,
      lat: formData.lat,
      long: formData.long,
      civilFrontId: civilIdFront ? civilIdFront : null,
      civilBackId: civilIdBack ? civilIdBack : null,
      commercialLicenseId: licenseId ? licenseId : null,
      signatureAuthorizationId: signatureId ? signatureId : null,
      instagramId: instagramId ? instagramId : null,
      agreementId: agreementId ? agreementId : null,
      otherMediaId: otherMediaId ? otherMediaId : null,
      articlesOfAssociationId: articleAssoId ? articleAssoId : null,
      civilOfOwnerId: ownerId ? ownerId : null,
      // civilOfManagerId:this.state.managerId,
      commissionPercentage: parseFloat(formData.commissionPercentage),
      fixedCommissionAmount: parseFloat(formData.fixedCommissionAmount),
    };

    // console.log("Submitting data:", data);
    dispatch(sellerUpdate(data));
  };

  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a className={`nav-link ${tabID === "businessInformation" ? "active" : ""}`}
                        data-toggle="tab" href="#businessInformation" role="tab" onClick={() => handleTabChange("businessInformation")}>
                        <span className="d-none d-md-block"> Business Information</span>
                        <span className="d-block d-md-none"> <i className="mdi mdi-home-variant h5"></i></span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${tabID === "pickUpDetails" ? "active" : ""}`}
                        data-toggle="tab" href="#pickUpDetails" role="tab" onClick={() => handleTabChange("pickUpDetails")}>
                        <span className="d-none d-md-block">Pick Up Details</span>
                        <span className="d-block d-md-none"> <i className="mdi mdi-account h5"></i></span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${tabID === "civilIds" ? "active" : ""}`}
                        data-toggle="tab" href="#civilIds" role="tab" onClick={() => handleTabChange("civilIds")}>
                        <span className="d-none d-md-block">Civil Ids Information</span>
                        <span className="d-block d-md-none"><i className="mdi mdi-account h5"></i></span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${tabID === "businessDocuments" ? "active" : ""}`}
                        data-toggle="tab" href="#businessDocuments" role="tab" onClick={() => handleTabChange("businessDocuments")}>
                        <span className="d-none d-md-block"> Business Documents</span>
                        <span className="d-block d-md-none"><i className="mdi mdi-account h5"></i></span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${tabID === "paymentGatewaySetup" ? "active" : ""}`}
                        data-toggle="tab" href="#paymentGatewaySetup" role="tab" onClick={() => handleTabChange("paymentGatewaySetup")} >
                        <span className="d-none d-md-block">Payment Information</span>
                        <span className="d-block d-md-none"> <i className="mdi mdi-account h5"></i></span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${tabID === "partiesAgreement" ? "active" : ""}`}
                        data-toggle="tab" href="#partiesAgreement" role="tab" onClick={() => handleTabChange("partiesAgreement")}>
                        <span className="d-none d-md-block">3 Parties Agreement</span>
                        <span className="d-block d-md-none"><i className="mdi mdi-account h5"></i> </span>
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content mt-3">

                    <div className={`tab-pane ${tabID === "businessInformation" ? "active" : ""} p-3`} role="tabpanel">
                      <div className="form-group col-md-6">
                        <label>Business Name</label>
                        <input type="text" className="form-control" placeholder="Enter Business Name" name="shopName" value={formData.shopName} onChange={handleChange} />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Business Type</label>
                        <div className="col-md-13">
                          <select className="form-control" name="type" value={formData.type} onChange={handleChange}>
                            <option value="Company">Company</option>
                            <option value="Establishment">Establishment</option>
                            <option value="Home Business">Home Business</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label>Email</label>
                        <input type="text" className="form-control" placeholder="Enter Email" name="email" value={formData.email} onChange={handleChange} />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Mobile Number</label>
                        <input type="text" className="form-control" placeholder="Mobile Number" name="mobile" value={formData.mobile} onChange={handleChange} />
                      </div>
                      <div className="form-group col-md-6">
                        <label>My Fatoorah Seller Code</label>
                        <input type="text" className="form-control" placeholder="My Fatoorah SellerCode." name="myFatoorahSellerCode"
                          value={formData.myFatoorahSellerCode} onChange={handleChange} />
                      </div>
                      <div className="form-group col-md-6">
                        <label>About Business</label>
                        <input type="text" className="form-control" placeholder="About Business."
                          name="aboutBusiness" value={formData.aboutBusiness} onChange={handleChange} />
                      </div>
                      <div className="form-group col-md-6">
                        <label>My fatoorah Status</label>
                        <div className="col-md-13">
                          <select className="form-control" name="myfatoorahStatus" value={formData.myfatoorahStatus} onChange={handleChange}>
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label>Store Verified</label>
                        <div className="col-md-13">
                          <select className="form-control" name="isStoreVerified" value={formData.isStoreVerified} onChange={handleChange}>
                            <option value="true">True</option>
                            <option value="false">False</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label>Fixed Commission Amount</label>
                        <input type="number" className="form-control" name="fixedCommissionAmount" value={formData.fixedCommissionAmount} onChange={handleChange} />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Commission Percentage</label>
                        <input type="number" className="form-control" name="commissionPercentage" value={formData.commissionPercentage} onChange={handleChange} />
                      </div>
                      <div className="form-group row">
                        <div className="col-md-3">
                          <label> Instagram(account screenshot)</label>
                          <input type="file" accept="image/png, image/gif, image/jpeg, application/pdf , .doc, .docx"
                            className="form-control" onChange={onInstagramChange} />
                        </div>
                        {instagramImg !== "" && (
                          <div className="col-md-9">
                            <div className="imgDiv">
                              <img src={instagramImg ? instagramImg.toString() : ''}></img>
                              {/* <a href={instagramImg} target="_blank" rel="noopener noreferrer"> {instagramImg}</a> */}
                              <button onClick={() => { setInstagramId(""); setInstagramImg(""); }}>
                                REMOVE
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="form-group row">
                        <div className="col-md-3">
                          <label> Other Media</label>
                          <input type="file" accept="image/png, image/gif, image/jpeg, application/pdf , .doc, .docx"
                            className="form-control" onChange={onOtherMediaChange} />
                        </div>
                        {otherMediaImg !== "" && (
                          <div className="col-md-9">
                            <div className="imgDiv">
                              <img src={otherMediaImg ? otherMediaImg.toString() : ''}></img>
                              <a href={otherMediaImg} target="_blank" rel="noopener noreferrer"> {otherMediaImg}</a>
                              <button onClick={() => { setOtherMediaId(""); setOtherMediaImg(""); }} >
                                REMOVE
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={`tab-pane ${tabID === "pickUpDetails" ? "active" : ""} p-3`} role="tabpanel">
                      <div className="form-group col-md-6">
                        <label>Address</label>
                        <textarea type="text" className="form-control" placeholder="Address." name="address" value={formData.address} onChange={handleChange} />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Latitude</label>
                        <input type="number" className="form-control" name="lat" value={formData.lat} onChange={handleChange} />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Longitude</label>
                        <input type="number" className="form-control" name="long" value={formData.long} onChange={handleChange} />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Map Url</label>
                        <input type="text" className="form-control" placeholder="Map Url" name="mapUrl" value={formData.mapUrl} onChange={handleChange} />
                      </div>
                    </div>

                    <div className={`tab-pane ${tabID === "civilIds" ? "active" : ""} p-3`} role="tabpanel">
                      <div className="form-group row">
                        <div className="col-md-3">
                          <label>Civil ID of Manager (Front)</label>
                          <input type="file" accept="image/png, image/gif, image/jpeg, application/pdf , .doc, .docx"
                            className="form-control" placeholder="Email." onChange={onCivilIdFrontChange} />
                        </div>
                        {civilIdImgFront !== "" && (
                          <div className="col-md-9">
                            <div className="imgDiv">
                              <img src={civilIdImgFront ? civilIdImgFront.toString() : ''}></img>
                              {/* <a href={civilIdImgFront} target="_blank" rel="noopener noreferrer"> {civilIdImgFront}</a> */}
                              <button onClick={() => { setCivilIdFront(""); setCivilIdImgFront(""); }}>
                                REMOVE
                              </button>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="form-group row">
                        <div className="col-md-3">
                          <label>Civil ID of Manager (Back)</label>
                          <input type="file" accept="image/png, image/gif, image/jpeg, application/pdf , .doc, .docx"
                            className="form-control" placeholder="Email." onChange={onCivilIdBackChange} />
                        </div>
                        {civilIdImgBack !== "" && (
                          <div className="col-md-9">
                            <div className="imgDiv">
                              <img src={civilIdImgBack ? civilIdImgBack.toString() : ''}></img>
                              {/* <a href={civilIdImgBack} target="_blank" rel="noopener noreferrer"> {civilIdImgBack}</a> */}
                              <button onClick={() => { setCivilIdBack(""); setCivilIdImgBack(""); }}>
                                REMOVE
                              </button>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="form-group row">
                        <div className="col-md-3">
                          <label>Civil ID of Owner</label>
                          <input type="file" accept="image/png, image/gif, image/jpeg, application/pdf , .doc, .docx"
                            className="form-control" onChange={onOwnerChange} />
                        </div>
                        {ownerImg !== "" && (
                          <div className="col-md-9">
                            <div className="imgDiv">
                              <img src={ownerImg ? ownerImg.toString() : ''}></img>
                              {/* <a href={ownerImg} target="_blank" rel="noopener noreferrer"> {ownerImg}</a> */}
                              <button onClick={() => { setOwnerId(""); setOwnerImg(""); }} >
                                REMOVE
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={`tab-pane ${tabID === "businessDocuments" ? "active" : ""} p-3`} role="tabpanel">
                      <div className="form-group row">
                        <div className="col-md-3">
                          <label>Commercial License</label>
                          <input type="file" accept="image/png, image/gif, image/jpeg, application/pdf , .doc, .docx"
                            className="form-control" onChange={onLicenseChange} />
                        </div>
                        {licenseImg !== "" && (
                          <div className="col-md-9">
                            <div className="imgDiv">

                              <img src={ownerImg ? ownerImg.toString() : ''}></img>

                              {/* <a href={licenseImg} target="_blank" rel="noopener noreferrer"> {ownerImg}</a> */}
                              <button onClick={() => { setLicenseId(""); setLicenseImg(""); }}>
                                REMOVE
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="form-group row">
                        <div className="col-md-3">
                          <label>Signature Authorization</label>
                          <input type="file" accept="image/png, image/gif, image/jpeg, application/pdf , .doc, .docx"
                            className="form-control" onChange={onSignatureChange} />
                        </div>
                        {signatureImg !== "" && (
                          <div className="col-md-9">
                            <div className="imgDiv">
                              <img src={signatureImg ? signatureImg.toString() : ''}></img>

                              {/* <a href={signatureImg} target="_blank" rel="noopener noreferrer"> {signatureImg}</a> */}
                              <button onClick={() => { setSignatureId(""); setSignatureImg(""); }}>
                                REMOVE
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={`tab-pane ${tabID === "paymentGatewaySetup" ? "active" : ""} p-3`} role="tabpanel">
                      <div className="form-group col-md-6">
                        <label>Bank Name</label>
                        <input type="text" className="form-control" placeholder="Bank Name" name='bankName'
                          value={formData.bankName} onChange={handleChange} />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Bank Account Holder Name</label>
                        <input type="text" className="form-control" placeholder="Bank Account Holder Name"
                          name='bankAccountHolderName' value={formData.bankAccountHolderName} onChange={handleChange} />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Bank Account</label>
                        <input type="text" className="form-control" placeholder="Bank Account"
                          name='bankAccount' value={formData.bankAccount} onChange={handleChange} />
                      </div>
                      <div className="form-group col-md-6">
                        <label>IBAN</label>
                        <input type="text" className="form-control" placeholder="IBAN No" name='IBAN'
                          value={formData.IBAN} onChange={handleChange} />
                      </div>
                    </div>

                    <div className={`tab-pane ${tabID === "partiesAgreement" ? "active" : ""} p-3`} role="tabpanel">
                      <div className="form-group row">
                        <div className="col-md-3">
                          <label> Articles-of-Association</label>
                          <input type="file" accept="image/png, image/gif, image/jpeg, application/pdf , .doc, .docx"
                            className="form-control" onChange={onArticleAssoChange} />
                        </div>
                        {articleAssoImg !== "" && (
                          <div className="col-md-9">
                            <div className="imgDiv">
                              <a href={articleAssoImg} target="_blank" rel="noopener noreferrer"> {articleAssoImg}</a>
                              <button onClick={() => { setArticleAssoId(""); setArticleAssoImg(""); }}>
                                REMOVE
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="form-group row">
                        <div className="col-md-3">
                          <label> 3 Parties’ Contract/Agreement</label>
                          <input type="file" accept="image/png, image/gif, image/jpeg, application/pdf , .doc, .docx"
                            className="form-control w-full" onChange={onAgreementChange} />
                        </div>
                        {agreementImg !== "" && (
                          <div className="col-md-9">
                            <div className="imgDiv">
                              <a href={agreementImg} target="_blank" rel="noopener noreferrer"> {agreementImg}</a>
                              <button onClick={() => { setAgreementId(""); setAgreementImg(""); }} >
                                REMOVE
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                  </div>
                  <div className="form-group">
                    <div className="d-flex justify-content-end ">
                      <button onClick={onSubmit} type="submit" className="btn btn-primary waves-effect waves-light mr-2" >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AUX>
  );
};

export default SellerEdit;
import Aux from '../../hoc/Aux_';
import Sidebar from './Sidebar';
import VendorSidebar from './VendorSidebar';
import Header from './Header';
import Footer from './Footer';
import React, {Component } from 'react';
import { withRouter } from 'react-router-dom';
import Login from '../../containers/MainContent/Login/Login';
import VendorLogin from '../../containers/MainContent/VendorLogin/VendorLogin';

class layout extends Component {
    state = {
        role: ''
    }
    render() {
        console.log("HOSTNAME: ", window.location.hostname)
        this.state.role = localStorage.getItem("role")
        console.log("ROLE: ", this.state.role) 
        // var auth = localStorage.getItem("Auth")
        return (
            <Aux>
                {localStorage.getItem("loged_In") === "true" ?
                    <main>
                        <div id="wrapper">
                            {this.props.sidebar ? this.state.role == 'vendor' ? <VendorSidebar /> : <Sidebar /> : null}
                            <div className="content-page">
                                <div className="content">
                                        {this.props.header ? <Header  /> : null}
                                        {this.props.children}
                                </div>
                                {this.props.footer ? <Footer /> : null}
                            </div>
                        </div>
                    </main> : 
                    window.location.hostname == "vendor.zeedco.co"  || window.location.hostname == "vendor-dev.zeedco.co" ? <VendorLogin /> : <Login/> 
                }  
            </Aux> 
        );
    }
}

export default withRouter(layout);


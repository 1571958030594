import React, { useEffect, useState } from 'react'
import AUX from '../../../hoc/Aux_';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {getCoupon, addCoupon , updateCoupon, getSellerDropdown} from '../../../redux/MainAction'
import moment from 'moment';
import Select from 'react-select';
import Swal from 'sweetalert2';

const CouponAdd = () => {
    const params = useParams()

    const [ sellValue , setSellerValue ] = useState([])
    const [ sellerOptions , setSellerOptions ] = useState([])
    const sellersList = useSelector(state=>state.Zeed.sellersList);

    const dispatch = useDispatch()
    const CouponList = useSelector(state=>state.Zeed.coupon)
    useEffect(()=>{
        getRolList()
    },[])
    
    const getRolList = ()=>{
        var DATA={
          startRange:'0',
          count:"1000"
        }
        dispatch(getCoupon(DATA))
        dispatch(getSellerDropdown(DATA))
    }

    
    const onSellerChange = (value) => {
        if(value){
            setSellerValue(value)
        }
    }

    useEffect(() => {
        if (sellersList?.length > 0) {
            var data = sellersList?.map(opt => {
                return {
                    label: opt?.userName,
                    value: opt?.id
                }
            })
            setSellerOptions(data)
        }
    }, [sellersList])

    const [coup , setCoup]=useState({
        "name": "",
        "couponCode": "",
        "description": "",
        "discountType": "",
        "discountValue": "",
        "minPurchaseAmount": "",
        "validFrom": "",
        "validTo": "",
        "maxUsers": "",
        "perUserLimit": "",
        "sellersList": [],
        "status": true,
        "display": true
    })

    const handleChange = (e) =>{
        const { value , name } = e.target
        let updatedCoup = { ...coup, [name]: value };
        setCoup(updatedCoup)
    }

    useEffect(()=>{
        if(params.coup_id){
            var spr = CouponList?.filter(itm=>itm.id===params.coup_id)
            const sellerList = spr[0]?.sellers || [];
            if (sellerList?.length) {
                const sellerTempArr = [];
                for (const elt of sellerList) {
                    // const checkSeller = sellersList?.find(ele => ele.id === elt);
                    // if (checkSeller) {
                        sellerTempArr.push({
                            label: elt.userName,
                            value: elt.id
                        });
                        onSellerChange(sellerTempArr)
                    // }
                }
            }
            setCoup({
                "name": spr[0]?.name,
                "couponCode": spr[0]?.couponCode,
                "description": spr[0]?.description,
                "discountType": spr[0]?.discountType,
                "discountValue": spr[0]?.discountValue,
                "minPurchaseAmount": spr[0]?.minPurchaseAmount,
                "validFrom": moment(spr[0]?.validFrom).format("YYYY-MM-DD"),
                "validTo": moment(spr[0]?.validTo).format("YYYY-MM-DD"),
                "maxUsers": spr[0]?.maxUsers,
                "perUserLimit": spr[0]?.perUserLimit,
                "status": spr[0]?.status,
                "display": spr[0]?.display
            })
        }
    },[params.coup_id])


    const onSubmit = (e)=>{
        e.preventDefault()
        if (!coup?.name) {
          showWarning("Please Add Name");
          return false;
        }
        if (!coup?.couponCode) {
          showWarning("Please Add Coupon Code");
          return false;
        }
        if (!coup?.discountValue) {
          showWarning("Please Add Discount Value");
          return false;
        }
        if (!coup?.minPurchaseAmount) {
          showWarning("Please Add Min Purchase Amount");
          return false;
        }
        if (!coup?.perUserLimit) {
          showWarning("Please Add Per User Limit");
          return false;
        }
        // if(coup?.minPurchaseAmount <= coup?.discountValue){
        //     showWarning("minPurchaseAmount cannot be less than discountValue");
        //     return false;
        // }
        var d = new Date(coup?.validFrom).getTime();
        var d1= new Date(coup?.validTo).getTime();
        const sellerArr = sellValue?.map(ele => ele.value) || [];
        if (sellerArr.length === 0) {
          showWarning("Please select seller");
          return false;
        }
        if (!d) {
          showWarning("Please select valid from date");
          return false;
        }
        if (!d1) {
          showWarning("Please select valid to date");
          return false;
        } 
        if(params.coup_id){
            var data ={
                "couponId":params?.coup_id,
                "name": coup?.name,
                "couponCode":  coup?.couponCode,
                "description":  coup?.description,
                "discountType":  coup?.discountType,
                "discountValue":  coup?.discountValue,
                "minPurchaseAmount":  coup?.minPurchaseAmount,
                "validFrom":  d,
                "validTo":  d1,
                "maxUsers":  coup?.maxUsers,
                "perUserLimit":  coup?.perUserLimit,
                "status":  coup?.status,
                "display":  coup?.display,
                "sellersList": sellerArr,
            }
            dispatch(updateCoupon(data))
        }else{
            var data ={
                "name": coup?.name,
                "couponCode":  coup?.couponCode,
                "description":  coup?.description,
                "discountType":  coup?.discountType,
                "discountValue":  coup?.discountValue,
                "minPurchaseAmount":  coup?.minPurchaseAmount,
                "validFrom":  d,
                "validTo":  d1,
                "maxUsers":  coup?.maxUsers,
                "perUserLimit":  coup?.perUserLimit,
                "status":  coup?.status,
                "display":  coup?.display,
                "sellersList": sellerArr,
            }
            dispatch(addCoupon(data))
        }
    }
    // console.log("date" , coup?.validFrom);

    function showWarning(message) {
      Swal.fire({
        title: "Warning",
        icon: "warning",
        text: message,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    }

    const today = new Date().toISOString().split('T')[0];
    
  return (
    <AUX>
    <div className="page-content-wrapper">
            <div className="container-fluid">

           <div className="row">
               <div className="col-lg-12">
                   <div className="card m-b-20">
                       <div className="card-body">
                               <form>
                                   <div className='row'>
                                       <div className="form-group col-md-6">
                                           <label>Coupon Name <span style={{ color: 'red' }}>*</span></label>
                                           <input type="text" className="form-control" placeholder="Coupon Name" name='name' value={coup.name} onChange={handleChange}/>
                                           {/* <span id="err">{this.state.name_err}</span> */}
                                       </div>
                                       <div className="form-group col-md-6">
                                           <label>Coupon Code <span style={{ color: 'red' }}>*</span></label>
                                           <input type="text" className="form-control" placeholder="Coupon Code" name='couponCode' value={coup.couponCode} onChange={handleChange}/>
                                           {/* <span id="err">{this.state.name_err}</span> */}
                                       </div>
                                       <div className="form-group col-md-6">
                                            <label>Description</label>
                                            <div>
                                                <textarea  className="form-control" name='description' value={coup.description} onChange={handleChange} rows="1"></textarea>
                                            </div>
                                        </div>

                                       <div className="form-group col-md-6">
                                           <label>Discount Value <span style={{ color: 'red' }}>*</span></label>
                                           <input type="number" className="form-control" placeholder="Discount Value" name='discountValue' value={coup.discountValue} onChange={handleChange}/>
                                           {/* <span id="err">{this.state.name_err}</span> */}
                                       </div>

                                       <div className="form-group col-md-6">
                                           <label>Minimum Purchase Amount <span style={{ color: 'red' }}>*</span></label>
                                           <input type="number" className="form-control" placeholder="Amount" name='minPurchaseAmount' value={coup.minPurchaseAmount} onChange={handleChange}/>
                                           {/* <span id="err">{this.state.name_err}</span> */}
                                       </div>

                                       <div className="form-group col-md-6">
                                           <label>Discount Type <span style={{ color: 'red' }}>*</span></label>
                                           <div className="col-md-13">
                                               <select className="form-control" name='discountType' value={coup.discountType} onChange={handleChange}>
                                               <option value="" disabled> Select a discount type</option>
                                                   <option value="amount">Amount</option>
                                                   <option value="percent">Percent</option>
                                               </select>
                                           </div>
                                       </div>

                                       <div className="form-group col-md-6">
                                           <label>Maximum Users</label>
                                           <input type="text" className="form-control" placeholder="Maximum Users" name='maxUsers' value={coup.maxUsers} onChange={handleChange}/>
                                           {/* <span id="err">{this.state.name_err}</span> */}
                                       </div>

                                       <div className="form-group col-md-6">
                                           <label>Valid From <span style={{ color: 'red' }}>*</span></label>
                                           <input type="date" className="form-control"  name='validFrom' value={coup.validFrom} min={today} onChange={handleChange}/>
                                           {/* <span id="err">{this.state.name_err}</span> */}
                                       </div>
                                       <div className="form-group col-md-6">
                                           <label>Valid To <span style={{ color: 'red' }}>*</span></label>
                                           <input type="date" className="form-control"  name='validTo' value={coup.validTo}   min={coup.validFrom || today} onChange={handleChange}/>
                                           {/* <span id="err">{this.state.name_err}</span> */}
                                       </div>
                                        <div className="form-group col-md-6">
                                            <div className="form-group mb-0">
                                                <label className="control-label">Select Sellers <span style={{ color: 'red' }}>*</span></label>
                                                <Select
                                                    onChange={onSellerChange}
                                                    options={sellerOptions}
                                                    value={sellValue}
                                                    isMulti={true}
                                                />
                                            </div>
                                        </div>
                                       <div className="form-group col-md-6">
                                           <label>Per UserLimit <span style={{ color: 'red' }}>*</span></label>
                                           <input type="text" className="form-control" placeholder='Per UserLimit' name='perUserLimit' value={coup.perUserLimit} onChange={handleChange}/>
                                           {/* <span id="err">{this.state.name_err}</span> */}
                                       </div>

                                       <div className="form-group col-md-6">
                                           <label>Status</label>
                                           <div className="col-md-13">
                                               <select className="form-control" name='status' value={coup.status} onChange={handleChange}>
                                                   <option value="true">Active</option>
                                                   <option value="false">InActive</option>
                                               </select>
                                           </div>
                                       </div>

                                       <div className="form-group col-md-6">
                                           <label>Display</label>
                                           <div className="col-md-13">
                                               <select className="form-control" name='display' value={coup.display} onChange={handleChange}>
                                               <option value="true">Active</option>
                                                   <option value="false">InActive</option>
                                               </select>
                                           </div>
                                       </div>
                                       
                                   </div>
                                   <div className="form-group">
                                       <div className='d-flex justify-content-end '>
                                           <button  onClick={onSubmit} type="submit" className="btn btn-primary waves-effect waves-light mr-2">
                                               Submit
                                           </button>
                                           <Link to='/coupon' className="btn btn-secondary waves-effect m-l-5">
                                               Cancel
                                           </Link>
                                       </div>
                                   </div>
                           </form>
                       </div>
                   </div>
               </div> 

           </div> 
       </div> 
   </div>
    </AUX>
  )
}

export default CouponAdd
import React, { useEffect, useState } from 'react'
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import { getBankList } from '../../../redux/MainAction'
import axios from "axios";
import { GET_BANK_LIST } from "../../../redux/type";
import Constant from "../../../Constant";
import { setLoading } from "../../../redux/MainAction";

var config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("loged_In_auth"),
    },
};

const BankList = () => {
    const dispatch = useDispatch()
    const BnkList = useSelector(state=>state.Zeed.banks)
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20);

    useEffect(()=>{
        dispatch(setLoading(true));
        fetchPageData(1);
        // getRolList()
      },[])
    
      const fetchPageData = (pageNum) => {
        const DATA = {
            startRange: (pageNum - 1) * perPage,
            count: perPage,
            page: pageNum,
        };
        axios
            .post(Constant.getAPI() + "/bank/backend-myfatoorahBankList", DATA, config)
            .then((res) => {
                // window.setLoading(true)
                dispatch({
                    type: GET_BANK_LIST,
                    payload: res.data.data,
                    totalRows: res.data.totalRows
                });
                setTotalRows(res.data.totalRows)
                dispatch(setLoading(false));
            })
            .catch((err) => {
                console.log(err)
                dispatch(setLoading(false))
            }
            );
        setCurrentPage(pageNum);
    };
    const handlePageChange = (pageNum) => {
        dispatch(setLoading(true));
        fetchPageData(pageNum);
    };

    const handlePerPageChange = (event) => {
        setPerPage(Number(event.target.value));
        fetchPageData(1);
    };
    // const getRolList = ()=>{
    //     var DATA={
    //       startRange:'0',
    //       count:"1000"
    //     }
    //     dispatch(getBankList(DATA))
    // }
    // console.log("BnkList",BnkList);
    const columns =[
            {
                label: "Bank Name",
                field: "Text", 
            },
    ]
    const rows = BnkList.map((itm)=>{return {
        Text: itm.Text
    }})

    const data = {
        columns,
        rows,
    };
    const totalPages = Math.ceil((totalRows ? totalRows : 0) / perPage);
  return (
    <AUX>
        <div className="page-content-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card m-b-20">
                            <div className="card-body">
                               {/* <div className='d-flex justify-content-end'>
                               <Link to='/wallet/add'>
                                <Button size="sm" color="secondary" onClick >Add Roles</Button>
                               </Link>
                               </div> */}
                                <MDBDataTable
                                    bordered
                                    theadColor="#2632384"
                                    paging={false}
                                    hover
                                    entries={20}
                                    data={data}
                                />
                                  <div className="pagination-controls">
                                      <button className="pagination-btn first-btn" onClick={() => handlePageChange(1)} disabled={currentPage === 1}>First</button>
                                      <button className="pagination-btn prev-btn" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                      <span>Page {currentPage} of {totalPages}</span>
                                      <button className="pagination-btn next-btn" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                                      <button className="pagination-btn last-btn" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>Last</button>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div> 
        </div>
    </AUX>
  )
}

export default BankList
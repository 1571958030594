 import React, { useEffect, useState } from 'react';
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeSlot , deleteTimeSlot , editTimeSlot } from '../../../redux/MainAction'
import moment from 'moment';
import axios from 'axios';
import { setLoading } from '../../../redux/MainAction';
import { GET_REPORTED_POSTS, GET_TIMESLOT } from '../../../redux/type';
import Constant from '../../../Constant';
import Toggle from 'react-toggle';

const config = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loged_In_auth'),
    },
};

const TimeSlotList = () => {
    const dispatch = useDispatch();
    const timeslotList = useSelector((state) => state.Zeed.timeslot);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20);

    useEffect(() => {
        dispatch(setLoading(true));
        fetchPageData(1);
    }, []);

    const fetchPageData = (pageNum) => {
        const DATA = {
            startRange: (pageNum - 1) * perPage,
            count: perPage,
            page: pageNum,
        };
        axios
            .post(Constant.getAPI() + '/config/backend-getAll-timeSlot', DATA, config)
            .then((res) => {
                dispatch({
                    type: GET_TIMESLOT,
                    payload: res.data.data,
                    totalRows: res.data.totalRows,
                });
                setTotalRows(res.data.totalRows);
                dispatch(setLoading(false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(setLoading(false));
            });
        setCurrentPage(pageNum);
    };

    const handlePageChange = (pageNum) => {
        dispatch(setLoading(true));
        fetchPageData(pageNum);
    };

    const columns = [
        {
            label: "Time slot",
            field: "slot", 
        },
        {
            label: "Status",
            field: "isActive", 
        },
        {
            label: "Action",
            field: "id", 
        },
    ];

    const rows = timeslotList.map((timeslot) => ({
        slot: timeslot.slot,
        isActive: <Toggle defaultChecked={timeslot.isActive} value={timeslot.isActive}  onChange={()=>handleChange(timeslot.id,timeslot.slot,timeslot.isActive)}/>,
        id:<><Link to={'/time-slot/edit/' + timeslot.id} title="Edit" className="mdi mdi-grease-pencil"></Link></>,
    }));

    const data = {
        columns,
        rows,
    };

    const handleDelete = (id)=>{
        var data ={
            slotId: id
        }
        dispatch(deleteTimeSlot(data))
    }

    const handleChange = (id , slot , isActive)=>{
        var data ={
            slotId:id,
            slot:slot,
            isActive: !isActive
        }
        dispatch(editTimeSlot(data))
    }

    const totalPages = Math.ceil((totalRows ? totalRows : 0) / perPage);

    return (
        <AUX>
            <div className="page-content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <MDBDataTable
                                        bordered
                                        theadColor="#2632384"
                                        paging={false}
                                        hover
                                        entries={20}
                                        data={data}
                                    />
                                    <div className="pagination-controls">
                                        <button
                                            className="pagination-btn first-btn"
                                            onClick={() => handlePageChange(1)}
                                            disabled={currentPage === 1}
                                        >
                                            First
                                        </button>
                                        <button
                                            className="pagination-btn prev-btn"
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1}
                                        >
                                            Previous
                                        </button>
                                        <span>
                                            Page {currentPage} of {totalPages}
                                        </span>
                                        <button
                                            className="pagination-btn next-btn"
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            disabled={currentPage === totalPages}
                                        >
                                            Next
                                        </button>
                                        <button
                                            className="pagination-btn last-btn"
                                            onClick={() => handlePageChange(totalPages)}
                                            disabled={currentPage === totalPages}
                                        >
                                            Last
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style>{`
                .pagination-controls {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-top: 20px;
                }
                select {
                    width: 27%;
                }
                .pagination-btn {
                    background-color: #263238;
                    color: white;
                    border: none;
                    padding: 10px 20px;
                    margin: 0 5px;
                    cursor: pointer;
                    border-radius: 5px;
                    font-size: 14px;
                }
                .pagination-btn:disabled {
                    background-color: #cccccc;
                    cursor: not-allowed;
                }
                .pagination-btn:not(:disabled):hover {
                    background-color: #263233;
                }
            `}</style>
        </AUX>
    );
};

export default TimeSlotList;

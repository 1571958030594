import React, { useEffect, useState } from 'react';
import AUX from '../../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Constant from '../../../../Constant'; 
import { getState, setLoading } from '../../../../redux/MainAction';
import { GET_STATE } from '../../../../redux/type';
import Toggle from 'react-toggle';
import Swal from 'sweetalert2';

var config = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loged_In_auth'),
    },
};

const State = () => {
    const dispatch = useDispatch();
    const stateList = useSelector(state => state.Zeed.state);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20);

    useEffect(() => {
        dispatch(setLoading(true));
        fetchPageData(1);
    }, []);

    const fetchPageData = (pageNum) => {
        const DATA = {
            startRange: (pageNum - 1) * perPage,
            count: perPage,
            page: pageNum,
        };
        axios
            .post(Constant.getAPI() + '/state/backend-getAll-state', DATA, config)
            .then((res) => {
                dispatch({
                    type: GET_STATE,
                    payload: res.data.data,
                    totalRows: res.data.totalRows,
                });
                setTotalRows(res.data.totalRows);
                dispatch(setLoading(false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(setLoading(false));
            });
        setCurrentPage(pageNum);
    };

    const handlePageChange = (pageNum) => {
        dispatch(setLoading(true));
        fetchPageData(pageNum);
    };

    const columns = [
        {
            label: "State Name",
            field: "name", 
        },
        {
            label: "Country Name",
            field: "country", 
        },
        {
            label: "Status",
            field: "status", 
        },
        {
            label: "Visibility",
            field: "visibility", 
        },
        {
            label: "Action",
            field: "id", 
        },                 
    ];

    const rows = stateList.map((state) => ({
        name: state.name,
        country: state && state.Country ? state?.Country.name : "",
        status: (
            <Toggle
                checked={state.status}
                onChange={() => handleStatusChange(state.id)}
            />
        ),
        visibility: (
            <Toggle
                checked={state.visibility}
                onChange={() => handleVisibilityChange(state.id)}
            />
        ),
        id: <Link to={"/state/edit/" + state?.id} title="Edit" className="mdi mdi-grease-pencil"></Link>,
    }));

    const data = {
        columns,
        rows,
    };

    const handleStatusChange = (id) => {
        var data = {
            id: id,
        };
        axios
            .post(Constant.getAPI() + '/state/toggleStateStatus', data, config)
            .then(res => {
                if (res.data.status) {
                    var data = {
                        startRange: '0',
                        count: "1000",
                    };
                    dispatch(getState(data));
                    Swal.fire({
                        title: "Updated",
                        text: "State Status has been updated",
                        icon: "success",
                        confirmButtonColor: "#3ab1f7",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Ok",
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleVisibilityChange = (id) => {
        var data = {
            id: id,
        };
        axios
            .post(Constant.getAPI() + '/state/toggleStateVisibility', data, config)
            .then(res => {
                if (res.data.status) {
                    var data = {
                        startRange: '0',
                        count: '1000',
                    };
                    dispatch(getState(data));
                    Swal.fire({
                        title: "Updated",
                        text: "State visibility has been updated",
                        icon: "success",
                        confirmButtonColor: "#3ab1f7",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Ok",
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    const totalPages = Math.ceil((totalRows ? totalRows : 0) / perPage);

    return (
        <AUX>
            <div className="page-content-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <MDBDataTable
                                        bordered
                                        theadColor="#2632384"
                                        paging={false}
                                        hover
                                        entries={20}
                                        data={data}
                                    />
                                    <div className="pagination-controls">
                                        <button
                                            className="pagination-btn first-btn"
                                            onClick={() => handlePageChange(1)}
                                            disabled={currentPage === 1}
                                        >
                                            First
                                        </button>
                                        <button
                                            className="pagination-btn prev-btn"
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1}
                                        >
                                            Previous
                                        </button>
                                        <span>
                                            Page {currentPage} of {totalPages}
                                        </span>
                                        <button
                                            className="pagination-btn next-btn"
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            disabled={currentPage === totalPages}
                                        >
                                            Next
                                        </button>
                                        <button
                                            className="pagination-btn last-btn"
                                            onClick={() => handlePageChange(totalPages)}
                                            disabled={currentPage === totalPages}
                                        >
                                            Last
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style>{`
                .pagination-controls {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-top: 20px;
                }
                select {
                    width: 27%;
                }
                .pagination-btn {
                    background-color: #263238;
                    color: white;
                    border: none;
                    padding: 10px 20px;
                    margin: 0 5px;
                    cursor: pointer;
                    border-radius: 5px;
                    font-size: 14px;
                }
                .pagination-btn:disabled {
                    background-color: #cccccc;
                    cursor: not-allowed;
                }
                .pagination-btn:not(:disabled):hover {
                    background-color: #263233;
                }
            `}</style>
        </AUX>
    );
};

export default State;


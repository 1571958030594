import React, {Component ,useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';  
import Axios from 'axios';
import { GET_NOTIFICATIONS } from '../../redux/type';
import Constant from '../../Constant';
import ting from './ting.mp3'
var config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("loged_In_auth"),
    },
};
const role = localStorage.getItem("role");

class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      dropdownOpen1: false,
      dropdownOpenprofile: false,
      dropdownOpenbadge: false,
      now_route: "",
      notificationCount: "",
      notifications: [],
      notifications1: [],
    };

    this.toggle = this.toggle.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.toggleprofile = this.toggleprofile.bind(this);
    this.togglebadge = this.togglebadge.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }
  toggle1() {
    this.setState((prevState) => ({
      dropdownOpen1: !prevState.dropdownOpen1,
    }));
  }
  toggleprofile() {
    this.setState((prevState) => ({
      dropdownOpenprofile: !prevState.dropdownOpenprofile,
    }));
  }
  togglebadge() {
    this.setState((prevState) => ({
      dropdownOpenbadge: !prevState.dropdownOpenbadge,
    }));
  }

  async onClickNotification(notificationId) {
    try {
      const response = await Axios.post(`${Constant.getAPI()}/notification/fetchDetails`, { id: notificationId }, config);
      const redirectPath = `/orders/${role === "admin" ? response.data.data.orderMasterId : response.data.data.orderSellerId
        }`;
      window.location.href = redirectPath;
    } catch (error) {
      console.log(error);
    }
  }

  togglescreen(e) {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  componentDidMount() {
    this.setState({ now_route: this.props.location.pathname });
    this.fetchNotifications();

    // Fetch unseen notifications after a delay of 1 second
    const unseenNotificationInterval = setInterval(() => {
        this.fetchUnseenNotification();
        this.fetchNotifications();
      }, 5000); // Fetch every 10 seconds (adjust as needed)
  
      this.setState({ unseenNotificationInterval });
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted
    clearInterval(this.state.unseenNotificationInterval);
  }

  fetchUnseenNotification() {
    Axios.post(
      Constant.getAPI() + "/notification/backend-unseenNotificationListing",
      {},
      config
    )
      .then((res) => {
        // console.log(res.data.count)
        if(res.data.count > 0){
            // alert('mp3')
            const audio = new Audio(ting);
            // const audio = new Audio('/assets/data/ting.mp3');
            audio.play().then(() => {
              console.log('Audio played successfully');
            }).catch(error => {
              console.error("Error playing audio:", error);
            });
        }
        // this.setState({ notifications1: res.data.data });
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      });
  }

  fetchNotifications() {
    Axios.post(
      Constant.getAPI() + "/notification/backend-headerListing",
      {},
      config
    )
      .then((res) => {
        this.setState({ notifications: res.data.data, notificationCount: res.data.count });
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      });
  }

  onLogout = () => {
    // console.log("CLICKED");
    localStorage.clear();
    window.location.reload();
  };

  render() {
    let userInitials;
    let name;
    let role = localStorage.getItem("role");
    if (role == "admin") {
      name = localStorage.getItem("ad_name");
    } else {
      name = localStorage.getItem("firstName");
    }
    if (name) {
      userInitials = name ? name.charAt(0).toUpperCase() : "";
    }
    // console.log("dropdownOpenprofile",this.state.dropdownOpenprofile);
    const latestNotifications = this.state.notifications.slice(0, 10).reverse();
    return (
      <div className="topbar">
        <nav className="navbar-custom">
          {/* <div className="search-wrap" id="search-wrap">
                                <div className="search-bar">
                                    <input className="search-input" type="search" placeholder="Search" />
                                    <a href="#" className="close-search toggle-search" data-target="#search-wrap">
                                        <i className="mdi mdi-close-circle"></i>
                                    </a>
                                </div>
                            </div> */}

          <ul className="list-inline float-right mb-0">
            {/* <li className="list-inline-item dropdown notification-list">
                                    <Link  className="nav-link waves-effect toggle-search" to="#"  data-target="#search-wrap">
                                        <i className="mdi mdi-magnify noti-icon"></i>
                                    </Link>
                                </li> */}
            <li className="list-inline-item dropdown notification-list hidden-xs-down">
              <Link
                onClick={() => this.togglescreen()}
                className="nav-link waves-effect"
                to="#"
                id="btn-fullscreen"
              >
                <i className="mdi mdi-fullscreen noti-icon"></i>
              </Link>
            </li>
            <li className="list-inline-item dropdown notification-list hidden-xs-down">
              {/* <Dropdown isOpen={this.state.dropdownOpen1}  toggle={this.toggle1}>
                                    <DropdownToggle  className="nav-link dropdown-toggle arrow-none waves-effect text-muted" tag="a">
                                        <span style={{color:"#707070"}}>English</span> <img src="assets/images/flags/us_flag.jpg" className="ml-2" height="16" alt="" />
                                    </DropdownToggle>
                                    <DropdownMenu className='dropdown-menu dropdown-menu-right language-switch'>
                                    <a className="dropdown-item" href="#"><img src="assets/images/flags/germany_flag.jpg" alt="" height="16"/><span> German </span></a>
                                    <a className="dropdown-item" href="#"><img src="assets/images/flags/italy_flag.jpg" alt="" height="16"/><span> Italian </span></a>
                                    <a className="dropdown-item" href="#"><img src="assets/images/flags/french_flag.jpg" alt="" height="16"/><span> French </span></a>
                                    <a className="dropdown-item" href="#"><img src="assets/images/flags/spain_flag.jpg" alt="" height="16"/><span> Spanish </span></a>
                                    <a className="dropdown-item" href="#"><img src="assets/images/flags/russia_flag.jpg" alt="" height="16"/><span> Russian </span></a>
                                   </DropdownMenu>
                                </Dropdown> */}
            </li>
            <li className="list-inline-item dropdown notification-list">
              <Dropdown
                isOpen={this.state.dropdownOpenbadge}
                toggle={this.togglebadge}
              >
                <DropdownToggle
                  className="nav-link dropdown-toggle droptest arrow-none waves-effect"
                  tag="a"
                >
                  <i className="ion-ios7-bell noti-icon"></i>
                  {this.state.notificationCount > 0 && (
                    <span className="badge badge-danger badge-pill">
                      {this.state.notificationCount}
                    </span>
                  )}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-menu-right dropdown-menu-lg">
                  <Scrollbars
                    style={{ height: "200px" }}
                    className="notification-item-list"
                  >
                    {latestNotifications
                    .sort((a, b) => (b.createdAt) - (a.createdAt))
                    .map((notification) => (
                      <Link
                        key={notification.id}
                        to="#"
                        className="dropdown-item notify-item active"
                        onClick={() => this.onClickNotification(notification.id)}
                      >
                        <div className="notify-icon bg-success">
                          <i className="mdi mdi-cart-outline"></i>
                        </div>
                        <p className="notify-details">
                          {notification.type == "orderPlaced" ? "New order" : ""}
                          <span className="text-muted">
                            {notification.text}
                          </span>
                        </p>
                      </Link>
                    ))}
                  </Scrollbars>
                  <Link
                    to="/push-notification"
                    className="dropdown-item text-center text-primary"
                  >
                    View all <i className="fi-arrow-right"></i>
                  </Link>
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="list-inline-item dropdown notification-list">
              <Dropdown
                isOpen={this.state.dropdownOpenprofile}
                toggle={this.toggleprofile}
              >
                <DropdownToggle
                  className="nav-link dropdown-toggle droptest arrow-none waves-effect nav-user"
                  tag="a"
                >
                  {/* <img src="assets/images/users/avatar-4.jpg" alt="user" className="rounded-circle" /> */}
                  <div className="avatar rounded-circle d-flex justify-content-center align-items-center">
                    {userInitials}
                  </div>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <Link id="ex" to="/profile-update">
                      <i className="mdi mdi-account-circle m-r-5"></i> Profile
                    </Link>
                  </DropdownItem>
                  {/* <DropdownItem>
                                            <Link id="ex" to="/wallet-transaction">
                                                <i className="mdi mdi-wallet m-r-5"></i> My Wallet
                                            </Link>
                                        </DropdownItem> */}
                  {/* <DropdownItem><span className="badge badge-success float-right">11</span><i className="mdi mdi-settings m-r-5"></i> Settings</DropdownItem> */}
                  {/* <DropdownItem><i className="mdi mdi-lock-open-outline m-r-5"></i> Lock screen</DropdownItem> */}
                  <DropdownItem onClick={this.onLogout}>
                    <i className="mdi mdi-power text-danger"></i> Logout
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>
          </ul>

          <ul className="list-inline menu-left mb-0">
            <li className="list-inline-item">
              <button
                type="button"
                className="button-menu-mobile open-left waves-effect"
              >
                <i className="ion-navicon"></i>
              </button>
            </li>
            <li className="hide-phone list-inline-item app-search">
              <h3 className="page-title" id="now_routing"></h3>
            </li>
          </ul>
          <div className="clearfix"></div>
        </nav>
      </div>
    );
  }
}

export default withRouter(header);
import React, { useEffect, useState } from 'react'
import AUX from '../../../hoc/Aux_';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {getPackage, addPackage , updatePackage} from '../../../redux/MainAction'
import Swal from 'sweetalert2';

const PackageAdd = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const PackList = useSelector(state=>state.Zeed.package)

    const [pack , setPack]=useState({
        name: "",
        amount: "",
        currency: "",
        type: "fixed",
    })

    const nameOptions = [
        'normalBiddingPostPrice',
        'liveBiddingPostPrice',
        'biddingPostRegistrationPrice',
        'normalPostServiceCharge',
        'deliveryCharges',
        'minimumPostIncrementPrice',
        'bookingCharge'
    ];
    
    useEffect(()=>{
        getRolList()
    },[])
    
    const getRolList = ()=>{
        var DATA={
          startRange:'0',
          count:"1000"
        }
        dispatch(getPackage(DATA))
    }

    useEffect(()=>{
        if(params.pack_id){
            var spr = PackList?.filter(itm=> itm?.id === params?.pack_id)
            // console.log("spr",spr);
            setPack({
                name: spr[0]?.name,
                amount: spr[0]?.amount,
                currency: spr[0]?.currency,
                type: spr[0]?.type,  
            })
        }
    },[params.pack_id])

    const handleChange = (e) =>{
        const { value , name } = e.target
        setPack({...pack,[name]: value})
    }

    const onSubmit = (e)=>{
        e.preventDefault()
        if (pack?.name === "") {
            Swal.fire({
                title: "Warning",
                icon: "warning",
                text: "Please Add Name",
                type: "warning",
                confirmButtonColor: "#3ab1f7",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
            });
            return false;
        }
        if (pack?.amount === "") {
            Swal.fire({
                title: "Warning",
                icon: "warning",
                text: "Please Add Amount",
                type: "warning",
                confirmButtonColor: "#3ab1f7",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
            });
            return false;
        } 
        if (pack?.currency === "") {
            Swal.fire({
                title: "Warning",
                icon: "warning",
                text: "Please Add Currency",
                type: "warning",
                confirmButtonColor: "#3ab1f7",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
            });
            return false;
        } 
       if(params.pack_id){
         var data ={
            PackageId:params.pack_id,
            name: pack?.name,
            amount: pack?.amount,
            currency: pack?.currency,
            type: pack?.type,
         }
         dispatch(updatePackage(data))
       }else{
        var data ={ 
            name: pack?.name,
            amount: pack?.amount,
            currency: pack?.currency,
            type: pack?.type,
        }
        dispatch(addPackage(data))
        // console.log("data", data);
       }
    }

    // console.log("params",params.pack_id);
  return (
    <AUX>
    <div className="page-content-wrapper">
            <div className="container-fluid">

           <div className="row">
               <div className="col-lg-12">
                   <div className="card m-b-20">
                       <div className="card-body">
                               <form onSubmit={onSubmit}>
                                   <div className='row'>
                                   <div className="form-group col-md-6">
                                                <label>Name <span style={{ color: 'red' }}>*</span></label>
                                                <select className="form-control" name='name' value={pack.name} onChange={handleChange}>
                                                    <option value="">Select Name</option>
                                                    {nameOptions.map((option, index) => (
                                                        <option key={index} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                       <div className="form-group col-md-6">
                                           <label>Amount <span style={{ color: 'red' }}>*</span></label>
                                           <input type="number" className="form-control" placeholder="Package amount" name='amount' value={pack.amount} onChange={handleChange}/>
                                           {/* <span id="err">{this.state.name_err}</span> */}
                                       </div>
                                       <div className="form-group col-md-6">
                                           <label>Currency</label>
                                           <label>Currency <span style={{ color: 'red' }}>*</span></label>
                                           <input type="text" className="form-control" placeholder="Package currency" name='currency' value={pack.currency} onChange={handleChange}/>
                                           {/* <span id="err">{this.state.name_err}</span> */}
                                       </div>
                                       <div className="form-group col-md-6">
                                           <label>Type</label>
                                           <div className="col-md-13">
                                               <select className="form-control" name='type' value={pack.type} onChange={handleChange}>
                                                   <option value="fixed">Fixed</option>
                                                   <option value="percent">Percent</option>
                                               </select>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="form-group">
                                       <div className='d-flex justify-content-end '>
                                           <button  onClick={"this.handleSubmit1"} type="submit" className="btn btn-primary waves-effect waves-light mr-2">
                                               Submit
                                           </button>
                                           <Link to='/package' className="btn btn-secondary waves-effect m-l-5">
                                               Cancel
                                           </Link>
                                       </div>
                                   </div>
                           </form>
                       </div>
                   </div>
               </div> 

           </div> 
       </div> 
   </div>
    </AUX>
  )
}

export default PackageAdd
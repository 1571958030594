import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import AUX from "../../../hoc/Aux_";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getallUsersBackend } from "../../../redux/MainAction";

const UsersView = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.Zeed.userList);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);


  useEffect(() => {
    getUserList();
  }, []);

  // const getUserList = () => {
  //   var DATA = {
  //     startRange: "0",
  //     count: "1000",
  //   };
  //   dispatch(getallUsers(DATA));
  // };

  const getUserList = (pageNum) => {
    const DATA = {
      startRange: (pageNum - 1) * perPage,
      count: perPage,
      page: pageNum,
    };
    dispatch(getallUsersBackend(DATA));
  };
  
  const [coup, setCoup] = useState({
    userName: "",
    firstName: "",
     lastName: "",
    email: "",
    mobileNumber: "",
    deviceType: "",
  });

  useEffect(() => {
    if (params.id && userList.length > 0) {
      const spr = userList.find((itm) => itm.id === params.id);
      if (spr) {
        setCoup({
          firstName: spr.firstName,
          userName: spr.userName,
          mobileNumber: spr.mobileNumber,
          deviceType: spr.deviceType,
          email: spr.email,
          lastName: spr.lastName,
        });
      }
    }
  }, [params.id, userList]);


  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <table className="table table-bordered table-custom">
                    <thead>
                      <tr style={{ backgroundColor: '#f0f0f0' }}>
                        <th colSpan="2">
                          <h5 style={{ margin: 0 }}>View Users Details</h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="label">User Name</td>
                        <td className="value">{coup.userName}</td>
                      </tr>
                      <tr>
                        <td className="label">First Name</td>
                        <td className="value">{coup.firstName}</td>
                      </tr>
                      <tr>
                        <td className="label">Last Name</td>
                        <td className="value">{coup.lastName}</td>
                      </tr>
                      <tr>
                        <td className="label">Mobile Number</td>
                        <td className="value">{coup.mobileNumber}</td>
                      </tr>
                      <tr>
                        <td className="label">Email</td>
                        <td className="value">{coup.email}</td>
                      </tr>
                      <tr>
                        <td className="label">Device Type</td>
                        <td className="value">{coup.deviceType}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="form-group">
                    <div className="d-flex justify-content-end">
                      <Link to="/users" className="btn btn-secondary waves-effect m-l-5">
                        Back
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AUX>
  );

 
};

export default UsersView;

import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import AUX from "../../../hoc/Aux_";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getReportedPOST } from "../../../redux/MainAction";

const ReportedPostsView = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const reportedPostsList = useSelector((state) => state.Zeed.reportPost);

  useEffect(() => {
    getReportedPostsList();
  }, []);

  const getReportedPostsList = () => {
    var DATA = {
      startRange: "0",
      count: "1000",
    };
    dispatch(getReportedPOST(DATA));
  };
  const [coup, setCoup] = useState({
    postName: "",
    userName: "",
    userEmail: "",
    date: "",
  });

  useEffect(() => {
    if (params.id && reportedPostsList.length > 0) {
      const spr = reportedPostsList.find((itm) => itm.id === params.id);
      if (spr) {
        setCoup({
          userName: spr.User.userName,
          postName: spr.Post.title,
          userEmail: spr.User.email,
          date: spr.updatedAt,
        });
      }
    }
  }, [params.id, reportedPostsList]);


  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <div>
                    <h5>View Reported Posts Details</h5>
                    <hr />
                    <div className="row">
                        <div className="form-group col-md-6 sorting">
                        Post Name:
                        </div>
                        <div className="form-group col-md-6 sorting">
                            {coup.postName}
                        </div>
                        <div className="form-group col-md-6 sorting">
                            User Name:
                        </div>
                        <div className="form-group col-md-6 sorting">
                            {coup.userName}
                        </div>
                        <div className="form-group col-md-6 sorting">
                            User Email:
                        </div>
                        <div className="form-group col-md-6 sorting">
                            {coup.userEmail}
                        </div>
                        <div className="form-group col-md-6 sorting">
                            Date:
                        </div>
                        <div className="form-group col-md-6 sorting">
                            {coup.date}
                        </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex justify-content-end">
                        <Link
                          to="/reported-Posts"
                          className="btn btn-secondary waves-effect m-l-5"
                        >
                          Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AUX>
  );

 
};

export default ReportedPostsView;

import React, { useEffect, useState } from 'react'
import AUX from '../../../../hoc/Aux_';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {getCountry ,  addCountry  , updateCountry} from '../../../../redux/MainAction'

const CountryAdd = () => {
    const dispatch = useDispatch()
    const CountryList = useSelector(state=>state.Zeed.country)
    const params = useParams()

    const [count , setCount]=useState({
        status:true,
        name:"",
        currency:""
    })
    const handleChange = (e) =>{
        const { value , name } = e.target
        setCount({...count,[name]: value})
    }
    useEffect(()=>{
        getRolList()
    },[])
    
    const getRolList = ()=>{
        var DATA={
        }
        dispatch(getCountry(DATA))
    }

    useEffect(()=>{
        if(params.count_id){
            var spr = CountryList?.filter(itm=>itm.id===params.count_id)
            // console.log("spr",spr);
            setCount({
                status:spr[0]?.status,
                name:spr[0]?.name,
                currency:spr[0]?.currency
            })
        }
    },[params.count_id])

    const onSubmit = (e)=>{
        e.preventDefault()
       if(params.count_id){
            var data ={
                CountryId:params.count_id,
                status:count?.status,
                name:count?.name,
                currency:count.currency
           }
           dispatch(updateCountry(data))
       }else{
        var data ={
            status:count?.status,
            name:count?.name,
            currency:count.currency
           }
           dispatch(addCountry(data))
       }
    }
  return (
    <AUX>
    <div className="page-content-wrapper">
            <div className="container-fluid">

           <div className="row">
               <div className="col-lg-12">
                   <div className="card m-b-20">
                       <div className="card-body">
                               <form onSubmit={onSubmit}>
                                   <div className='row'>
                                       <div className="form-group col-md-6">
                                           <label>Country Name</label>
                                           <input type="text" className="form-control" placeholder="Country Name" name='name' value={count.name} onChange={handleChange}/>
                                           {/* <span id="err">{this.state.name_err}</span> */}
                                       </div>
                                       <div className="form-group col-md-6">
                                           <label>Currency</label>
                                           <input type="text" className="form-control" placeholder="Currency" name='currency' value={count.currency} onChange={handleChange}/>
                                           {/* <span id="err">{this.state.name_err}</span> */}
                                       </div>
                                       <div className="form-group col-md-6">
                                           <label>Status</label>
                                           <div className="col-md-13">
                                               <select className="form-control" name='status' value={count.status} onChange={handleChange}>
                                                   <option value="true">Active</option>
                                                   <option value="false">Inactive</option>
                                               </select>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="form-group">
                                       <div className='d-flex justify-content-end '>
                                           <button  onClick={"this.handleSubmit1"} type="submit" className="btn btn-primary waves-effect waves-light mr-2">
                                               Submit
                                           </button>
                                           <Link to='/country' className="btn btn-secondary waves-effect m-l-5">
                                               Cancel
                                           </Link>
                                       </div>
                                   </div>
                           </form>
                       </div>
                   </div>
               </div> 

           </div> 
       </div> 
   </div>
    </AUX>
  )
}

export default CountryAdd
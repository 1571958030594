import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import AUX from "../../../hoc/Aux_";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getWarehouseList } from "../../../redux/MainAction";

const WarehouseView = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const list = useSelector((state) => state.Zeed.warehouse);

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    var DATA = {
      startRange: "0",
      count: "1000",
    };
    dispatch(getWarehouseList(DATA));
  };
  const [coup, setCoup] = useState({
    email: "",
    isActive: "",
    createdAt: "",
  });

  useEffect(() => {
    if (params.id && list.length > 0) {
      const spr = list.find((itm) => itm.id === params.id);
      if (spr) {
        setCoup({
          email: spr.email,
          isActive: spr.isActive,
          createdAt: spr.createdAt,
        });
      }
    }
  }, [params.id, list]);


  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <div>
                    <h5>View Warehouse Details</h5>
                    <hr />
                    <div className="row">
                      <div className="form-group col-md-6 sorting">
                        Email:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.email}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6 sorting">
                        Is Active:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {(coup.isActive == true ? 'Yes' : 'No')}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6 sorting">
                        Created At:
                      </div>
                      <div className="form-group col-md-6 sorting">
                        {coup.createdAt}
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex justify-content-end">
                        <Link
                          to="/warehouse"
                          className="btn btn-secondary waves-effect m-l-5"
                        >
                          Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AUX>
  );

 
};

export default WarehouseView;

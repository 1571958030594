import React, { useEffect, useState } from "react";
import AUX from "../../../hoc/Aux_";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addWarehouse,
  updateWarehouse,
} from "../../../redux/MainAction";
import Swal from "sweetalert2";

const WarehouseAdd = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [cate, setCate] = useState({
    email: "",
    password: "",
  });
  const [langId, setLangId] = useState("en");
  const warehouse = useSelector((state) => state.Zeed.warehouse);

  const handleLang = (e) => {
    setLangId(e.target.id);
  };

  const getRolList = () => {
    const DATA = {
      startRange: "0",
      count: "1000",
    };
  };

  useEffect(() => {
    getRolList();
  }, []);

  useEffect(() => {
    if (params.id && langId) {
      const spr = warehouse.filter((itm) => itm.id === params.id);
      setCate({
        email: spr[0]?.email,
        // password: spr[0]?.password,
      });
    }
  }, [params.id, langId]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setCate({ ...cate, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (cate?.email === '') {
      Swal.fire({
        title: "Warning",
        icon: "warning",
        text: "Please Add Email",
        type: "warning",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      return false
    }
    else if (cate.password === '') {
      Swal.fire({
        title: "Warning",
        icon: "warning",
        text: "Please Add Password",
        type: "warning",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      return false
    }
    if (params.id) {
      const data = {
        email: cate?.email,
        password: cate?.password,
        warehouseAdminId: params.id,
      };
      dispatch(updateWarehouse(data));
    } else {
      const data = {
        email: cate?.email,
        password: cate?.password,
      };
      dispatch(addWarehouse(data));
    }
  };

  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <ul
                    className="nav nav-tabs"
                    role="tablist"
                    onClick={handleLang}
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#en"
                        role="tab"
                      >
                        <span id="en" className="d-none d-md-block">
                          Warehouse 
                        </span>
                        <span className="d-block d-md-none">
                          <i className="mdi mdi-home-variant h5"></i>
                        </span>
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content mt-3">
                    <div className="form-group row">
                      <div className="col-md-6">
                        <label>Email <span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          value={cate.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                      <label>Password <span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Password"
                          name="password"
                          value={cate.password}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="d-flex justify-content-end ">
                      <button
                        onClick={onSubmit}
                        type="submit"
                        className="btn btn-primary waves-effect waves-light mr-2"
                      >
                        Submit
                      </button>
                      <Link
                        to="/category"
                        className="btn btn-secondary waves-effect m-l-5"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AUX>
  );
};

export default WarehouseAdd;

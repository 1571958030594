import React, { useEffect, useState } from "react";
import AUX from "../../../hoc/Aux_";
import { MDBDataTable } from "mdbreact";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { GET_ORDER } from "../../../redux/type";
import axios from "axios";
import Constant from "../../../Constant";
import { setLoading } from "../../../redux/MainAction";
import Axios from "axios";
import Swal from "sweetalert2";
var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

const SellerAllOrders = () => {
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();
  const ordrList = useSelector((state) => state.Zeed.orders);
  const location = useLocation();
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [orderStatusParam, setActiveParam] = useState("");
  const [filterOrderId, setFilterOrderId] = useState("");
  const [filterPaymentStatus, setFilterPaymentStatus] = useState("");
  const [filterOrderStatus, setFilterOrderStatus] = useState("");
  const [filterName, setFilterName] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterVendorName, setFilterVendorName] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const orderStatus = queryParams.get("orderStatusId") || "";
    dispatch(setLoading(true));
    setActiveParam(orderStatus);
    fetchPageData(1, orderStatus);
  }, [location.search]);

  const fetchPageData = (pageNum, orderStatusParam) => {
    const DATA = {
      startRange: (pageNum - 1) * perPage,
      count: perPage,
      page: pageNum,
      orderStatusId: orderStatusParam,
      orderId: filterOrderId,
      paymentStatus: filterPaymentStatus,
      deliveryStatusId: filterOrderStatus,
      vendorName: filterVendorName,
      userName: filterName,
      startDate: filterStartDate,
      endDate: filterEndDate,
    };
    let vendorUrl = "/order/backend-vendor-getAll";
    axios
      .post(Constant.getAPI() + vendorUrl, DATA, config)
      .then((res) => {
        // window.setLoading(true)
        dispatch({
          type: GET_ORDER,
          payload: res.data.data,
          totalRows: res.data.totalRows,
        });
        setTotalRows(res.data.totalRows);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
    setCurrentPage(pageNum);
  };

  const handlePageChange = (pageNum) => {
    dispatch(setLoading(true));
    fetchPageData(pageNum, orderStatusParam);
  };

  const handlePerPageChange = (event) => {
    setPerPage(Number(event.target.value));
    fetchPageData(1);
  };

  const handleOrderIdChange = (event) => {
    setFilterOrderId(event.target.value);
  };

  const handlePaymentStatusChange = (event) => {
    setFilterPaymentStatus(event.target.value);
  };

  const handleOrderStatusChange = (event) => {
    setFilterOrderStatus(event.target.value);
  };

  const handleNameChange = (event) => {
    setFilterName(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setFilterStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setFilterEndDate(event.target.value);
  };

  const handleVendorNameChange = (event) => setFilterVendorName(event.target.value);

  const toggleFilterVisibility = () => {
    setShowFilter(!showFilter);
  };

  // const changeStatus = (sellerId, orderId, deliveryStatusId) => {
  //   const data = {
  //     sellerId: sellerId,
  //     orderId: orderId,
  //     deliveryStatusId: deliveryStatusId,
  //   };
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: localStorage.getItem("loged_In_auth"),
  //     },
  //   };

  //   Axios.post(Constant.getAPI() + "/order/changeSellerDeliveryStatus", data, config)
  //     .then((res) => {
  //       if (res.data.status) {
  //         fetchPageData(1);
  //         Swal.fire({
  //           title: "Order status has been changed",
  //           icon: "success",
  //           confirmButtonColor: "#3ab1f7",
  //           cancelButtonColor: "#d33",
  //           confirmButtonText: "Ok",
  //         });
  //       } else {
  //         Swal.fire({
  //           title: "You are not authorised",
  //           icon: "error",
  //           imageUrl: "./assets/images/test.png",
  //           imageHeight: 100,
  //           confirmButtonColor: "#3ab1f7",
  //           cancelButtonColor: "#d33",
  //           confirmButtonText: "Ok",
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Error:", err);
  //       Swal.fire({
  //         title: "You are not authorised",
  //         icon: "error",
  //         imageUrl: "./assets/images/test.png",
  //         imageHeight: 100,
  //         confirmButtonColor: "#3ab1f7",
  //         cancelButtonColor: "#d33",
  //         confirmButtonText: "Ok",
  //       });
  //     });
  // };

  const filteredUsList = ordrList.filter((user) => {
    // if (filterOrderId && !user.invoiceNumber.includes(filterOrderId)) {
    //   return false;
    // }

    // if (filterPaymentStatus && user.paymentStatus !== filterPaymentStatus) {
    //   return false;
    // }

    // if (filterOrderStatus && user.orderStatus !== filterOrderStatus) {
    //   return false;
    // }

    // if (filterName && !user.sellerDetails.shopName.includes(filterName)) {
    //   return false;
    // }

    // if (!orderStatusParam) {
    //   return true;
    // }
    // if (
    //   orderStatusParam == "63736349-5569-4a1a-8a57-df116ea4543c" &&
    //   user.orderStatus === "Pending"
    // ) {
    //   return true;
    // }
    // if (
    //   orderStatusParam == "e1635f74-c3e3-4639-83ce-c1d5af1d3810" &&
    //   user.orderStatus === "Accepted"
    // ) {
    //   return true;
    // }
    // if (
    //   orderStatusParam == "54f95aa1-e90d-4eac-a0e0-eeb8640f535b" &&
    //   user.orderStatus === "Ready for pickup"
    // ) {
    //   return true;
    // }
    // if (
    //   orderStatusParam == "bb4b6700-fc58-43e0-a3c6-37337dee7c86" &&
    //   user.orderStatus === "En Route to Warehouse"
    // ) {
    //   return true;
    // }
    // if (
    //   orderStatusParam == "b6b76fe2-1081-4ae4-ae0d-55f11aa716af" &&
    //   user.orderStatus === "Reached at warehouse"
    // ) {
    //   return true;
    // }
    // if (
    //   orderStatusParam == "746995c7-2a91-487b-a57b-913bf722cd9f" &&
    //   user.orderStatus === "Cancelled"
    // ) {
    //   return true;
    // }
    return true;
  });

  const columns = [
    {
      label: "Order ID",
      field: "invoiceNumber",
    },
    // {
    //   label: "Area",
    //   field: "Area",
    // },
    {
      label: "Date-Time",
      field: "createdAt",
    },
    {
      label: "Customer Name",
      field: "userName",
    },
    {
      label: "Customer Email",
      field: "email",
    },
    {
      label: "Customer Phone",
      field: "phone",
    },
    {
      label: "Vendor Name",
      field: "shopName",
    },
    {
      label: "Vendor Mobile",
      field: "vendorMobile",
    },
    {
      label: "Vendor Address",
      field: "vendorAddress",
    },
    // {
    //   label: "Time Slot",
    //   field: "deliveryTimeSlot",
    // },
    {
      label: "Sub Total",
      field: "orderTotal",
    },
    {
      label: "Payment Status",
      field: "paymentStatus",
    },
    {
      label: "Order Status",
      field: "orderStatus",
    },
    // {
    //   label: "Vendor Change Status",
    //   field: "changeStatus",
    // },
    {
      label: "Action",
      field: "id",
    },
  ];

  const rows = filteredUsList.map((Item) => ({
    invoiceNumber: Item.invoiceNumber,
    // User: Item.customer.userName,
    Area: Item?.addressDetails?.City?.name || "",
    createdAt: moment.unix(Item.createdAt).format("DD-MMM-YYYY | hh:mm A"),
    deliveryTimeSlot: Item.deliveryTimeSlot,
    orderTotal: Item.orderTotal,
    userName: Item?.customer?.userName,
    email: Item?.customer?.email,
    phone: Item?.customer?.mobileNumber,
    shopName: Item?.sellerDetails?.shopName,
    vendorMobile: Item?.sellerDetails?.mobile,
    vendorAddress: Item?.sellerDetails?.address,
    orderStatus:
      Item.orderStatus === "Placed" ? (
        <b
          style={{
            color: "green",
            marginTop: "0",
            marginBottom: 0,
            fontWeight: "600",
          }}
        >
          {Item.orderStatus}
        </b>
      ) : Item.orderStatus === "Pending" ? (
        <b
          style={{
            color: "orange",
            marginTop: "0",
            marginBottom: 0,
            fontWeight: "600",
          }}
        >
          {Item.orderStatus}
        </b>
      ) : Item.orderStatus === "Accepted" ? (
        <b
          style={{
            color: "green",
            marginTop: "0",
            marginBottom: 0,
            fontWeight: "600",
          }}
        >
          {Item.orderStatus}
        </b>
      ) : Item.orderStatus === "Reached at warehouse" ? (
        <b
          style={{
            color: "blue",
            marginTop: "0",
            marginBottom: 0,
            fontWeight: "600",
          }}
        >
          At store
        </b>
      ) : (
        <b
          style={{
            color: "red",
            marginTop: "0",
            marginBottom: 0,
            fontWeight: "600",
          }}
        >
          {Item.orderStatus}
        </b>
      ),
      // changeStatus: (
      //   <div className="pr-3">
      //     <select
      //       className="orderInvoiceSelect"
      //       onChange={(e) => changeStatus(Item.SellerId, Item.OrderId, e.target.value)}
      //       value={Item.orderStatusId}
      //     >
      //       <option value="">- Select Status -</option>
      //       {Item.vendorStatusList &&
      //         Item.vendorStatusList.map((data) => (
      //           <option key={data.id} value={data.id}>
      //             {data.id === "b6b76fe2-1081-4ae4-ae0d-55f11aa716af"
      //               ? "At store"
      //               : data.displayStatus}
      //           </option>
      //         ))}
      //     </select>
      //   </div>
      // ),
    // orderStatus: Item.orderStatus === "Placed" ? <b style={{ color: 'green', marginTop: "0", marginBottom: 0, fontWeight: "600" }}>{Item.orderStatus}</b> : <b style={{ color: 'red', marginTop: "0", marginBottom: 0, fontWeight: "600"}}>{Item.orderStatus}</b>,
    paymentStatus:
      Item.paymentStatus === "Pending" ? (
        <p
          style={{
            color: "red",
            marginTop: "0",
            marginBottom: 0,
            fontWeight: "600",
          }}
        >
          Pending
        </p>
      ) : Item.paymentStatus === "received" ? (
        <p style={{ color: "green", marginTop: "0", marginBottom: 0 }}>
          Success
        </p>
      ) : (
        <p style={{ color: "red", marginTop: "0", marginBottom: 0 }}>Failed</p>
      ),
    paymentStatus: (() => {
      switch (Item.paymentStatus) {
        case "Pending":
          return (
            <p
              style={{
                color: "orange",
                marginTop: "0",
                marginBottom: 0,
                fontWeight: "600",
              }}
            >
              Pending
            </p>
          );
        case "Received":
          return (
            <p
              style={{
                color: "green",
                marginTop: "0",
                marginBottom: 0,
                fontWeight: "600",
              }}
            >
              Success
            </p>
          );
        case "notProcessed":
          return (
            <p
              style={{
                color: "red",
                marginTop: "0",
                marginBottom: 0,
                fontWeight: "600",
              }}
            >
              Failed
            </p>
          );
        default:
          return (
            <p
              style={{
                color: "red",
                marginTop: "0",
                marginBottom: 0,
                fontWeight: "600",
              }}
            >
              Failed
            </p>
          );
      }
    })(),
    id: <Link to={`/orders/${Item.id}`} className="mdi mdi-eye"></Link>,
  }));

  const data = {
    columns,
    rows,
  };
  const totalPages = Math.ceil((totalRows ? totalRows : 0) / perPage);

  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card m-b-20">
                <div className="card-body">
                <div>
                    <button
                      onClick={toggleFilterVisibility}
                      style={{
                        marginBottom: "10px",
                        padding: "10px 20px",
                        backgroundColor: "#007bff",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      {showFilter ? "Hide Filters" : "Show Filters"}
                    </button>
                    {showFilter && (
                      <div
                        style={{
                          border: "1px solid #ddd",
                          borderRadius: "4px",
                          padding: "20px",
                          backgroundColor: "#fff",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          marginBottom: "20px",
                        }}
                      >
                        <div style={{ display: "flex", marginBottom: "10px" }}>
                          <div style={{ flex: 1, marginRight: "10px" }}>
                            <label
                              htmlFor="orderId"
                              style={{
                                display: "block",
                                marginBottom: "5px",
                              }}
                            >
                              Order ID
                            </label>
                            <input
                              type="text"
                              id="orderId"
                              value={filterOrderId}
                              onChange={handleOrderIdChange}
                              style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #ddd",
                              }}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <label
                              htmlFor="phone"
                              style={{
                                display: "block",
                                marginBottom: "5px",
                              }}
                            >
                              Vendor Name
                            </label>
                            <input
                              type="text"
                              id="phone"
                              value={filterVendorName}
                              onChange={handleVendorNameChange}
                              style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #ddd",
                              }}
                            />
                          </div>
                        </div>
                        <div style={{ display: "flex", marginBottom: "10px" }}>
                          <div style={{ flex: 1, marginRight: "10px" }}>
                            <label
                              htmlFor="paymentStatus"
                              style={{
                                display: "block",
                                marginBottom: "5px",
                              }}
                            >
                              Payment Status
                            </label>
                            <select
                              id="paymentStatus"
                              value={filterPaymentStatus}
                              onChange={handlePaymentStatusChange}
                              style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #ddd",
                              }}
                            >
                              <option value="">Select</option>
                              <option value="Pending">Pending</option>
                              <option value="Received">Received</option>
                              <option value="notProcessed">Failed</option>
                            </select>
                          </div>
                          <div style={{ flex: 1 }}>
                            <label
                              htmlFor="orderStatus"
                              style={{
                                display: "block",
                                marginBottom: "5px",
                              }}
                            >
                              Order Status
                            </label>
                            <select
                              id="orderStatus"
                              value={filterOrderStatus}
                              onChange={handleOrderStatusChange}
                              style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #ddd",
                              }}
                            >
                              <option value="">Select</option>
                              <option value="63736349-5569-4a1a-8a57-df116ea4543c">Pending</option>
                              {/* <option value="Placed">Placed</option> */}
                              <option value="54f95aa1-e90d-4eac-a0e0-eeb8640f535b">Ready for pickup</option>
                              <option value="bb4b6700-fc58-43e0-a3c6-37337dee7c86">
                                En Route to Warehouse
                              </option>
                              <option value="Rb6b76fe2-1081-4ae4-ae0d-55f11aa716af">Reached at warehouse</option>
                              <option value="746995c7-2a91-487b-a57b-913bf722cd9f">Cancelled</option>
                            </select>
                          </div>
                        </div>
                        <div style={{ display: "flex", marginBottom: "10px" }}>
                          <div style={{ flex: 1, marginRight: "10px" }}>
                            <label
                              htmlFor="startDate"
                              style={{
                                display: "block",
                                marginBottom: "5px",
                              }}
                            >
                              Start Date
                            </label>
                            <input
                              type="date"
                              id="startDate"
                              value={filterStartDate}
                              onChange={handleStartDateChange}
                              style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #ddd",
                              }}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <label
                              htmlFor="endDate"
                              style={{
                                display: "block",
                                marginBottom: "5px",
                              }}
                            >
                              End Date
                            </label>
                            <input
                              type="date"
                              id="endDate"
                              value={filterEndDate}
                              onChange={handleEndDateChange}
                              style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #ddd",
                              }}
                            />
                          </div>
                        </div>
                        <div style={{ textAlign: "right", marginTop: "10px" }}>
                          <button
                            onClick={() => fetchPageData(1, filterOrderStatus)}
                            style={{
                              padding: "10px 20px",
                              backgroundColor: "#28a745",
                              color: "#fff",
                              border: "none",
                              borderRadius: "4px",
                              cursor: "pointer",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            Apply Filters
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <MDBDataTable
                    bordered
                    theadColor="#2632384"
                    paging={false}
                    hover
                    searching={false}
                    entries={20}
                    data={data}
                  />
                  <div className="pagination-controls">
                    {/* <select class="" onChange={handlePerPageChange} value={perPage}>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={40}>40</option>
                      <option value={80}>80</option>
                      <option value={100}>100</option>
                    </select> */}
                    <button
                      className="pagination-btn first-btn"
                      onClick={() => handlePageChange(1)}
                      disabled={currentPage === 1}
                    >
                      First
                    </button>
                    <button
                      className="pagination-btn prev-btn"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <span>
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      className="pagination-btn next-btn"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                    <button
                      className="pagination-btn last-btn"
                      onClick={() => handlePageChange(totalPages)}
                      disabled={currentPage === totalPages}
                    >
                      Last
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{`
        .pagination-controls {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 20px;
        }
        select{
          width: 27%;
        }
        .pagination-btn {
          background-color: #263238;
          color: white;
          border: none;
          padding: 10px 20px;
          margin: 0 5px;
          cursor: pointer;
          border-radius: 5px;
          font-size: 14px;
        }
        
        .pagination-btn:disabled {
          background-color: #cccccc;
          cursor: not-allowed;
        }
        
        .pagination-btn:not(:disabled):hover {
          background-color: #263233;
        }
      `}</style>
    </AUX>
  );
};

export default SellerAllOrders;

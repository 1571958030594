import React, { useEffect, useState, useRef } from "react";
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import AUX from "../../../hoc/Aux_";
import { Link } from "react-router-dom";
import { getDashboard } from '../../../redux/MainAction'
import { useDispatch, useSelector } from 'react-redux';
import { addDays } from 'date-fns';
import { enUS } from 'date-fns/locale';
import ReactApexChart from 'react-apexcharts';
import Axios from "axios";
import Constant from "../../../Constant";

const Dashboard = () => {

    var config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("loged_In_auth"),
        },
    };

    const dispatch = useDispatch()
    const DashboardList = useSelector(state => state.Zeed.dashboard)

    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const dateRangePickerRef = useRef(null);

    const handleToggleDateRangePicker = () => {
        setShowDateRangePicker(!showDateRangePicker);
    };

    const handleSelect = (ranges) => {
        const selectedRange = ranges.selection;
        revenue({ dateRange: selectedRange })
        barGraph({ dateRange: selectedRange });
        pieChartForTopSelling({ dateRange: selectedRange });
        pieChartForLeastSelling({ dateRange: selectedRange });
        customerInsights({ dateRange: selectedRange });
        inventoryStatus({ dateRange: selectedRange });
        dispatch(getDashboard({ startRange: '0', count: "1000", dateRange: selectedRange }));
        setShowDateRangePicker(false);
        setRange([selectedRange]);
    };

    const [insights, setInsights] = useState({
        newCustomersCount: 0,
        repeatCustomersCount: 0,
        retentionRate: 0,
        avgTimeSpent: 0,
    });

    const [inventory, setInventory] = useState({
        outOfStockCount: 0,
        runningStockCount: 0,
    });

    const [revenues, setRevenue] = useState({
        totalProductSalesRevenue: 0,
        totalDeliveryCharges: 0,
    });

    // Date range state
    const [range, setRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    // bar graph
    const [chartData, setChartData] = useState({
        options: {
            chart: {
                id: 'sales-by-categories-chart'
            },
            xaxis: {
                categories: []
            }
        },
        series: [{
            name: 'Sales',
            data: []
        }]
    });

    // pie chart top selling
    const [pieChartTopSellingData, setPieChartTopSellingData] = useState({
        series: [],
        options: {
            chart: {
                type: 'pie',
            },
            labels: [],
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: 'bottom',
                        },
                    },
                },
            ],
        },
    });

    // pie chart least selling
    const [pieChartLeastSellingData, setPieChartLeastSellingData] = useState({
        series: [],
        options: {
            chart: {
                type: 'pie',
            },
            labels: [],
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: 'bottom',
                        },
                    },
                },
            ],
        },
    });

    // pie chart revenue
    const [pieChartRevenueData, setPieChartRevenueData] = useState({
        series: [],
        options: {
            chart: {
                type: 'pie',
            },
            labels: [],
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: 'bottom',
                        },
                    },
                },
            ],
        },
    });

    // Static date ranges
    const staticRanges = [
        {
            label: 'Today',
            range: () => ({
                startDate: new Date(),
                endDate: new Date()
            }),
            isSelected: (range) => isDateInRange(new Date(), range),
        },
        {
            label: 'Yesterday',
            range: () => ({
                startDate: addDays(new Date(), -1),
                endDate: addDays(new Date(), -1)
            }),
            isSelected: (range) => isDateInRange(addDays(new Date(), -1), range),
        },
        {
            label: 'This Week',
            range: () => ({
                startDate: moment().startOf('week').toDate(),
                endDate: moment().endOf('week').toDate()
            }),
            isSelected: (range) => isDateInRange(moment().startOf('week').toDate(), range),
        },
        {
            label: 'Last Week',
            range: () => ({
                startDate: moment().subtract(1, 'weeks').startOf('week').toDate(),
                endDate: moment().subtract(1, 'weeks').endOf('week').toDate()
            }),
            isSelected: (range) => isDateInRange(moment().subtract(1, 'weeks').startOf('week').toDate(), range),
        },
        {
            label: 'This Month',
            range: () => ({
                startDate: moment().startOf('month').toDate(),
                endDate: moment().endOf('month').toDate()
            }),
            isSelected: (range) => isDateInRange(moment().startOf('month').toDate(), range),
        }
    ];

    const isDateInRange = (date, range) => {
        const { startDate, endDate } = range;
        return date >= startDate && date <= endDate;
    };

    const barGraph = async (dateRange) => {
        try {
            const response = await Axios.post(Constant.getAPI() + "/dashboard/sales-By-Categories", dateRange, config);
            const data = response.data;

            const categories = data.map(item => item.categoryName);
            const sales = data.map(item => item.totalSales);

            setChartData({
                options: {
                    ...chartData.options,
                    xaxis: {
                        categories
                    }
                },
                series: [{
                    name: 'Sales',
                    data: sales
                }]
            });
        } catch (error) {
            console.error('Error fetching sales data', error);
        }
    };

    useEffect(() => {
        barGraph(range[0]);
    }, []);

    const pieChartForTopSelling = async (dateRange) => {
        try {
            const response = await Axios.post(Constant.getAPI() + "/dashboard/top-selling-products", dateRange, config);
            const data = response.data;

            const labels = data.map(item => item.productName);
            const series = data.map(item => item.totalAmount);

            setPieChartTopSellingData({
                ...pieChartTopSellingData,
                series,
                options: {
                    ...pieChartTopSellingData.options,
                    labels,
                },
            });
        } catch (error) {
            console.error('Error fetching top selling products:', error);
        }
    };

    useEffect(() => {
        pieChartForTopSelling();
    }, []);

    const pieChartForLeastSelling = async (dateRange) => {
        try {
            const response = await Axios.post(Constant.getAPI() + "/dashboard/least-selling-products", dateRange, config);
            const data = response.data;

            const labels = data.map(item => item.productName);
            const series = data.map(item => item.totalAmount);

            setPieChartLeastSellingData({
                ...pieChartLeastSellingData,
                series,
                options: {
                    ...pieChartLeastSellingData.options,
                    labels,
                },
            });
        } catch (error) {
            console.error('Error fetching top selling products:', error);
        }
    };

    useEffect(() => {
        pieChartForLeastSelling();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
                setShowDateRangePicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        getRolList()
    }, [])

    const customerInsights = async (dateRange) => {
        const response = await Axios.post(Constant.getAPI() + "/dashboard/customer-insights", dateRange, config);
        setInsights(response.data);
    };

    useEffect(() => {
        customerInsights();
    }, []);

    const inventoryStatus = async (dateRange) => {
        const response = await Axios.post(Constant.getAPI() + "/dashboard/inventory-status", dateRange, config);
        setInventory(response.data);
    };

    useEffect(() => {
        inventoryStatus();
    }, []);

    const revenue = async (dateRange) => {
        const response = await Axios.post(Constant.getAPI() + "/dashboard/revenue", dateRange, config);
        setRevenue(response.data);
        console.log(response.data, 'response.data');
        setPieChartRevenueData({
            series: [parseFloat(response.data.totalProductSalesRevenue), parseFloat(response.data.totalDeliveryCharges)],
            options: {
                ...pieChartRevenueData.options,
                labels: ['Product Sales', 'Delivery Charges']
            }
        });
    };

    useEffect(() => {
        revenue();
    }, []);

    const [vendorbadge, setVendorCount] = useState({
        pending: 0,
        readyForPickup: 0,
        assignedDriverReadyForPickup: 0,
        unassignedDriverReadyForPickup:0,
        atStore: 0,
        accepted: 0,
        cancelled: 0,
        enRouteWarehouse: 0,
    });


    const vendorCount = async (DATA) => {
        const resposne = await Axios.post(Constant.getAPI() + "/dashboard/sideBarVendorOrderBadges", DATA, config);
        setVendorCount(resposne.data);
    }

    useEffect(() => {
        vendorCount();
    }, []);



    const getRolList = () => {
        var DATA = {
            startRange: '0',
            count: "1000"
        }
        dispatch(getDashboard(DATA))
    }
    // console.log("DashboardList",DashboardList);

    return (
        <AUX>
            <div className="page-content-wrapper">
                <div className="container-fluid" style={{ position: 'relative' }}>
                    <div className="w-100" style={{ textAlign: 'end', position: 'relative' }}>
                        <button
                            onClick={handleToggleDateRangePicker}
                            style={{
                                marginBottom: '20px',
                                padding: '10px 20px',
                                backgroundColor: '#007bff',
                                color: 'white',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer',
                            }}
                        >
                            {showDateRangePicker ? 'Hide Filter' : 'Show Filter'}
                        </button>
                    </div>

                    {showDateRangePicker && (
                        <div ref={dateRangePickerRef} style={{ position: 'absolute', right: '0', top: '50px', zIndex: '999' }}>
                            <DateRangePicker
                                ranges={range}
                                onChange={handleSelect}
                                locale={enUS}
                                staticRanges={staticRanges}
                                inputRanges={[]}
                            />
                        </div>
                    )}

                    <div className="row">
                        <div className="col-md-6 col-xl-3">
                            <div className="mini-stat clearfix bg-white">
                                <span className="mini-stat-icon bg-purple mr-0 float-right">
                                    <i className="mdi mdi-basket"></i>
                                </span>
                                <div className="mini-stat-info">
                                    <span className="counter text-purple">KWD {DashboardList.totalSales}</span> Sales
                                </div>
                                <div className="clearfix"></div>
                                <p className=" mb-0 m-t-20 text-muted"> Total Sales: KWD {DashboardList.totalSales} </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="mini-stat clearfix bg-white">
                                <span className="mini-stat-icon bg-pink mr-0 float-right">
                                    <i className="mdi mdi-cart"></i>
                                </span>
                                <div className="mini-stat-info">
                                    <span className="counter">
                                        <a href="/orders" className=" text-pink" style={{ textDecoration: "underline" }}>
                                            {DashboardList.totalOrders}
                                        </a>
                                    </span>
                                    Orders
                                </div>
                                <div className="clearfix"></div>
                                <p className="text-muted mb-0 m-t-20">Total orders: {DashboardList.totalOrders}</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="mini-stat clearfix bg-white">
                                <span className="mini-stat-icon bg-blue-grey mr-0 float-right">
                                    <i className="mdi mdi-truck"></i>
                                </span>
                                <div className="mini-stat-info">
                                    <span className="counter">
                                        <a href="/users" className="text-blue-grey" style={{ textDecoration: "underline" }}>
                                            {DashboardList.totalCount}
                                        </a>
                                    </span>
                                    Users
                                </div>
                                <div className="clearfix"></div>
                                <p className="text-muted mb-0 m-t-20">Total Users:  {DashboardList.totalCount}</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="mini-stat clearfix bg-white">
                                <span className="mini-stat-icon bg-brown mr-0 float-right">
                                    <i className="mdi mdi-black-mesa"></i>
                                </span>
                                <div className="mini-stat-info">
                                    <span className="counter">
                                        <a href="/sellers" className="counter text-brown" style={{ textDecoration: "underline" }}>
                                            {DashboardList.sellerCount}
                                        </a>
                                    </span>
                                    Seller
                                </div>
                                <div className="clearfix"></div>
                                <p className="text-muted mb-0 m-t-20">Total Seller:   {DashboardList.sellerCount}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="clearfix bg-white text-center">
                                <div className="d-flex justify-content-center align-items-center" style={{ background: "#d0d8e3" }}>
                                    <h5 className="text-blue">Users Statistics</h5>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Ios</p>
                                            <h3>
                                                <Link to={{ pathname: "/users", search: "?signup=iOS", }}
                                                    className="text-dark" style={{ textDecoration: "underline" }} >
                                                    {DashboardList.iosCount}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Website</p>
                                            <h3>
                                                <Link to={{ pathname: "/users", search: "?signup=web", }}
                                                    className="text-dark" style={{ textDecoration: "underline" }} >
                                                    {DashboardList.webCount}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Total</p>
                                            <h3>
                                                <a href="/users" className="text-dark" style={{ textDecoration: "underline" }} >
                                                    {DashboardList.totalCount}
                                                </a>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="clearfix bg-white text-center">
                                <div className="d-flex justify-content-center align-items-center" style={{ background: "#d0d8e3" }}>
                                    <h5 className="text-blue">Seller Statistics</h5>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Active</p>
                                            <h3>
                                                <Link to={{ pathname: "/sellers", search: "?isSeller=Seller" }}
                                                    className="text-dark" style={{ textDecoration: "underline" }} >
                                                    {DashboardList.activeSellerCount}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>InActive</p>
                                            <h3>
                                                <Link to={{ pathname: "/sellers", search: "?status=0", }}
                                                    className="text-dark" style={{ textDecoration: "underline" }} >
                                                    {DashboardList.inActiveSellerCount}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>All Seller</p>
                                            <h3>
                                                <a href="/sellers" className="text-dark" style={{ textDecoration: "underline" }} >
                                                    {DashboardList.sellerCount}
                                                </a>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-12">
                            <div className="clearfix bg-white text-center">
                                <div className="d-flex justify-content-center align-items-center" style={{ background: "#d0d8e3" }}>
                                    <h5 className="text-blue">Master Order By Status</h5>
                                </div>
                                <div className="row">
                                    {/* <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Placed</p>
                                            <h3>
                                                <Link to={{ pathname: "/orders", search: "?orderStatusId=383a1c6f-d1ca-4aa8-ae70-4ba71f015e2b", }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {DashboardList.placedOrder}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div> */}
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Pending</p>
                                            <h3>
                                                <Link to={{ pathname: "/pending", }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {DashboardList.pending}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Pending (Accepted)</p>
                                            <h3>
                                                <Link to={{ pathname: "/accepted",  }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {DashboardList.placedOrder}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Accepted (Not at store)</p>
                                            <h3>
                                                <Link to={{ pathname: "/master-accepted-not-at-store", }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {DashboardList.acceptedNotAtStore}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Accepted (at store)</p>
                                            <h3>
                                                <Link to={{ pathname: "/master-at-store", }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {DashboardList.atStore}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Being Wrapped</p>
                                            <h3>
                                                <Link to={{ pathname: "/being-wrapped-up" }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {DashboardList.beingWrapped}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Ready for Delivery (Unassigned)</p>
                                            <h3>
                                                <Link to={{ pathname: "/being-wrapped-up-unassign", }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {DashboardList.beingWrappedAndNotInDeliveries}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Ready for Delivery (Assigned)</p>
                                            <h3>
                                                <Link to={{ pathname: "/being-wrapped-up-assign" }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {DashboardList.beingWrappedAndInDeliveries}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Out for Delivery</p>
                                            <h3>
                                                <Link to={{ pathname: "/on-the-way", }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {DashboardList.onTheWay}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Delivered</p>
                                            <h3>
                                                <Link to={{ pathname: "/cancelled", }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {DashboardList.deliveredOrder}
                                                </Link> 
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Cancelled</p>
                                            <h3>
                                                <Link to={{ pathname: "/orders", search: "?orderStatusId=cee1b25e-2b10-11ef-bd7f-0ad94c5b94d5", }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {DashboardList.cancelledOrder}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Not Delivered</p>
                                            <h3>
                                                <Link to={{ pathname: "/orders", search: "?orderStatusId=54b7c7d8-5715-4536-a330-d2641b0b8bf7" }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {DashboardList.notDeliveredOrder}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Reached at warehouse</p>
                                            <h3>
                                                <Link to={{ pathname: "/orders", search: "?orderStatusId=b6b76fe2-1081-4ae4-ae0d-55f11aa716af" }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {DashboardList.warehouseOrder}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Ready for Pickup</p>
                                            <h3>
                                                <Link to={{ pathname: "/orders", search: "orderStatusId=54f95aa1-e90d-4eac-a0e0-eeb8640f535b" }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {DashboardList.pickupOrder}
                                                </Link>
                                            </h3> 
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row mt-4">  
                        <div className="col-md-12">
                            <div className="clearfix bg-white text-center">
                                <div className="d-flex justify-content-center align-items-center" style={{ background: "#d0d8e3" }}>
                                    <h5 className="text-blue">Vendor Order Status</h5>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Pending</p>
                                            <h3>
                                                <Link to={{ pathname: "/seller-pending-orders",}} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {vendorbadge.pending}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Accepted</p>
                                            <h3>
                                                <Link to={{ pathname: "/seller-accepted-orders",  }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {vendorbadge.accepted} 
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Ready for Pickup (Unassigned)</p>
                                            <h3>
                                                <Link to={{ pathname: "/ready-for-pickup", }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {vendorbadge.unassignedDriverReadyForPickup}
                                                </Link> 
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Ready for Pickup (Assigned)</p>
                                            <h3>
                                                <Link to={{ pathname: "/ready-for-pickup",  }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {vendorbadge.assignedDriverReadyForPickup}
                                                </Link> 
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Enroute to Warehouse</p>
                                            <h3>
                                                <Link to={{ pathname: "/enroute-warehouse",  }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {vendorbadge.enRouteWarehouse}
                                                </Link> 
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Reached at Warehouse</p>
                                            <h3>
                                                <Link to={{ pathname: "/at-store", }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {vendorbadge.atStore}
                                                </Link> 
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-12">
                            <div className="clearfix bg-white text-center">
                                <div className="d-flex justify-content-center align-items-center" style={{ background: "#d0d8e3" }}>
                                    <h5 className="text-blue">Order By Date</h5>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Today</p>
                                            <h3>
                                                {DashboardList.todayOrderCount}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>This Week</p>
                                            <h3>
                                                {DashboardList.thisWeekOrderCount}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>This Month</p>
                                            <h3>
                                                {DashboardList.thisMonthOrderCount}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p> This Year</p>
                                            <h3>
                                                {DashboardList.thisYearOrderCount}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="clearfix bg-white text-center">
                                <div className="d-flex justify-content-center align-items-center" style={{ background: "#d0d8e3" }}>
                                    <h5 className="text-blue">Product Statistics</h5>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Active</p>
                                            <h3>
                                                <Link to={{ pathname: "/product-fixed", search: "?active=1", }}
                                                    className="text-dark" style={{ textDecoration: "underline" }} >
                                                    {DashboardList.activeProduct}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>InActive</p>
                                            <h3>
                                                <Link to={{ pathname: "/product-fixed", search: "?active=0", }}
                                                    className="text-dark" style={{ textDecoration: "underline" }} >
                                                    {DashboardList.inactiveProduct}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>All Product</p>
                                            <h3>
                                                <a href="/product-Fixed" className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {DashboardList.totalproduct}
                                                </a>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="clearfix bg-white text-center">
                                <div className="d-flex justify-content-center align-items-center" style={{ background: "#d0d8e3" }}>
                                    <h5 className="text-blue">Report Statistics</h5>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Today</p>
                                            <h3>
                                                <Link to={{ pathname: "/" }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {DashboardList.todayReport}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Yesterday</p>
                                            <h3>
                                                <Link to={{ pathname: "/" }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {DashboardList.yesterdayReportCount}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>All Reports</p>
                                            <h3>
                                                <Link to={{ pathname: "/reported-Posts" }} className="text-dark" style={{ textDecoration: "underline" }}>
                                                    {DashboardList.allReport}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-4'>
                        <div className="col-md-4">
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <h4 className="mt-0 header-title" style={{ color: "#8294C4" }}>Sales By Category</h4>
                                    <ReactApexChart
                                        options={chartData.options}
                                        series={chartData.series}
                                        type="bar"
                                        height={350}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <h4 className="mt-0 header-title" style={{ color: "#8294C4" }}>Top selling products</h4>
                                    <ReactApexChart
                                        options={pieChartTopSellingData.options}
                                        series={pieChartTopSellingData.series}
                                        type="pie"
                                        height={400}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <h4 className="mt-0 header-title" style={{ color: "#8294C4" }}>Least selling products</h4>
                                    <ReactApexChart
                                        options={pieChartLeastSellingData.options}
                                        series={pieChartLeastSellingData.series}
                                        type="pie"
                                        height={400}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="clearfix bg-white text-center">
                                <div className="d-flex justify-content-center align-items-center" style={{ background: "#d0d8e3" }}>
                                    <h5 className="text-blue">Customer Insights</h5>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>New Customers</p>
                                            <h3>
                                                <Link
                                                    className="text-dark">
                                                    {insights.newCustomersCount}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Repeat Customers</p>
                                            <h3>
                                                <Link
                                                    className="text-dark">
                                                    {insights.repeatCustomersCount}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="clearfix bg-white text-center">
                                <div className="d-flex justify-content-center align-items-center" style={{ background: "#d0d8e3" }}>
                                    <h5 className="text-blue">Inventory Status</h5>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Out of stock</p>
                                            <h3>
                                                <Link
                                                    className="text-dark">
                                                    {inventory.outOfStockCount}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Running Stock</p>
                                            <h3>
                                                <Link
                                                    className="text-dark">
                                                    {inventory.runningStockCount}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="clearfix bg-white text-center">
                                <div className="d-flex justify-content-center align-items-center" style={{ background: "#d0d8e3" }}>
                                    <h5 className="text-blue">Revenue</h5>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>Product Sales Revenue</p>
                                            <h3>
                                                <Link
                                                    className="text-dark">
                                                    {revenues.totalProductSalesRevenue}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mini-stat widget-chart-sm clearfix bg-white text-center shadow">
                                            <p>total Delivery Charges</p>
                                            <h3>
                                                <Link
                                                    className="text-dark">
                                                    {revenues.totalDeliveryCharges}
                                                </Link>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card m-b-20">
                                <div className="card-body">
                                    <h4 className="mt-0 header-title" style={{ color: "#8294C4" }}>Revenue chart</h4>
                                    <ReactApexChart
                                        options={pieChartRevenueData.options}
                                        series={pieChartRevenueData.series}
                                        type="pie"
                                        height={300}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
        </AUX>
    );

}
export default Dashboard
import React, { useEffect, useState ,useRef} from "react";
import Axios from "axios";
import Constant from "../../../Constant";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import AUX from "../../../hoc/Aux_";
import { getSeller, setLoading } from "../../../redux/MainAction";

const ExcelUpload = () => {
  const fileInputRef = useRef(null); 
  const [file, setFile] = useState("");
  const [sellerOptions, setSellerOptions] = useState([]);
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState("");
  const [seValue, setSellerValue] = useState([]);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const role = localStorage.getItem("role");
  const vendorId = localStorage.getItem("vendorId");
  const SellerList = useSelector((state) => state.Zeed.sellerList);

  const onFileChange = async (e) => {
    const fileData = e.target.files[0];
    // console.log(fileData, '---fileData---');
    setFile(fileData);
  };

  const onSellerChange = (selectedOption) => {
    if (selectedOption) {
      setSellerValue(selectedOption.value);
    }
  };

  const getSellerList = () => {
    dispatch(getSeller({}));
  };

  useEffect(() => {
    getSellerList();
  }, []);

  useEffect(() => {
    if (SellerList?.length > 0) {
      const data = SellerList.map((opt) => ({
        label: opt.userName || "",
        value: opt.id,
        email: opt.email,
        mobileNumber: opt.mobileNumber,
      }));
      setSellerOptions(data);
    }
  }, [SellerList]);

  const uploadFile = (e) => {
    e.preventDefault();

    const fileType = file?.name.split(".")[1];

    if (fileType !== "xlsx" && fileType !== "xls") {
      Swal.fire({
        title: "Error",
        imageUrl: "/assets/images/ZeedIcon.png",
        imageHeight: 100,
        text: "File must be an Excel sheet",
        type: "error",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      return;
    }

    if(!seValue.length && role == "admin"){
      Swal.fire({
        title: "Please select seller",
        icon: "waring",
        type: "waring",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      return;
    }

    const formData = new FormData();
    if (role == "admin") {
      formData.append("seller", seValue);
    }
    formData.append("excelFile", file);

    const apiEndpoint = isUpdateMode
      ? `${Constant.getAPI()}/post/updatePostSheet`
      : `${Constant.getAPI()}/post/uploadPostSheet`;

     dispatch(setLoading(true));
    if (role == "vendor") {
      formData.append("seller", vendorId);
    }

    Axios.post(apiEndpoint, formData, {
      headers: {
        Authorization: localStorage.getItem("loged_In_auth"),
        ContentType: "multipart/form-data",
      },
      timeout: 600000,
    })
      .then((response) => {
        if (response.data.status) {
          dispatch(setLoading(false));
          Swal.fire({
            title: "Product uploaded",
            icon: "success",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            text: "",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
          setFile(null);
          fileInputRef.current.value = "";
          setFilterValue("");
        } else {
          dispatch(setLoading(false));
          Swal.fire({
            title: "Sheet upload failed",
            icon: "error",
            type: "error",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        console.log(error.data);
      });
  };

  const exportSample = () => {
    Axios.post(
      `${Constant.getAPI()}/post/importSampleSheet`,
      {},
      {
        headers: {
          Authorization: localStorage.getItem("loged_In_auth"),
        },
      }
    )
      .then((response) => {
        if (response.data.status === 1) {
          const exportfile = response.data.url;
          const fileName = response.data.fileName;
          const a = document.createElement("a");
          a.href = exportfile;
          a.download = fileName;
          a.click();
        } else {
          console.log("");
        }
      })
      .catch((error) => {
        console.log(error.data);
      });
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
  };

  const thStyle = {
    backgroundColor: "#f1eeee",
    fontSize: "1rem",
    fontWeight: 500,
    padding: "0.5rem",
  };

  const tdStyle = {
    verticalAlign: "middle",
    fontSize: "1rem",
    padding: "0.3rem",
  };

  const selectedSeller = {
    fontWeight: 600,
  };

  return (
    <AUX>
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card m-b-20">
                <div className="card-body">
                  <form onSubmit={uploadFile}>
                    <div className="row">
                      {role !== "vendor" ? (
                        <div
                          className="form-group col-md-8 col-sm-12"
                          style={{
                            height: "calc(100vh - 140px)",
                            overflow: "auto",
                          }}
                        >
                          <input
                            style={{
                              width: "30%",
                              paddingLeft: "4px",
                              float: "right",
                              marginBottom: "10px",
                            }}
                            type="text"
                            value={filterValue}
                            onChange={(e) => setFilterValue(e.target.value)}
                            placeholder="Search here"
                          />
                          <table
                            className="table table-bordered"
                            style={tableStyle}
                          >
                            <thead
                              style={{
                                position: "sticky",
                                top: "0px",
                                zIndex: "9",
                              }}
                            >
                              <tr>
                                <th
                                  style={thStyle}
                                  width="10%"
                                  className="text-center"
                                ></th>
                                <th
                                  style={thStyle}
                                  width="10%"
                                  className="text-center"
                                >
                                  User name
                                </th>
                                <th
                                  style={thStyle}
                                  width="10%"
                                  className="text-center"
                                >
                                  Email
                                </th>
                                <th
                                  style={thStyle}
                                  width="10%"
                                  className="text-center"
                                >
                                  Mobile
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {sellerOptions
                                .filter(
                                  (option) =>
                                    option.label
                                      .toLowerCase()
                                      .includes(filterValue.toLowerCase()) ||
                                    (option.mobileNumber &&
                                      option.mobileNumber.includes(
                                        filterValue.toLowerCase()
                                      )) ||
                                    (option.email &&
                                      option.email
                                        .toLowerCase()
                                        .includes(filterValue.toLowerCase()))
                                )
                                .map((option) => (
                                  <tr
                                    key={option.value}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => onSellerChange(option)}
                                  >
                                    <td
                                      style={tdStyle}
                                      className="text-center"
                                      width="9%"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={seValue === option.value}
                                      />
                                    </td>
                                    <td>
                                      <div>{option.label}</div>
                                    </td>
                                    <td>
                                      <div>{option.email}</div>
                                    </td>
                                    <td>
                                      <div>{option.mobileNumber}</div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      ) : null}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginBottom: "20px",
                          marginLeft: "10px",
                        }}
                      >
                        <label
                          style={{ fontSize: "18px", marginBottom: "10px" }}
                        >
                          Excel File:
                        </label>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{
                            padding: "10px",
                            border: "2px solid #ccc",
                            borderRadius: "5px",
                            fontSize: "16px",
                            backgroundColor: "#f9f9f9",
                            cursor: "pointer",
                            transition: "border-color 0.3s ease",
                          }}
                          name="excelFile"
                          onChange={onFileChange}
                        />
                        <div className="form-group mt-3 w-100">
                          <div className="d-flex justify-content-start ">
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light mr-2"
                            >
                              {isUpdateMode ? "Update" : "Submit"}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary waves-effect waves-light mr-2"
                              onClick={exportSample}
                            >
                              Download Sample
                            </button>
                          </div>
                          <div className="d-flex justify-content-start mt-3">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="updateModeSwitch"
                                checked={isUpdateMode}
                                onChange={() => setIsUpdateMode(!isUpdateMode)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="updateModeSwitch"
                              >
                                Update Mode
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AUX>
  );
};

export default ExcelUpload;

import { MDBDataTable } from 'mdbreact';
import React, { useEffect, useState } from 'react'
import { Link ,useParams } from 'react-router-dom';
import axios from "axios";
import Constant from '../../../Constant';

const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("loged_In_auth"),
    },
};

const VariantList = () => {
    const [variant, setVariant] = useState()

    const params = useParams();

    const data = { 
        columns: [
            {
                label: "Variant Attributes Name",    
                field: "variantAttributes",
            },
            {
                label: "Price",    
                field: "price",
            },
            {
                label: "Quantity",    
                field: "quantity",
            },
        ], 
        rows: variant?.map((itm) => {
            return {
                variantAttributes: itm.variantAttribute.map(
                    (attr, i) => (<li key={attr.Attribute?.id}><b>{i+1} - </b> {attr.Attribute?.name} / {attr.Attribute?.name_ar}</li>)
                ),
                price: itm.price,
                quantity: itm.quantity,
            }
        })
    }

    useEffect(() => {
        let id = params.id;
        getVariantList({productId: id})

    }, [])

    const getVariantList = (data) => {
        axios
        .post(Constant.getAPI() + "/admin/getVariant", data, config)
        .then((res) => {
            // console.log("🚀 ~ .then ~ res:", [...res.data.data])
            setVariant([...res.data.data])
        // console.log(res);
      // dispatch(setLoading(false));
     })
     .catch((err) => console.log(err));
    }

    return (
        <div className="pcoded-inner-content">
        <div className="main-body">
            <div className="page-wrapper">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>Variants List</h5>
                                <Link to={`/variantDetails/${params.id}`} className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger" data-modal="modal-13">
                                     <i className="icofont icofont-plus m-r-5"></i> 
                                </Link>
                            </div>
                            <div className="card-block">
                                <div className="dt-responsive table-responsive">
                                    <MDBDataTable
                                        bordered
                                        hover
                                        data={data}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default VariantList